import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import {environment} from './../../../environments/environment';
import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';
import {AppConfig} from './../../../app/app.config';
import {ConstantHelper} from './../../_helpers/constant.helper';
import {UserService} from './../../_services/user.service';
import {from} from 'rxjs';
import {SweetAlertHelper} from 'src/app/_helpers/sweet.alert.helper';
import {Message} from 'src/app/_locale/message';
import {async} from '@angular/core/testing';

declare let $: any;

@Component({
  selector: 'app-doctor-doc-review',
  templateUrl: './doctor-doc-review.component.html',
  styleUrls: ['./doctor-doc-review.component.scss'],
  providers: [AppConfig, CommonHelper],
})
export class DoctorDocReviewComponent implements OnInit {
  showLoader = false;

  loggedInUserId: string;
  loggedInUserRoleId: string;

  demographicId: string;
  userId: string;
  resultJsonObj: any = {};
  hidePdfPopUp = false;
  demographicUrl: SafeResourceUrl;
  hippaUrl: SafeResourceUrl;
  disclaimerUrl: SafeResourceUrl;
  ahsDisclaimerPdf: SafeResourceUrl;
  ahsHippaPdf: SafeResourceUrl;
  showDocThumbnails = true;
  ahsPdfUrl: SafeResourceUrl = '';
  documentsLength = false;
  documentsList: any;
  docGroupId: any;
  docGroupTitle: any;
  docSet: any = 'A';
  docTyep: any;
  companyDefaultGroupTitle: any;
  companyDefaultGroupId: any;
  activeGroupId: any;
  selectedDocId: any;
  signedGroups: any = [];
  signedGroupsCount: any;
  childRoute: string;
  physicianInfo: any;
  seleteDocumetUrl: any = '';
  seleteDocumetBase64: any = '';
  selectedFileExt: any = '';
  videoLink: any = '';

  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  profileObj: any = {};
  showDeleteButton = false;
  showHippaUpdateBtn: string;
  message = Message;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer,
    public userService: UserService
  ) {
    this.showHippaUpdateBtn = localStorage.getItem('showHippaUpdateBtn');

    this.loggedInUserRoleId = localStorage.getItem('loggedInUserRoleId');

    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.demographicId =
      this.activatedRoute.snapshot.paramMap.get('demographicId');
    this.userId = this.activatedRoute.snapshot.paramMap.get('userId');
    localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.findUserDetails(this.userId);
    this.findCompanyDefaultGroup(this.userId);
    this.findPhysicianDetailsByUserId(this.loggedInUserId);
    this.findSignedGroups(this.userId);
  }

  ngOnInit() {
    // tslint:disable-next-line:only-arrow-functions
    $(document).ready(function() {
      // tslint:disable-next-line:only-arrow-functions
      $(window).resize(function() {
        setHeightResizeFunc();
      });
      setHeightResizeFunc();
    });

    function setHeightResizeFunc() {
      const rightHeight =
        $('.right-box .ibox-title').height() +
        $('.right-box .ibox-content').height() -
        45;
      $('.docLists').height(rightHeight);
    }
  }

  findUserDetails(userId) {
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      id: userId,
    };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          this.showLoader = false;
          if (apiResponse.commandResult.status == 1) {
            this.docGroupId =
              apiResponse.commandResult.data.userInfo.DocGroupId;
            this.docGroupTitle =
              apiResponse.commandResult.data.userInfo.GroupTitle;
            this.profileObj = apiResponse.commandResult.data.userInfo;
            this.activeGroupId = this.profileObj.DocGroupId;
            this.fnFindAllDocuments();
          } else {
            // alert("Error Occurred.");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.doctorDocComp.finUserDetails
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPhysicianDetailsByUserId(userId) {
    const finalData = {
      id: userId,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl04() +
        ApiHelper.name_findPhysicianDetailsByUserIdApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.physicianInfo = apiResponse.commandResult.data.physicianInfo;
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  //

  findCompanyDefaultGroup(userId) {
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      loggedInUserId: userId,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getLambdaUrl03() +
        ApiHelper.name_getCompanyDefaultGroupApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.companyDefaultGroupTitle =
            apiResponse.commandResult.data.companyDefaultGroup.GroupTitle;
          this.companyDefaultGroupId =
            apiResponse.commandResult.data.companyDefaultGroup.Id;
          this.showLoader = true;
          // this.fnFindGroupDocs()
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  showDemographicForm() {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = {
      loggedInUserId: this.userId,
      demographicId: this.demographicId,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_generateDemographicPdfApi,
        finalData,
        options
      )
      .subscribe(
        async (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.success == 1) {
            // console.log(this.resultJsonObj.commandResult.data);

            // this.demographicUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.resultJsonObj.commandResult.data
            );
          } else {
            // alert("oops! error occured, please try later");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.allotDocgrpComp.onSubmi1
            );
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  showHippaForm() {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = {
      loggedInUserId: this.userId,
      demographicId: this.demographicId,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_generateHippaRealeasPdfApi,
        finalData,
        options
      )
      .subscribe(
        async (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.success == 1) {
            this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.resultJsonObj.commandResult.data
            );
          } else {
            // alert("oops! error occured, please try later");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.allotDocgrpComp.onSubmi1
            );
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  showDisclaimerForm() {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      headers: httpHeaders,
    };
    const finalData = {
      loggedInUserId: this.userId,
      demographicId: this.demographicId,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getPhpUrl() +
        ApiHelper.php_generateWaiverDisclaimerPdfApi,
        finalData,
        options
      )
      .subscribe(
        async (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.success == 1) {
            // console.log(apiResponse);
            // this.disclaimerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.resultJsonObj.commandResult.data
            );
          } else {
            // alert("oops! error occured, please try later");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.allotDocgrpComp.onSubmi1
            );
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  goToDemographicForm() {
    this.router.navigate(['/demographicsform/' + this.loggedInUserId]);
  }

  async editDemographicForm5() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure, you want to modify your demographic information."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.doctordocComp.editDemographicForm5
    );
    if (alertStatus) {
      this.router.navigate([
        '/edit-demographic-5/' + this.loggedInUserId + '/' + this.demographicId,
      ]);
    }
  }

  async editSignature() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure, you want to change your signature."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminDocReviewComp.editSignature
    );
    if (alertStatus) {
      this.router.navigate([
        '/signaturewaiver/' + this.loggedInUserId + '/' + this.demographicId,
      ]);
    }
  }

  async signOff() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: After submitting, you should receive all documents by email."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.doctordocComp.signOff
    );
    {
      this.showDocThumbnails = false;

      const httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      const options = {
        headers: httpHeaders,
      };
      const finalData = {
        loggedInUserId: this.userId,
        demographicId: this.demographicId,
        company_slug: ConstantHelper.COMPANY_SLUG,
      };
      this.showLoader = true;

      return this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_generateDemographicPdfApi,
          finalData,
          options
        )
        .subscribe(
          (apiResponse) => {
            this.http
              .post(
                this.appConfig.getPhpUrl() +
                ApiHelper.php_generateHippaRealeasPdfApi,
                finalData,
                options
              )
              .subscribe(
                (apiResponse) => {
                  this.http
                    .post(
                      this.appConfig.getPhpUrl() +
                      ApiHelper.php_generateWaiverDisclaimerPdfApi,
                      finalData,
                      options
                    )
                    .subscribe(
                      (apiResponse) => {
                        this.http
                          .post(
                            this.appConfig.getPhpUrl() +
                            ApiHelper.php_sendSignOffMailApi,
                            finalData,
                            options
                          )
                          .subscribe(
                            (apiResponse) => {
                              this.resultJsonObj = apiResponse;
                              // console.log(this.resultJsonObj);

                              this.http
                                .post(
                                  this.appConfig.getPhpAhsUrl() +
                                  ApiHelper.ahs_uploadFile2ElemrexApi,
                                  finalData,
                                  options
                                )
                                .subscribe(
                                  (apiResponse) => {
                                    this.resultJsonObj = apiResponse;
                                    // console.log(this.resultJsonObj);
                                    // this.router.navigate(["/"]);
                                    this.router.navigate([
                                      '/dashboard/' + this.loggedInUserId,
                                    ]);
                                    this.showLoader = false;
                                  },
                                  (err) => {
                                    // console.log(err);
                                  }
                                );
                            },
                            (err) => {
                              // console.log(err);
                            }
                          );
                      },
                      (err) => {
                        // console.log(err);
                      }
                    );
                },
                (err) => {
                  // console.log(err);
                }
              );
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  showDocument(documentId, documentUrl, docTyep) {
    console.log(documentUrl);
    this.selectedFileExt = this.getFileExtension(documentUrl).toLowerCase();
    this.selectedDocId = documentId;
    this.docTyep = docTyep;
    console.log(this.selectedFileExt);


    if (this.selectedFileExt == 'doc' || this.selectedFileExt == 'docx') {
      this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://docs.google.com/gview?url=' + documentUrl + '&embedded=true'
      );
    } else {
      this.ahsPdfUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(documentUrl);
    }

    // if (this.selectedFileExt == 'jpeg' || this.selectedFileExt == 'jpg' || this.selectedFileExt == 'png') {
    //   this.http.get(documentUrl, {responseType: 'blob'})
    //     .subscribe(response => {
    //       const reader = new FileReader();
    //       reader.onloadend = () => {
    //         const base64Data = reader.result as string;
    //         const base64result = base64Data.split(',')[1];
    //         console.log(base64result);
    //         this.seleteDocumetBase64 = base64result;
    //       };
    //       reader.readAsDataURL(response);
    //     });
    // }

    if (this.selectedFileExt === 'jpeg' || this.selectedFileExt === 'jpg' || this.selectedFileExt === 'png' || this.selectedFileExt === 'mp4') {
      if (this.selectedFileExt === 'mp4') {
        // const video = document.createElement('video');
        this.videoLink = this.sanitizer.bypassSecurityTrustResourceUrl(documentUrl);
        console.log("video link:", this.videoLink)
        // this.videoLink.controls = true;
        const getSafeVideoUrl = () => {
          return this.sanitizer.bypassSecurityTrustResourceUrl(this.videoLink);
        }
      } else {
        this.http.get(documentUrl, { responseType: 'blob' })
          .subscribe(response => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64Data = reader.result as string;
              const base64result = base64Data.split(',')[1];
              this.seleteDocumetBase64 = base64Data;
            };
            reader.readAsDataURL(response);
          });
      }
    }

    this.seleteDocumetUrl = documentUrl;
    this.showDeleteButton = true;
  }


  getFileExtension(filename) {
    const extension = filename.split('.').pop();
    return extension;
  }

  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  async onReset1() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the User Database?"
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.adminContactUsComp.goToDemographicsComp
    // );
    // if (alertStatus) {
      this.router.navigate([
        '/' + this.childRoute + '/demographics/' + this.loggedInUserId,
      ]);
    // }
  }

  selectDocGroup(docSet) {
    console.log('docSet', docSet);
    this.seleteDocumetUrl = '';
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.showDeleteButton = false;
    if (docSet == 'A') {
      this.fnFindAllDocuments();
    } else if (docSet == 'U') {
      this.fnFindUploadedDocs();
    } else if (docSet == 'DS') {
      this.fnFindDoctorAcknowledgments();
    } else if (docSet == 'HD') {
      this.fnFindPhysicainHippa(8);
    } else if (docSet == 'ID') {
      this.fnFindPhysicainHippa(9);
    } else if (docSet == 'VD') {
      this.fnFindPhysicainHippa(10);
    } else if (docSet == 'RD') {
      this.fnFindPhysicainHippa(13);
    } else if (docSet == 'PU') {
      this.fnFindPhysicainHippa(14);
    } else {
      this.activeGroupId = docSet;
      this.fnFindGroupDocs();
    }

    //
  }

  fnFindActiveAdminDocuments() {
    const finalData = {
      loggedInUserId: this.userId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl02() +
        ApiHelper.name_getActiveAdminDocumentsListApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
            // console.log(this.documentsList);
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindAllDocuments() {
    const finalData = {
      loggedInUserId: this.userId,
      company_slug: ConstantHelper.COMPANY_SLUG,
      email: this.profileObj.Email,
      physicianId: this.loggedInUserId,
      userRoleId: this.loggedInUserRoleId,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findAllDocumentsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindDefaultDocuments() {
    const finalData = {
      loggedInUserId: this.userId,
      company_slug: ConstantHelper.COMPANY_SLUG,
      email: this.profileObj.Email,
    };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
        ApiHelper.name_findDefaultDocumentsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log('Default Documents', this.resultJsonObj);

          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindGroupDocs() {
    const finalData = {
      loggedInUserId: this.userId,
      company_slug: ConstantHelper.COMPANY_SLUG,
      doc_group_id: this.activeGroupId,
      default_group_id: this.companyDefaultGroupId,
      email: this.profileObj.Email,
    };

    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findDocsBySubGroupApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log('Sub Group Documents', this.resultJsonObj);
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
            // console.log(this.documentsList);
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindPhysicainHippa(docType) {
    const finalData = {
      userId: this.userId,
      phyNpi: this.physicianInfo.PhysicianNipNo,
      docType: docType,
      physicianId: this.loggedInUserId,
    };

    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findPhysicainHippaApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindUploadedDocs_not() {
    // console.log(this.profileObj.Email);
    const finalData = {
      loggedInUserId: this.userId,
      company_slug: ConstantHelper.COMPANY_SLUG,
      docType: ConstantHelper.DOC_TYPE_USER_UPLOADED,
      email: this.profileObj.Email,
    };
    // console.log(finalData);
    // return;
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
        ApiHelper.name_getUploadedDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log('Uploaded Documents', this.resultJsonObj);
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
            // console.log(this.documentsList);
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindUploadedDocs() {
    // console.log(this.profileObj.Email);
    const finalData = {
      userId: this.userId,
      doctorId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    // console.log(finalData);
    // return;
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getPhysicianUploadDocuments,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log('Uploaded Documents', this.resultJsonObj);
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
            // console.log(this.documentsList);
          } else {
            this.documentsLength = false;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async deleteDoc() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminDocReviewComp.deleteDoc
    ); // if (confirm("PLEASE CONFIRM: Are you sure, you want to delete"))
    {
      const finalData = {
        docId: this.selectedDocId,
      };
      this.showLoader = true;

      return this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_deleteDocApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
            this.showDeleteButton = false;
            this.showLoader = false;

            this.fnFindUploadedDocs();
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  async clearViewr() {
    // if (confirm("PLEASE CONFIRM: Are you sure, you want to clear preview?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminDocReviewComp.clearViewr
    );
    if(alertStatus)
    {
      // this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
      this.selectedFileExt = '';
      this.showDeleteButton = false;
    }
  }

  ////////////////////////////////////////////////////////

  findSignedGroups(userId) {
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      loggedInUserId: userId,
    };
    this.showLoader = true;

    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findUserDocumentGroupsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.signedGroups = apiResponse.commandResult.data.DocGroupd;
          this.signedGroupsCount =
            apiResponse.commandResult.data.DocGroupd.length;
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnFindDoctorAcknowledgments() {
    const finalData = {
      loggedInUserId: this.userId,
      phyId: this.loggedInUserId,
      company_slug: null,
      docType: 7,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
        ApiHelper.name_getPhyAcknowledgmentDocumentsList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to Physician Home Page?"
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.adminChangePassComp.onReset
    // );
    // if (alertStatus) {
      this.router.navigate([
        '/' + this.childRoute + '/dashboard/' + this.loggedInUserId,
      ]);
    // }
  }

  async goToPracticeList() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the User Database?"
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.adminUploaddocComp.gotoDemgraphics
    // );
    // if (alertStatus) {
      this.router.navigate([
        '/' + this.childRoute + '/demographics/' + this.loggedInUserId,
      ]);
    // }
  }
}

import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { ArrayHelper } from "./../../_helpers/array.helper";

import { UploadService } from "../../_services/upload.service";
import { UserService } from "../../_services/user.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { async } from "@angular/core/testing";

// import { ShareUnrelatedDateService } from "./../../_services/share-unrelated-date.service";

@Component({
  selector: "app-practice-groups",
  templateUrl: "./practice-groups.component.html",
  styleUrls: ["./practice-groups.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class PracticeGroupsComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  usersList: any = [];
  isPrimary: any;
  showEditParticipatingCenterModal = false;
  showAddSpecialityModal = false;
  showEditSpecialityModal = false;
  showAddSubSpecialityModal = false;
  showEditSubSpecialityModal = false;
  showSpecialityList = false;
  childRoute: string;
  loggedInUserId: string;
  companySlug: string = ConstantHelper.COMPANY_SLUG;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  searchStateList: any = ArrayHelper.STATE_ARRAY;

  selectedPhysician: any = {};
  participatingCenter: string;
  companyId: string;
  newParticipatingCenter: string;
  stateName: string;
  cityName: string;
  newSpeciality: string;
  newSubSpeciality: string;
  specialityTableArr: any = [];
  subSpecialityTableArr: any = [];
  specialities: any = [];
  doctors: any = [];
  showLoader = false;
  newGroup: string;
  resultJsonObj: any = {};
  showAddDoctorModal = false;
  subSpecialities: any = [];
  practiceGroup: any = {};
  userRoleVal: any = 0;
  selectedSpeciality: any;
  selectedSubSpeciality: any;
  selectedDoctor: any;
  user_Role_Id: any;
  doctorTableArr: any = [];
  selectedSpecialityId = "";
  subSelectedSpecialityId = "";
  selectedParticipatingPhysicianId = "";
  selectedDelegateId: any;
  assignedDelegateId: any;
  isDelegated: any = 0;
  message = Message;
  COECityState: any;
  recordId: any;
  noDataFlag = false;
  storedParticipatingCenterState = "";

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private userService: UserService
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    // tslint:disable-next-line:max-line-length
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    // alert(this.loggedInUserId);
    this.getSpecialitiesByComapnSlug(this.companySlug);
    this.getPhysiciansByComapnSlug(this.companySlug);
    this.selectedDelegateId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.loggedInUserId;

    this.user_Role_Id = localStorage.getItem("loggedInUserRoleId");
    console.log("User Role Id is:", this.user_Role_Id);
  }

  ngOnInit() {
    this.findUserDetails(this.loggedInUserId);
    this.selectedPhysician.speciality = "";
    this.selectedPhysician.subSpeciality = "";
    this.selectedPhysician.doctor = "";
    this.selectedPhysician.participatingCenterState = "";
    this.selectedPhysician.ParticipatingCenterCity = "";
    this.findAdminList();
    $("#example").DataTable({
      order: [[3, "desc"]],
    });
  }

  findAdminList() {
    this.usersList = [];

    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      loggedInUserId: this.loggedInUserId,
      userRoleVal: this.userRoleVal,
    };
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_getAdminUsersListApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log("API resp: ", apiResponse)
          // this.tempUsersList = this.resultJsonObj.commandResult.data.adminUsers;
          this.usersList = this.resultJsonObj.commandResult.data.adminUsers;
          console.log("List here", this.usersList);
          // this.isPrimary = this.resultJsonObj.commandResult.data.IsPrimary;
          // // console.log(this.isPrimary);
          // localStorage.setItem(
          //   "isPrimary",
          //   this.resultJsonObj.commandResult.data.IsPrimary
          // );

          // if (this.usersList.length > 0) {
          //   this.isPrimary = this.usersList[4].IsPrimary;
          //   console.log("IsPrimary for the first user:", this.isPrimary);

          //   localStorage.setItem("isPrimary", this.isPrimary);
          // }

          // if(this.userRoleVal>0){
          //   this.filterPhysician(this.userRoleVal)
          // }else{
          //   this.usersList = this.resultJsonObj.commandResult.data.adminUsers;
          // }

          this.usersList.forEach((user) => {
            const isPrimary = user.IsPrimary;
            console.log("IsPrimary for user:", isPrimary);

            // Perform additional operations with the isPrimary value if needed

            // Store the isPrimary value in local storage (with a unique key for each user)
            localStorage.setItem(`isPrimary_${user.RecordId}`, isPrimary);
          });
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findUserDetails(loggedInUserId) {
    const finalData = {
      id: loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (response: any) => {
          localStorage.setItem(
            "loggedInUserName",
            response.commandResult.data.userInfo.FullName
          );
          localStorage.setItem(
            "loggedInUserEmail",
            response.commandResult.data.userInfo.Email
          );
          localStorage.setItem(
            "loggedInUserRoleId",
            response.commandResult.data.userInfo.UserRoleId
          );
          localStorage.setItem(
            "loggedInUserComapnyId",
            response.commandResult.data.userInfo.ComapnyId
          );
          localStorage.setItem(
            "loggedInUserParticipatingCenter",
            response.commandResult.data.userInfo.ParticipatingCenter
          );
          localStorage.setItem(
            "loggedInUserComapny",
            response.commandResult.data.userInfo.CompanySlug
          );
          this.isPrimary = response.commandResult.data.userInfo.IsPrimary;

          this.selectedPhysician.participatingCenter =
            response.commandResult.data.userInfo.ParticipatingCenter;
          this.selectedPhysician.ParticipatingCenterCompany =
            response.commandResult.data.userInfo.ParticipatingCenterCompany;
          this.selectedPhysician.ParticipatingCenterState =
            response.commandResult.data.userInfo.ParticipatingCenterState;
          this.storedParticipatingCenterState =
            this.selectedPhysician.ParticipatingCenterState;
          this.selectedPhysician.ParticipatingCenterCity =
            response.commandResult.data.userInfo.ParticipatingCenterCity;

          this.selectedPhysician.companyId =
            response.commandResult.data.userInfo.ComapnyId;
          this.newParticipatingCenter =
            response.commandResult.data.userInfo.ParticipatingCenter;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async onReset() {
    // if (
    //   confirm("Are you certain you want to return to the Physician Home Page?")
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.adminChangePassComp.onReset
    // );
    // if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
      ]);
    // }
  }

  async goToPracticeSpecialities() {
    // if (
    //   confirm("PLEASE CONFIRM: Are you certain you want to go to specialties?")
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.prcaticeGroupComp.goToPracticeSpecialities
    );
    if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/practice-specialities/" + this.loggedInUserId,
      ]);
    }
  }

  async goToPracticeSubSpecialities() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to go to sub-specialties?"
    //   )
    // )

    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.prcaticeGroupComp.goToPracticeSpecialities
    );
    if (alertStatus) {
      this.router.navigate([
        "/" +
          this.childRoute +
          "/practice-sub-specialities/" +
          this.loggedInUserId,
      ]);
    }
  }

  async goToCallSchedules(doctorTable) {
    // console.log(doctorTable);
    // console.log("loggedInUserId", this.loggedInUserId);
    // console.log("companyId", this.selectedPhysician.companyId);
    // console.log("specialityId", this.selectedSpecialityId);
    // console.log("subSpecialityId", this.subSelectedSpecialityId);
    // console.log("coeId", doctorTable.id);
    // console.log("physicianId", doctorTable.registered_physician_id);

    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to go to On Call-schedules?"
    //   )
    // )

    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.prcaticeGroupComp.goToCallSchedules
    );
    if (alertStatus) {
      // tslint:disable-next-line: max-line-length
      this.router.navigate([
        "/" +
          this.childRoute +
          "/physician-scheduling/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.selectedPhysician.companyId +
          "/" +
          doctorTable.speciality_id +
          "/" +
          doctorTable.sub_speciality_id +
          "/" +
          doctorTable.id +
          "/" +
          doctorTable.registered_physician_id,
      ]);
    }
  }

  async updateParticipatingCenter() {

    if (
      !this.selectedPhysician.ParticipatingCenterCompany ||
      !this.selectedPhysician.ParticipatingCenterCity || !/^[a-zA-Z\s]+$/.test(this.selectedPhysician.ParticipatingCenterCity)
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(this.message.alertMessages.prcaticeGroupComp.StateandcityBlank);
      if (alertStatus) {
        return false;
      }
      return;
    }

    if (
      (this.user_Role_Id == 4 && this.isPrimary == 1) ||
      (this.user_Role_Id == 1 && this.isPrimary == 1)
    ) {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.prcaticeGroupComp.updateParticipating
      );
      if (alertStatus) {
        const finalData = {
          id: this.selectedPhysician.companyId,
          company: this.selectedPhysician.ParticipatingCenterCompany,
          state:
            this.selectedPhysician.participatingCenterState ||
            this.storedParticipatingCenterState,
          city: this.selectedPhysician.ParticipatingCenterCity,
          user_id: this.loggedInUserId,
        };

        this.http
          .post(
            this.appConfig.getPhpUrl() +
              ApiHelper.php_updateParticipatingCenterApi,
            finalData,
            this.httpOptions
          )
          .subscribe(
            async (response: any) => {
              this.findUserDetails(this.loggedInUserId);
              this.showEditParticipatingCenterModal = false;

              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.prcaticeGroupComp
                  .updateParticipating1
              );
              if (alertStatus) {
                location.reload();
              }
            },
            (err) => {
              // console.log(err);
            }
          );
      }
    }
  }

  async goToDemographics() {
    // if (confirm("Confirm your desire to return to the 'Patient user' list. "))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminUploaddocComp.gotoDemgraphics
    );
    if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/demographics/" + this.loggedInUserId,
      ]);
    }
  }

  closeEditParticipatingCenterModal() {
    this.showEditParticipatingCenterModal = false;
    this.practiceGroup.finalData = "";
  }

  async openEditParticipatingCenterModal() {
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.practiceGroupComp
    //     .openEditParticipatingCenterModal
    // );
    // if (alertStatus) {
    //   this.showEditParticipatingCenterModal = true;
    //   return;
    // }

    if (
      (this.user_Role_Id == 4 && this.isPrimary == 1) ||
      (this.user_Role_Id == 1 && this.isPrimary == 1)
    ) {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.practiceGroupComp
          .openEditParticipatingCenterModal
      );
      if (alertStatus) {
        this.showEditParticipatingCenterModal = true;
        return;
      }
    } else {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.prcaticeGroupComp.updateCOE
      );
      if (alertStatus) {
        location.reload();
      }
    }
  }

  async coeNameChangeRequest() {
    if (
      !this.practiceGroup.suggested_name ||
      !this.practiceGroup.request_reason
    ) {
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminDuallistComp.submitReason
      );
      return;
    }
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.admindualListComp.submitReason
    );
    if (alertStatus) {
      const httpHeaders = new HttpHeaders({
        "content-Type": "application/json",
      });
      const options = {
        headers: httpHeaders,
      };
      const finalData = {
        loggedInUserId: this.loggedInUserId,
        company_slug: this.companySlug,
        company_id: this.selectedPhysician.companyId,
        suggested_name: this.practiceGroup.suggested_name,
        city_name: this.practiceGroup.city_name,
        state_name: this.practiceGroup.state_name,
        request_reason: this.practiceGroup.request_reason,
      };
      this.showLoader = true;
      this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_coeNameChangeRequestApi,
          finalData,
          options
        )
        .subscribe(
          async (apiResponse: any) => {
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminDuallistComp.sentEmail
            );
            if (alertStatus) {
              location.reload();
            }

            this.showLoader = false;
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  // if (alertStatus) {
  //   const httpHeaders = new HttpHeaders({
  //     "Content-Type": "application/json",
  //   });
  //   const options = {
  //     headers: httpHeaders,
  //   };

  //   const finalData = {
  //     loggedInUserId: this.loggedInUserId,
  //     company_slug: this.companySlug,
  //     company_id: this.selectedPhysician.companyId,
  //   };
  //   this.showLoader = true;
  //   this.http
  //     .post(
  //       this.appConfig.getPhpUrl() + ApiHelper.php_coeNameChangeRequestApi,
  //       finalData,
  //       options
  //     )
  //     .subscribe(
  //       async (apiResponse: any) => {
  //         const alertStatus = await this.sweetAlertHelper.confirmPopUp(
  //           this.message.confirmTitle,
  //           this.message.confirmMessages.admindualListComp.sentEmail
  //         );
  //         location.reload();
  //         this.showLoader = false;
  //       },
  //       (err) => {
  //         // console.log(err);
  //       }
  //     );
  // }

  // async openEditParticipatingCenterModal() {
  //   const alertStatus = await this.sweetAlertHelper.confirmPopUp(
  //     this.message.confirmTitle,
  //     this.message.confirmMessages.practiceGroupComp
  //       .openEditParticipatingCenterModal
  //   );
  //   this.showLoader = true;
  //   {
  //     const httpHeaders = new HttpHeaders({
  //       "Content-Type": "application/json",
  //     });
  //     const options = {
  //       headers: httpHeaders,
  //       // withCredentials: true,
  //     };
  //     const finalData = {
  //       // email: this.recipientEmail,
  //       // fullName: this.recipientName,
  //       loggedInUserId: this.loggedInUserId,
  //       company_slug: this.companySlug,
  //       company_id: this.selectedPhysician.companyId,
  //     };
  //     // const coId = finalData.company_id
  //     // console.log('CO DI',coId)
  //     this.showLoader = true;
  //     this.http
  //       .post(
  //         this.appConfig.getPhpUrl() + ApiHelper.php_coeNameChangeRequestApi,
  //         finalData,
  //         options
  //       )
  //       .subscribe(
  //         async (apiResponse: any) => {
  //           // if (this.companyId === this.companyId) {
  //           //   const alertStatus = await this.sweetAlertHelper.alertPopUp(
  //           //     this.message.alertMessages.adminInvitePatientComp.onSubmit
  //           //   );
  //           // } else {
  //           //   const alertStatus = await this.sweetAlertHelper.confirmPopUp(
  //           //     this.message.confirmTitle,
  //           //     this.message.confirmMessages.admindualListComp.sentEmail
  //           //   );
  //             location.reload();
  //           //}

  //         },
  //         (err) => {
  //           // console.log(err);
  //         }
  //       );
  //     if (alertStatus) {
  //       this.showEditParticipatingCenterModal = true;
  //     }
  //   }
  // {
  //   if (this.companyId === this.companyId) {
  //     const alertStatus = await this.sweetAlertHelper.alertPopUp(
  //       this.message.alertMessages.adminInvitePatientComp.onSubmit
  //     );
  //   } else {
  //     const alertStatus = await this.sweetAlertHelper.confirmPopUp(
  //       this.message.confirmTitle,
  //       this.message.confirmMessages.admindualListComp.sentEmail
  //     );
  //     location.reload();
  //   }
  //   // location.reload();
  //   this.showLoader = false;
  // });
  //}

  openList() {
    this.showSpecialityList = true;
  }

  // concateSpeciality(speciality) {

  //   console.log(speciality)
  //   this.selectedSpeciality = speciality;
  //   // var tempSpe = this.specialities.find(e => e.id == specialityId);
  //   // this.specialityTableArr.push(tempSpe);
  //   // console.log(tempSpe)

  // }
  async addSpeciality() {
    if (!this.newSpeciality) {
      // alert("please enter specialty");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.prcaticeGroupComp.addSpeciality
      );

      return;
    }
    // if (
    //   confirm("PLEASE CONFIRM: Are you sure you want to add new specialty ")
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.prcaticeGroupComp.addSpeciality
    );
    {
      // console.log(this.newSpeciality);

      const finalData = {
        id: 0,
        speciality: this.newSpeciality,
      };
      this.specialityTableArr.push(finalData);
      this.showAddSpecialityModal = false;
    }
  }

  async editSpeciality() {
    if (!this.newSpeciality) {
      // alert("please enter specialty");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.prcaticeGroupComp.addSpeciality
      );

      return;
    }
    // if (
    //   confirm("PLEASE CONFIRM: Are you sure you want to add edit specialty ")
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.prcaticeGroupComp.editSpeciality
    );
    {
      // console.log(this.newSpeciality);

      const finalData = {
        id: 0,
        speciality: this.newSpeciality,
      };
      this.specialityTableArr.push(finalData);
      this.showEditSpecialityModal = false;
    }
  }

  concateSubSpeciality(suSpeciality) {
    this.selectedSubSpeciality = suSpeciality;
  }

  async addSubSpeciality() {
    if (!this.newSubSpeciality) {
      // alert("please enter Sub-specialty");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.prcaticeGroupComp.addSubSpeciality
      );

      return;
    }
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure you want to add new Sub-speciality "
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.prcaticeGroupComp.addSubSpeciality
    );
    {
      // console.log(this.newSubSpeciality);

      const finalData = {
        id: 0,
        speciality_id: 1,
        speciality: "Sub Speciality",
        sub_speciality: this.newSubSpeciality,
      };
      // console.log(finalData);

      this.subSpecialityTableArr.push(finalData);
      // console.log(this.subSpecialityTableArr);

      this.showAddSubSpecialityModal = false;
    }
  }

  async editSubSpeciality() {
    if (!this.newSubSpeciality) {
      // alert("please enter Sub-specialty");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.prcaticeGroupComp.addSubSpeciality
      );
      return;
    }
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure you want to edit new Sub-speciality "
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.prcaticeGroupComp.editSubSpeciality
    );
    {
      // console.log(this.newSubSpeciality);

      const finalData = {
        id: 0,
        speciality_id: 1,
        speciality: "Sub Speciality",
        sub_speciality: this.newSubSpeciality,
      };

      // console.log(finalData);

      this.subSpecialityTableArr.push(finalData);
      // console.log(this.subSpecialityTableArr);

      this.showAddSubSpecialityModal = false;
    }
  }

  closeAddSpecialityModal() {
    this.showAddSpecialityModal = false;
  }

  openAddSpecialityModal() {
    this.showAddSpecialityModal = true;
  }

  closeAddSubSpecialityModal() {
    this.showAddSubSpecialityModal = false;
  }

  openAddSubSpecialityModal() {
    this.showAddSubSpecialityModal = true;
  }

  closeEditSpecialityModal() {
    this.showEditSpecialityModal = false;
  }

  openEditSpecialityModal() {
    this.showEditSpecialityModal = true;
  }

  closeEditSubSpecialityModal() {
    this.showEditSubSpecialityModal = false;
  }

  openEditSubSpecialityModal() {
    this.showEditSubSpecialityModal = true;
  }

  getSpecialitiesByComapnSlug(companySlug) {
    const finalData = {
      companySlug,
    };
    return this.http
      .post(
        this.appConfig.getPhpUrl() +
          ApiHelper.php_getSpecialitiesByComapnSlugApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log(apiResponse);
          this.specialities = apiResponse.commandResult.data.specialities;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  checkDelegateStatus() {
    this.selectedDelegateId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.loggedInUserId;
    // console.log(this.selectedDelegateId);
    if (this.selectedDelegateId != this.loggedInUserId) {
      this.loggedInUserId = this.selectedDelegateId;
      this.assignedDelegateId =
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
      this.isDelegated = 1;
    } else {
      this.loggedInUserId =
        this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    }
  }

  getSubSpecialitiesByComapnSlug(selectedSpecialityId) {
    this.checkDelegateStatus();

    this.subSpecialities = [];
    this.subSelectedSpecialityId = "";
    const finalData = {
      companySlug: this.companySlug,
      specialityId: selectedSpecialityId,
    };
    return this.http
      .post(
        this.appConfig.getPhpUrl() +
          ApiHelper.php_getSubSpecialitiesByComapnSlugApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log(apiResponse);
          this.subSpecialities =
            apiResponse.commandResult.data.sub_specialities;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  getPhysiciansByComapnSlug(companySlug) {
    const finalData = {
      company_slug: companySlug,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_findPhysiciansByCompanySlug,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log(apiResponse);
          this.doctors = this.fnChangePhysicianNameFormat1(
            apiResponse.commandResult.data.companiesList
          );
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnChangePhysicianNameFormat1(physicians) {
    const formattedNameArray: any = [];
    physicians.forEach((e) => {
      e.physician_name = this.userService.getUS_FromatName(e.physician_name);
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(
      this.userService.dynamicSort("physician_name")
    );
  }

  openAddDoctorModal() {
    this.showAddDoctorModal = true;
  }

  closeAddDoctorModal() {
    this.showAddDoctorModal = false;
    this.selectedParticipatingPhysicianId = "";
  }

  async addParticipatingDoctor() {
    if (!this.selectedParticipatingPhysicianId) {
      // alert("please select physician");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.prcaticeGroupComp.addParticipatingDoctor
      );
      return;
    }
    // if (confirm("PLEASE CONFIRM: Are you sure you want to continue? "))

    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.prcaticeGroupComp.addparticipatingDoctor
    );
    if (alertStatus) {
      const finalData = {
        companySlug: this.companySlug,
        specialityId: this.selectedSpecialityId,
        subSpecialityId: this.subSelectedSpecialityId,
        physicianId: this.selectedParticipatingPhysicianId,
        assignedDelegateId: this.assignedDelegateId,
        isDelegated: this.isDelegated,
        selectedDelegateId: this.selectedDelegateId,
      };

      return this.http
        .post(
          this.appConfig.getPhpUrl() +
            ApiHelper.php_addParticipatingPhysicianApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse: any) => {
            // console.log(apiResponse);
            if (apiResponse.commandResult.success == -1) {
              // alert("physician already exists in this center");
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.prcaticeGroupComp
                  .addParticipatingDoctor1
              );
            } else {
              this.doctorTableArr = this.fnChangePhysicianNameFormat(
                apiResponse.commandResult.data.participating_physicians
              );

              this.showAddDoctorModal = false;
              // this.selectedSpecialityId = "";
              // this.subSelectedSpecialityId = "";
              this.selectedParticipatingPhysicianId = "";
              // alert("physician added to Center of Excellence");
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.prcaticeGroupComp
                  .addParticipatingDoctor2
              );
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  getParticipatingPhysiciansByComapnSlug(specialityId, subSpecialityId) {
    const finalData = {
      companySlug: this.companySlug,
      specialityId,
      subSpecialityId,
    };
    return this.http
      .post(
        this.appConfig.getPhpUrl() +
          ApiHelper.php_findParticipatingPhysiciansBySubSpecialityApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log(apiResponse);
          this.noDataFlag = true;
          this.doctorTableArr = this.fnChangePhysicianNameFormat(
            apiResponse.commandResult.data.participating_physicians
          );
          for (let i = 0; i < this.doctorTableArr.length; i++) {
            this.findDoctorByUserId(
              this.doctorTableArr[i].registered_physician_id
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnChangePhysicianNameFormat(physicians) {
    const formattedNameArray: any = [];
    physicians.forEach((e) => {
      e.full_name = this.userService.getUS_FromatName(e.full_name);
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(this.userService.dynamicSort("full_name"));
  }

  doNothing() {}

  async changeStatus(id) {
    // if (confirm("PLEASE CONFIRM: Are you sure you want change status"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.prcaticeGroupComp.ChangeStatus
    );
    if (alertStatus) {
      const finalData = {
        companySlug: this.companySlug,
        id,
        specialityId: this.selectedSpecialityId,
        subSpecialityId: this.subSelectedSpecialityId,
      };

      return this.http
        .post(
          this.appConfig.getPhpUrl() +
            ApiHelper.php_changeParticipatingPhysicianStatusByIdApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse: any) => {
            // console.log(apiResponse);
            this.doctorTableArr = this.fnChangePhysicianNameFormat(
              apiResponse.commandResult.data.participating_physicians
            );

            // alert("status changed successfully");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminphysicianSchedulingComp
                .channgeStatus
            );
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  goToCallSchedulesLog() {
    this.router.navigate([
      "/" + this.childRoute + "/on-call-log/" + this.loggedInUserId,
    ]);
  }

  findDoctorByUserId(userId) {
    const finalData = {
      userId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findDoctorByUserIdApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log(apiResponse);
          if (apiResponse.commandResult.status === 1) {
            this.COECityState =
              apiResponse.commandResult.data.npiInfo.PhysicianCity +
              " " +
              apiResponse.commandResult.data.npiInfo.PhysicianPrimaryState;
            console.log("City State here: ", this.COECityState);
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  onSubmit() {}

  setStateCode(event) {
    this.selectedPhysician.participatingCenterState = event.target.value;
    console.log(
      "setStateCode",
      this.selectedPhysician.participatingCenterState
    );
  }
}

export const ArrayHelper = {

  GENDER_ARRAY: ['Male', 'Female', 'Other'],
  MONTH_ARRAY: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
  DAY_ARRAY: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
  YEAR_ARRAY: ['1930', '1931', '1932', '1933', '1934', '1935', '1936', '1937', '1938', '1939', '1940', '1941', '1942', '1943', '1944', '1945', '1946', '1947', '1948', '1949', '1950', '1951', '1952', '1953', '1954', '1955', '1956', '1957', '1958', '1959', '1960', '1961', '1962', '1963', '1964', '1965', '1966','1967', '1968', '1969', '1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020'],
  
  
  STATE_ARRAY: [{StateCode: 'AL', State: 'Alabama'}, {StateCode: 'AK', State: 'Alaska'}, {StateCode: 'AZ', State: 'Arizona'}, {StateCode: 'AR', State: 'Arkansas'}, {StateCode: 'CA', State: 'California'}, {StateCode: 'CO', State: 'Colorado'}, {StateCode: 'CT', State: 'Connecticut'}, {StateCode: 'DE', State: 'Delaware'}, {StateCode: 'FL', State: 'Florida'}, {StateCode: 'GA', State: 'Georgia'}, {StateCode: 'HI', State: 'Hawaii'}, {StateCode: 'ID', State: 'Idaho'}, {StateCode: 'IL', State: 'Illinois'}, {StateCode: 'IN', State: 'Indiana'}, {StateCode: 'IA', State: 'Iowa'}, {StateCode: 'KS', State: 'Kansas'}, {StateCode: 'KY', State: 'Kentucky'}, {StateCode: 'LA', State: 'Louisiana'}, {StateCode: 'ME', State: 'Maine'}, {StateCode: 'MD', State: 'Maryland'}, {StateCode: 'MA', State: 'Massachusetts'}, {StateCode: 'MI', State: 'Michigan'}, {StateCode: 'MN', State: 'Minnesota'}, {StateCode: 'MS', State: 'Mississippi'}, {StateCode: 'MO', State: 'Missouri'}, {StateCode: 'MT', State: 'Montana'}, {StateCode: 'NE', State: 'Nebraska'}, {StateCode: 'NV', State: 'Nevada'}, {StateCode: 'NH', State: 'New Hampshire'}, {StateCode: 'NJ', State: 'New Jersey'}, {StateCode: 'NM', State: 'New Mexico'}, {StateCode: 'NY', State: 'New York'}, {StateCode: 'NC', State: 'North Carolina'}, {StateCode: 'ND', State: 'North Dakota'}, {StateCode: 'OH', State: 'Ohio'}, {StateCode: 'OK', State: 'Oklahoma'}, {StateCode: 'OR', State: 'Oregon'}, {StateCode: 'PA', State: 'Pennsylvania'}, {StateCode: 'PR', State: 'Puerto Rico'}, {StateCode: 'RI', State: 'Rhode Island'}, {StateCode: 'SC', State: 'South Carolina'}, {StateCode: 'SD', State: 'South Dakota'}, {StateCode: 'TN', State: 'Tennessee'}, {StateCode: 'TX', State: 'Texas'}, {StateCode: 'UT', State: 'Utah'}, {StateCode: 'VT', State: 'Vermont'}, {StateCode: 'VA', State: 'Virginia'}, {StateCode: 'WA', State: 'Washington'}, {StateCode: 'WV', State: 'West Virginia'}, {StateCode: 'WI', State: 'Wisconsin'}],



  CALL_LOG_YEAR_ARRAY: ['2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'],

  FULL_MONTH_ARRAY: [{month_name: 'January', month_id: '01'}, {month_name: 'February', month_id: '02'}, {month_name: 'March', month_id: '03'}, {month_name: 'April', month_id: '04'}, {month_name: 'May', month_id: '05'}, {month_name: 'June', month_id: '06'}, {month_name: 'July', month_id: '07'}, {month_name: 'August', month_id: '08'}, {month_name: 'September', month_id: '09'}, {month_name: 'October', month_id: '10'}, {month_name: 'November', month_id: '11'}, {month_name: 'December', month_id: '12'}]

};

import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UploadService } from "../../_services/upload.service";
import { UserService } from "../../_services/user.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-ushf",
  templateUrl: "./ushf.component.html",
  styleUrls: ["./ushf.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class USHFComponent implements OnInit {
  loggedInUserId: string;
  demographicId: string;
  resultJsonObj: any = {};
  docInfo: any = {};
  companySlug: string = ConstantHelper.COMPANY_SLUG;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  statesArre: any = [];
  stateSelectDisabledOption = true;
  citySelectDisabledOption = true;
  physicianSelectDisabledOption = true;
  cityLength = false;
  noCityLength = false;
  citiesList: any;
  physicianLength = false;
  noPhysicianLength = false;
  physiciansList: any = [];
  selectedPhysician: any = {};
  hippaAvailable: any;
  selectedDocUrl: any;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private uploadService: UploadService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");

    const finalData = {
      id: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findPhysicianCitiesApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.statesArre = apiResponse.commandResult.data.statesList;
          // console.log('this.statesArre=>', this.statesArre)
        },
        (err) => {
          // console.log(err);
        }
      );

    this.docInfo.state = "";
    this.docInfo.city = "";
    this.docInfo.physicianName = "";
  }

  async onReset() {
    // if (confirm('PLEASE CONFIRM: Are you certain you want to return to the Home Page?'))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(["/dashboard/" + this.loggedInUserId]);
    }
  }

  async onSubmit() {
    if (this.selectedDocUrl) {
      // if (confirm('PLEASE CONFIRM: Are you sure?')) {
      window.open(this.selectedDocUrl);
      // }
    } else {
      // alert("No HIPAA form found for the state.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminushfComp.onSubmit1
      );
    }
  }

  findCitiesByStateCode(stateCode) {
    this.stateSelectDisabledOption = false;
    this.citiesList = [];

    // tslint:disable-next-line: object-literal-shorthand
    const finalData = { stateCode: stateCode };
    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log(apiResponse);
          this.citySelectDisabledOption = true;
          if (apiResponse.commandResult.status == 1) {
            if (apiResponse.commandResult.data.cityList.length > 0) {
              this.cityLength = true;
              this.citiesList = apiResponse.commandResult.data.cityList;
              // console.log('this.citiesList', this.citiesList);
            } else {
              this.noCityLength = true;
            }
          } else {
            this.noCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPhysicianByStateCode(cityName) {
    this.citySelectDisabledOption = false;
    // tslint:disable-next-line: object-literal-shorthand
    const finalDataPhysician = { cityName: cityName };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_getPhysiciansByCityCodeApi,
        finalDataPhysician,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          if (apiResponsePhysician.commandResult.status == 1) {
            this.physicianSelectDisabledOption = true;
            if (
              apiResponsePhysician.commandResult.data.physiciansList.length > 0
            ) {
              this.physicianLength = true;
              this.physiciansList =
                apiResponsePhysician.commandResult.data.physiciansList;
              // console.log('physiciansList List');
              // console.log(this.physiciansList);
            } else {
              // alert("No Physicians Found");
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.adminsecondoptwfCOmp
                  .findcoePhysicians
              );
              this.noPhysicianLength = true;
            }
          } else {
            // alert("No Physicians Found");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findcoePhysicians
            );
            this.noPhysicianLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  updatePhysicianInfo(physicianId) {
    this.physicianSelectDisabledOption = false;

    this.selectedPhysician = this.physiciansList.find(
      (e) => e.RecordId == physicianId
    );
    this.selectedPhysician.PhysicianEmail =
      this.selectedPhysician.PhysicianEmail.substring(0, 4) + "****@***.***";

    // console.log(this.selectedPhysician);
  }

  onChangeUserState(stateCode) {
    // console.log('State Code=> ', stateCode);
    this.userService.findHippaByStateCode(stateCode).subscribe(
      (apiResponse) => {
        // console.log(apiResponse);
        this.resultJsonObj = apiResponse;
        if (this.resultJsonObj.commandResult.status == 1) {
          this.hippaAvailable = true;
          this.selectedDocUrl =
            this.resultJsonObj.commandResult.data.hippaDetails.DocumentUrl;
        } else {
          this.hippaAvailable = false;
        }
      },
      (err) => {
        // console.log(err);
      }
    );
  }
}

import { from } from "rxjs";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";

import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

declare let $: any;

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UploadService } from "../../_services/upload.service";
import { AppConfig } from "./../../../app/app.config";
import { UserService } from "../../_services/user.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-admin-uploaddoc",
  templateUrl: "./admin-uploaddoc.component.html",
  styleUrls: ["./admin-uploaddoc.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class AdminUploaddocComponent implements OnInit {
  @ViewChild("fileUploaded1", { static: false }) fileUploaded1: ElementRef;
  @ViewChild("fileUploaded2", { static: false }) fileUploaded2: ElementRef;
  @ViewChild("fileUploaded3", { static: false }) fileUploaded3: ElementRef;
  @ViewChild("fileUploaded4", { static: false }) fileUploaded4: ElementRef;
  @ViewChild("fileUploaded5", { static: false }) fileUploaded5: ElementRef;

  fileToUpload1: File = null;
  fileToUpload2: File = null;
  fileToUpload3: File = null;
  fileToUpload4: File = null;
  fileToUpload5: File = null;

  docType1 = "";
  docType2 = "";
  docType3 = "";
  docType4 = "";
  docType5 = "";

  fileSize1 = 0;
  fileSize2 = 0;
  fileSize3 = 0;
  fileSize4 = 0;
  fileSize5 = 0;

  fileName1 = "";
  fileName2 = "";
  fileName3 = "";
  fileName4 = "";
  fileName5 = "";

  loggedInUserId: string;
  demographicId: string;
  userId: string;

  companySlug = ConstantHelper.COMPANY_SLUG;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  childRoute: string;
  patientName: any;
  loggedInUserRoleId:any;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  delegateId: any;
  isDelegating: any = 0;
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private uploadService: UploadService,
    private userService: UserService,
    public sweetAlertHelper: SweetAlertHelper,
    private renderer: Renderer2
  ) {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.demographicId =
      this.activatedRoute.snapshot.paramMap.get("demographicId");
    this.userId = this.activatedRoute.snapshot.paramMap.get("userId");
    this.findUserDetails(this.userId);
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.delegateId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.loggedInUserId;
    this.isDelegating = localStorage.getItem("isDelegating")
      ? localStorage.getItem("isDelegating")
      : 0;
      this.loggedInUserRoleId = localStorage.getItem("loggedInUserRoleId");
  }

  ngOnInit() {}

  findUserDetails(demographicUserId) {
    const finalData = {
      id: demographicUserId,
    };
    // console.log(finalData)
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.patientName = this.userService.getUS_FromatName(
            apiResponse.commandResult.data.userInfo.FullName
          );
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  onFileSelected1(event) {
    if (this.fileUploaded1.nativeElement.value != "") {
      this.fileToUpload1 = event.target.files.item(0) as File;
      this.fileSize1 = Math.round(this.fileToUpload1.size / 1024);
      this.fileName1 = this.fileToUpload1.name;
    } else {
    }
  }

  onFileSelected2(event) {
    if (this.fileUploaded2.nativeElement.value != "") {
      this.fileToUpload2 = event.target.files.item(0) as File;
      this.fileSize2 = Math.round(this.fileToUpload2.size / 1024);
      this.fileName2 = this.fileToUpload2.name;
    } else {
    }
  }

  onFileSelected3(event) {
    if (this.fileUploaded3.nativeElement.value != "") {
      this.fileToUpload3 = event.target.files.item(0) as File;
      this.fileName3 = this.fileToUpload3.name;
      this.fileSize3 = Math.round(this.fileToUpload3.size / 1024);
    } else {
    }
  }

  onFileSelected4(event) {
    if (this.fileUploaded4.nativeElement.value != "") {
      this.fileToUpload4 = event.target.files.item(0) as File;
      this.fileSize4 = Math.round(this.fileToUpload4.size / 1024);
      this.fileName4 = this.fileToUpload4.name;
    } else {
    }
  }

  onFileSelected5(event) {
    if (this.fileUploaded5.nativeElement.value != "") {
      this.fileToUpload5 = event.target.files.item(0) as File;
      this.fileSize5 = Math.round(this.fileToUpload5.size / 1024);
      this.fileName5 = this.fileToUpload5.name;
    } else {
    }
  }

  async uploadFile() {
    let confMsg = "";
    if (
      this.fileUploaded1.nativeElement.value == "" &&
      this.fileUploaded2.nativeElement.value == "" &&
      this.fileUploaded3.nativeElement.value == "" &&
      this.fileUploaded4.nativeElement.value == "" &&
      this.fileUploaded5.nativeElement.value == ""
    ) {
      //   alert("Please select at least on file.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4
      );
      return;
    }

    if (this.fileToUpload1 && !this.validateFile(this.fileToUpload1.name)) {
      //   alert("File 1 can be only .png, .jpg, .jpeg. or .pdf file formats.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d21_7
      );
      return false;
    } else if (this.fileSize1 > 61440) {
      //   alert("File 1 is too Big, please select a file less than 60mb");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_1
      );
    } else if (this.fileToUpload1 && this.docType1 == "") {
      //   alert("Please choose File 1 Folder.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminUploadComp.uploadFile3
      );
      return false;
    } else if (
      this.fileToUpload2 &&
      !this.validateFile(this.fileToUpload2.name)
    ) {
      //   alert("File 2 can be only .png, .jpg, .jpeg. or .pdf file formats.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4
      );
      return false;
    } else if (this.fileSize2 > 61440) {
      //   alert("File 2 is too Big, please select a file less than 60mb");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_3
      );
    } else if (this.fileToUpload2 && this.docType2 == "") {
      //   alert("Please choose File 2 Folder.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminUploadComp.uploadFile6
      );
      return false;
    } else if (
      this.fileToUpload3 &&
      !this.validateFile(this.fileToUpload3.name)
    ) {
      //   alert("File 3 can be only .png, .jpg, .jpeg. or .pdf file formats.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4
      );
      return false;
    } else if (this.fileSize3 > 61440) {
      //   alert("File 3 is too Big, please select a file less than 60mb");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d04_5
      );
    } else if (this.fileToUpload3 && this.docType3 == "") {
      //   alert("Please choose File 3 Folder.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminUploadComp.uploadFile9
      );
      return false;
    } else if (
      this.fileToUpload4 &&
      !this.validateFile(this.fileToUpload4.name)
    ) {
      //   alert("File 4 can be only .png, .jpg, .jpeg. or .pdf file formats.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4
      );
      return false;
    } else if (this.fileSize4 > 61440) {
      //   alert("File 4 is too Big, please select a file less than 60mb");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminUploadComp.uploadFile11
      );
    } else if (this.fileToUpload4 && this.docType4 == "") {
      //   alert("Please choose File 4 Folder.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminUploadComp.uploadFile12
      );
      return false;
    } else if (
      this.fileToUpload5 &&
      !this.validateFile(this.fileToUpload5.name)
    ) {
      //   alert("File 5 can be only .png, .jpg, .jpeg. or .pdf file formats.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4
      );
      return false;
    } else if (this.fileSize5 > 61440) {
      //   alert("File 5 is too Big, please select a file less than 60mb");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminUploadComp.uploadFile14
      );
    } else if (this.fileToUpload5 && this.docType5 == "") {
      //   alert("Please choose File 5 Folder.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminUploadComp.uploadFile15
      );
      return false;
    } else {
      let actingUserId = this.delegateId
        ? this.delegateId
        : this.loggedInUserId;
      //
      //   confMsg = this.message.confirmMessages.adminUploaddocComp.uploadFile;
      //   const confirmStatus = await this.sweetAlertHelper.confirmPopUp(
      //     this.message.confirmTitle,
      //     confMsg
      //   );
      //   if (confirmStatus)

      // if (
      //   confirm("PLEASE CONFIRM: Are you sure you want to upload the file?")
      // )
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.adminUploaddocComp.uploadFile
      );
      if (alertStatus) {
        this.uploadService
          .upload5Files(
            actingUserId,
            this.userId,
            this.demographicId,
            this.companySlug,
            localStorage.getItem("loggedInUserComapnyId"),
            this.fileToUpload1,
            this.docType1,
            this.fileName1,

            this.fileToUpload2,
            this.docType2,
            this.fileName2,

            this.fileToUpload3,
            this.docType3,
            this.fileName3,

            this.fileToUpload4,
            this.docType4,
            this.fileName4,

            this.fileToUpload5,
            this.docType5,
            this.fileName5
          )
          .subscribe(
            async (data: any) => {
              if (data !== undefined) {
                if (data.commandResult.success == 1) {
                  // alert('File Uploaded Successfully!');
                  //   if (
                  //     confirm(
                  //       "PLEASE CONFIRM: Do you want to upload more files? Yes stay on the page. No return to Home page. "
                  //     )
                  //   )
                  const alertStatus =
                    await this.sweetAlertHelper.confirmPopUpwithYesOrNo(
                      this.message.confirmTitle,
                      this.message.confirmMessages.adminUploaddocComp
                        .uploadFile1
                    );
                  if (alertStatus) {
                    window.location.reload();
                  } else {
                    this.router.navigate([
                      "/" +
                        this.childRoute +
                        "/demographics/" +
                        this.loggedInUserId,
                    ]);
                  }
                }
              }
            },
            (error) => {
              // console.log(error);
            }
          );
      } else {
        this.router.navigate([
          "/" + this.childRoute + "/demographics/" + this.loggedInUserId,
        ]);
      }
    }
  }

  async gotToDashboard() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure you want to return to the Home Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
      ]);
    }
  }

  validateFile(name: String) {
    let ext = name.substring(name.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "pdf" ||
      ext.toLowerCase() == "mp4" 
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkCheckBoxvalue(event) {
    // console.log(event.checked);
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the User Database?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminContactUsComp.goToDemographicsComp
    );
    if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
      ]);
    }
  }

  async goToDemographics() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Confirm your desire to return to the 'Patient user' list. "
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminUploaddocComp.gotoDemgraphics
    );
    if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/demographics/" + this.loggedInUserId,
      ]);
    }
  }
}

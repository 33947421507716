import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SignaturePad } from "angular2-signaturepad";
import { ApiHelper } from "../../_helpers/api.helper";
import { CommonHelper } from "../../_helpers/common.helper";
import { AppConfig } from "../../app.config";
import { UserService } from "../../_services/user.service";
import * as moment from "moment";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

@Component({
  selector: "app-la",
  templateUrl: "./la.component.html",
  styleUrls: ["./la.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class LaComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  hippaJson: any = {};
  loggedInUserId: string;
  userSignatures: any = [];
  userSignatureSelected = false;
  userFullSignature = "";
  userInitialSignature = "";
  userChoosedSignature = "";
  userFullSignatureExit = false;
  userInitialSignatureExis = false;
  showPdfModal = false;
  // city = "";
  signatureType: any;

  ssnPattern = "^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$";

  resultJsonObj: any = {};
  clean = "0";
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  @ViewChild("canvasArea", { static: true }) canvasArea: ElementRef;
  canvasElm: any;
  public signaturePadOptions: Object = {
    minWidth: 1,
    canvasWidth:
      window.innerWidth < 1024 ? window.innerWidth : window.innerWidth / 2,
    canvasHeight: 450,
  };

  currentDate = new Date();
  dateFormat = "MM-dd-yyyy";

  userWitnessSignatureSelected = false;
  userWitnessChoosedSignature = "";

  userAgencyRepresentSelected = false;
  userAgencyChoosedSignature = "";

  signatureFroWhom: any;
  isEmail: any;
  isPracticePhy: any;
  physicianId: any;
  requestId: any;
  tempId: any;
  userOptionId: any;
  emailLogId: any;
  patientId: string;
  fileDesTxt: any = "";
  selectedDelegateId: any;
  assignedDelegateId: any;
  isDelegated: any = 0;
  selectedDocUrl: any;
  selectedPhysician: any = {};
  docInfo: any = {};
  selectedStateCode: any;
  showLoader = false;
  childRoute: string;
  patient_name: any;
  physicianName: any;
  PhysicianOfficeAddress: any;
  patient_dob: any;
  patient_city: any;
  patient_ssn: any;
  loginUserType: any;
  healthcare_provider: any;
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public userService: UserService
  ) {
    // alert(localStorage.getItem('delegateId'));
    this.loggedInUserId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.isEmail = this.activatedRoute.snapshot.paramMap.get("isEmail")
      ? this.activatedRoute.snapshot.paramMap.get("isEmail")
      : 0;
    this.isPracticePhy =
      this.activatedRoute.snapshot.paramMap.get("isPracticePhy");
    this.physicianId = this.activatedRoute.snapshot.paramMap.get("physicianId")
      ? this.activatedRoute.snapshot.paramMap.get("physicianId")
      : 0;
    this.requestId = this.activatedRoute.snapshot.paramMap.get("requestId")
      ? this.activatedRoute.snapshot.paramMap.get("requestId")
      : 0;

    this.tempId = this.activatedRoute.snapshot.paramMap.get("tempId")
      ? this.activatedRoute.snapshot.paramMap.get("tempId")
      : 0;
    this.userOptionId = this.activatedRoute.snapshot.paramMap.get(
      "userOptionId"
    )
      ? this.activatedRoute.snapshot.paramMap.get("userOptionId")
      : 0;
    this.emailLogId = this.activatedRoute.snapshot.paramMap.get("emailLogId")
      ? this.activatedRoute.snapshot.paramMap.get("emailLogId")
      : 0;

    this.hippaJson.healthcare_provider = this.userService.getUS_FromatName(
      localStorage.getItem("providerName")
    );
    this.hippaJson.healthcare_provider_email =
      localStorage.getItem("providerEmail");
    this.hippaJson.healthcare_provider_npi = localStorage.getItem(
      "providerPhysicianNipNo"
    );
    this.hippaJson.street_address = localStorage.getItem(
      "providerOfficeAddress"
    );
    this.hippaJson.city_zip = localStorage.getItem("providerCity")
      ? localStorage.getItem("providerCity")
      : "" + ", " + localStorage.getItem("PhysicianStateName")
      ? localStorage.getItem("PhysicianStateName")
      : "" + ", " + localStorage.getItem("providerZipCode")
      ? localStorage.getItem("providerZipCode")
      : "";
    this.hippaJson.city_zip = localStorage.getItem("providerCity");
    this.hippaJson.providerCity = localStorage.getItem("providerCity");
    this.hippaJson.providerPhone = localStorage.getItem("providerPhone");
    this.hippaJson.patientPhone = localStorage.getItem("patientPhone");
    this.hippaJson.providerPhysicianNipNo = localStorage.getItem(
      "providerPhysicianNipNo"
    );

    // this.hippaJson.patient_name = this.userService.getUS_FromatName(localStorage.getItem('loggedInUserName'));
    // this.fnFindSignature(this.loggedInUserId);
    this.fnFindDemographic(this.patientId);
    this.findUserDetails(this.patientId);

    // this.hippaJson.patient_signature_date = this.currentDate;
    // this.hippaJson.witness_signature_date = this.currentDate;
    this.hippaJson.pre_to = this.currentDate;
    this.hippaJson.post_to = this.currentDate;
    this.hippaJson.healthcare_provider = localStorage.getItem(
      "selectedPhysicianName"
    );
    this.hippaJson.PhysicianOfficeAddress = localStorage.getItem(
      "selectedPhysicianOfficeAddress"
    );
    this.hippaJson.physicianNPI = localStorage.getItem("selectedPhysicianNPI");
    this.hippaJson.physicianEmail = localStorage.getItem(
      "selectedPhysicianEmail"
    );
    this.hippaJson.physicianPhone = localStorage.getItem("Mobile");
  }

  ngOnInit() {
    // console.log(localStorage);
    this.patientId = this.activatedRoute.snapshot.paramMap.get("patientId");
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");

    this.hippaJson.checkbox_01 = false;
    this.hippaJson.checkbox_02 = false;
    this.hippaJson.checkbox_03 = false;
    this.hippaJson.checkbox_04 = false;
    this.hippaJson.checkbox_05 = false;
    this.hippaJson.checkbox_06 = false;
    this.hippaJson.checkbox_07 = false;
    this.hippaJson.checkbox_08 = false;
    this.hippaJson.checkbox_09 = false;
    this.hippaJson.checkbox_10 = false;
    this.hippaJson.checkbox_11 = false;
    this.hippaJson.checkbox_12 = false;
    this.hippaJson.checkbox_13 = false;
    this.hippaJson.checkbox_14 = false;
    this.hippaJson.checkbox_15 = false;
    this.hippaJson.checkbox_16 = false;
    this.hippaJson.checkbox_17 = false;
    this.hippaJson.checkbox_18 = false;
    this.hippaJson.checkbox_19 = false;
    this.hippaJson.checkbox_20 = false;
    this.hippaJson.checkbox_21 = false;
    this.hippaJson.checkbox_22 = false;
    this.hippaJson.checkbox_23 = false;
    this.hippaJson.checkbox_24 = false;
    this.hippaJson.checkbox_25 = false;
    this.hippaJson.checkbox_26 = false;
    this.hippaJson.checkbox_27 = false;
    this.hippaJson.checkbox_28 = false;
    this.hippaJson.checkbox_29 = false;
    this.hippaJson.patient_name = "";
    this.hippaJson.physicianName = "";
    this.hippaJson.physicianAddress = "";
    this.hippaJson.patient_dob = "";
    this.hippaJson.patient_ssn = "";
    this.hippaJson.patient_address = "";
    this.hippaJson.patient_city = "";
    this.hippaJson.patient_state = "";
    this.hippaJson.patient_zip = "";
    this.hippaJson.patientPhone = "";
    this.hippaJson.patient_fax = "";
    this.hippaJson.release_info = "";
    this.hippaJson.mailing_address = "";
    this.hippaJson.mailaddress = "";
    this.hippaJson.mail_address = "";
    this.findUserDetails(this.patientId);

    (this.hippaJson.physicianName = localStorage.getItem(
      "selectedPhysicianName"
    )),
      (this.hippaJson.street_address = this.hippaJson.PhysicianOfficeAddress);
    this.hippaJson.city_zip =
      this.hippaJson.physicianCity + "," + this.hippaJson.physicianState;
  }

  findUserDetails(patientId) {
    const finalData = {
      company_slug: null,
      id: patientId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            this.hippaJson.name_patient =
              apiResponse.commandResult.data.userInfo.LastName +
              ", " +
              apiResponse.commandResult.data.userInfo.FirstName;
            this.hippaJson.dob = apiResponse.commandResult.data.userInfo.DOB;
            this.hippaJson.tele_no =
              apiResponse.commandResult.data.userInfo.Mobile;
            this.hippaJson.mailing_address =
              apiResponse.commandResult.data.userInfo.Email;
            // console.log("patient email ",this.hippaJson.patient_email);
            //console.log("patient ph: ", this.hippaJson.tele_no);
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async onSubmit() {
    // if (
    //   confirm(
    //     'PLEASE CONFIRM: to print generated  State HIPAA Form.'
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.onSubmit
    );
   if(alertStatus) {
      const finalData = {
        selectedDocUrl: this.selectedDocUrl,
        fileDesTxt: this.fileDesTxt,
        loggedInUserId: this.loggedInUserId,
        userId: this.patientId,
        //loginUserType:this.loginUserType,
        patient_name: this.hippaJson.patient_name,
        name_patient: this.hippaJson.name_patient,
        dob: this.hippaJson.dob,
        patient_address: this.hippaJson.patient_address,
        patient_city: this.hippaJson.patient_city,
        patient_state: this.hippaJson.patient_state,
        patient_phone: this.hippaJson.patient_phone,
        patient_fax: this.hippaJson.patient_fax,
        //patient_email:this.hippaJson.patient_email,
        physicianNPI: this.hippaJson.PhysicianNipNo,
        healthcare_provider: this.hippaJson.physicianName,
        street_address: this.hippaJson.PhysicianOfficeAddress,
        city_zip: this.hippaJson.city_zip,
        physicianState: this.selectedStateCode,
        assignedDelegateId: this.assignedDelegateId,
        isDelegated: this.isDelegated,
        selectedDelegateId: this.selectedDelegateId,
        selectedEmail: this.selectedPhysician.altEmail,
        selectedPhysician: this.selectedPhysician.physicianName,
        physicianPhone: this.hippaJson.physicianPhone,
        checkbox_01: this.hippaJson.checkbox_01,
        checkbox_02: this.hippaJson.checkbox_02,
        checkbox_03: this.hippaJson.checkbox_03,
        checkbox_04: this.hippaJson.checkbox_04,
        checkbox_05: this.hippaJson.checkbox_05,
        checkbox_06: this.hippaJson.checkbox_06,
        checkbox_07: this.hippaJson.checkbox_07,
        checkbox_08: this.hippaJson.checkbox_08,
        checkbox_09: this.hippaJson.checkbox_09,
        checkbox_10: this.hippaJson.checkbox_10,
        checkbox_11: this.hippaJson.checkbox_11,
        checkbox_12: this.hippaJson.checkbox_12,
        checkbox_13: this.hippaJson.checkbox_13,
        checkbox_14: this.hippaJson.checkbox_14,
        checkbox_15: this.hippaJson.checkbox_15,
        checkbox_16: this.hippaJson.checkbox_16,
        checkbox_17: this.hippaJson.checkbox_17,
        checkbox_18: this.hippaJson.checkbox_18,
        checkbox_19: this.hippaJson.checkbox_19,
        checkbox_20: this.hippaJson.checkbox_20,
        checkbox_21: this.hippaJson.checkbox_21,
        checkbox_22: this.hippaJson.checkbox_22,
        checkbox_23: this.hippaJson.checkbox_23,
        checkbox_24: this.hippaJson.checkbox_24,
        checkbox_25: this.hippaJson.checkbox_25,
        checkbox_26: this.hippaJson.checkbox_26,
        checkbox_27: this.hippaJson.checkbox_27,
        checkbox_28: this.hippaJson.checkbox_28,
        checkbox_29: this.hippaJson.checkbox_29,

        health_info: this.hippaJson.health_info,
        release_info: this.hippaJson.release_info,
        date: this.hippaJson.date,
        ssn_no: this.hippaJson.ssn_no,
        healthcare_provider_email: this.hippaJson.healthcare_provider_email,
        healthcare_provider_npi: this.hippaJson.healthcare_provider_npi,

        other_specify: this.hippaJson.other_specify,
        other: this.hippaJson.other,
        agency_representative: this.hippaJson.agency_representative,
        other_info: this.hippaJson.other_info,
        expire_on: this.hippaJson.expire_on,
        period_begining: this.hippaJson.period_begining,
        ending: this.hippaJson.ending,
        request_date: this.hippaJson.request_date,
        mailing_address: this.hippaJson.mailing_address,
        mailaddress: this.hippaJson.patient_email,
        city_statet_zip: this.hippaJson.patient_address,
        relation: this.hippaJson.relation,
        tele_no: this.hippaJson.patient_phone,
        mail_address: this.hippaJson.patient_email,
        citystatetzip: this.hippaJson.patient_address,
        relationship: this.hippaJson.relationship,
        telephone_no: this.hippaJson.patient_phone,
        isEmail: this.isEmail,
        requestId: this.requestId,
      };
      this.showLoader=true;
      return this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.generateHipaaLA,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            if (this.hippaJson.loggedInUserRoleId == 1) {
              this.router.navigate([
                "/doctor/" +
                  "/demographics/" +
                  this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
                  "/1/",
              ]);
            } else if (this.hippaJson.loggedInUserRoleId == 4) {
              this.router.navigate([
                "/doctor/" +
                  "/demographics/" +
                  this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
                  "/1/",
              ]);
            } else {
              if (window.open(apiResponse.commandResult.data, "_blank")) {
                this.router.navigate([
                  "/doctor/" +
                    "/ushf/" +
                    this.activatedRoute.snapshot.paramMap.get(
                      "loggedInUserId"
                    ) +
                    "/" +
                    this.activatedRoute.snapshot.paramMap.get("patientId") +
                    "/" +
                    this.activatedRoute.snapshot.paramMap.get("demographicId"),
                ]);
              }
            }
            this.showLoader=false;
          },
          (err) => {}
        );
    }
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Click ‘OK’ to confirm returning to the ‘Home Page’."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate([
        "/doctor/" +
          "/dashboard/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    }
  }

  async goBack() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the USHF Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.goBack
    );
    if (alertStatus) {
      this.router.navigate([
        "/doctor/" +
          "/ushf/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.activatedRoute.snapshot.paramMap.get("patientId") +
          "/" +
          this.activatedRoute.snapshot.paramMap.get("demographicId"),
      ]);
    }
  }

  closePdfModal() {
    this.showPdfModal = false;
    this.hippaJson.patient_signature = "";
  }

  // openSignatureCanvas(selectedValue, signatureFroWhom) {
  //   this.signatureFroWhom = signatureFroWhom;
  //   // console.log(selectedValue);
  //   this.signatureType = selectedValue;
  //   if (
  //     selectedValue == "AFS" ||
  //     selectedValue == "AIS" ||
  //     selectedValue == "AWTSG" ||
  //     selectedValue == "AAGC"
  //   ) {
  //     this.showPdfModal = true;
  //   }
  //   if (selectedValue == "FS" || selectedValue == "IS") {
  //     this.userSignatureSelected = true;
  //     if (selectedValue == "FS") {
  //       this.userChoosedSignature = this.userFullSignature;
  //     }
  //     if (selectedValue == "IS") {
  //       this.userChoosedSignature = this.userInitialSignature;
  //     }
  //   }
  // }

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set("minWidth", 1); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
  }

  // saveCanvas() {
  //   if (
  //     confirm(
  //       "PLEASE CONFIRM: This will replace your existing signature. Do you wish to change?"
  //     )
  //   ) {
  //     const reqData = {
  //       loggedInUserId: this.loggedInUserId,
  //       signatureBase64: this.signaturePad.toDataURL(),
  //       signatureType: this.signatureType,
  //     };
  //     return this.http
  //       .post(
  //         this.appConfig.getLambdaUrl05() +
  //           ApiHelper.name_saveSignatureImageApi,
  //         reqData,
  //         this.httpOptions
  //       )
  //       .subscribe(
  //         (apiResponse: any) => {
  //           if (apiResponse.commandResult.status == 1) {
  //             this.userSignatureSelected = true;
  //             this.userChoosedSignature =
  //               apiResponse.commandResult.data.fileInfo.signatureUrl;
  //             // console.log(this.userChoosedSignature);
  //             this.fnFindSignature(this.loggedInUserId);
  //             this.showPdfModal = false;
  //             // this.hippaJson.patient_signature = '';
  //           }
  //         },
  //         (err) => {
  //           // console.log(err);
  //         }
  //       );
  //   }
  // }

  // clearCanvas() {
  //   if (
  //     confirm("PLEASE CONFIRM: Are you sure you want to clear you signature.")
  //   ) {
  //     this.signaturePad.clear();
  //   }
  // }

  drawStart() {}

  fnFindDemographic(patientId) {
    const finalData = {
      id: patientId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_findUserDemographicGeneralApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiresponse: any) => {
          // alert(response.commandResult.data.userInfo.form1City);
          // this.hippaJson.dob = new Date(
          //   response.commandResult.data.userInfo.form1DOB
          // );
          this.hippaJson.representative_city_zip =
            apiresponse.commandResult.data.userInfo.form1City;
          this.hippaJson.city_zip =
            apiresponse.commandResult.data.userInfo.form1ResidenceNumber +
            ", " +
            apiresponse.commandResult.data.userInfo.form1StreetName +
            ", " +
            apiresponse.commandResult.data.userInfo.form1PrimaryPhysicianCity;
          this.hippaJson.citystatetzip =
            apiresponse.commandResult.data.userInfo.form1ResidenceNumber +
            ", " +
            apiresponse.commandResult.data.userInfo.form1StreetName +
            ", " +
            apiresponse.commandResult.data.userInfo.form1PrimaryPhysicianCity;
          this.hippaJson.city_statet_zip =
            apiresponse.commandResult.data.userInfo.form1ResidenceNumber +
            ", " +
            apiresponse.commandResult.data.userInfo.form1StreetName +
            ", " +
            apiresponse.commandResult.data.userInfo.form1PrimaryPhysicianCity;
          // this.hippaJson.city_zip = response.commandResult.data.userInfo.form1PrimaryPhysicianCity + ', ' + response.commandResult.data.userInfo.form1State + ', ' + response.commandResult.data.userInfo.form1ZIP;
          // this.hippaJson.street_address = response.commandResult.data.userInfo.form1StreetName;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  autoFormatSSN() {
    // alert('Amit');
    let val = this.hippaJson.ssn_no.replace(/\D/g, "");
    // val = val.replace(/^(\d{3})/, "$1-");
    // val = val.replace(/-(\d{2})/, "-$1-");
    // val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
    this.hippaJson.ssn_no = val;
  }

  openLABlankpdf() {
    window.open("https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/Louisiana-HIPAA-Medical-Release-Form.pdf", "_blank")
  }
  // saveParentCanvas(signFor) {
  //   const reqData = {
  //     loggedInUserId: this.loggedInUserId,
  //     signatureBase64: this.signaturePad.toDataURL(),
  //     signatureType: this.signatureType,
  //     signFor,
  //   };
  //   return this.http
  //     .post(
  //       this.appConfig.getLambdaUrl05() +
  //         ApiHelper.name_saveParentSignatureImageApi,
  //       reqData,
  //       this.httpOptions
  //     )
  //     .subscribe(
  //       (apiResponse: any) => {
  //         if (apiResponse.commandResult.status == 1) {
  //           if (signFor == "WTSG") {
  //             this.userWitnessSignatureSelected = true;
  //             this.userWitnessChoosedSignature =
  //               apiResponse.commandResult.data.fileInfo.signatureUrl;
  //           }
  //           if (signFor == "AGC") {
  //             this.userAgencyRepresentSelected = true;
  //             this.userAgencyChoosedSignature =
  //               apiResponse.commandResult.data.fileInfo.signatureUrl;
  //           }
  //           this.showPdfModal = false;
  //         }
  //       },
  //       (err) => {
  //         // console.log(err);
  //       }
  //     );
  // }
}

import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  HostListener
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from '@angular/common/http';

import {AuthService} from '../auth.service';
import {MustMatch} from '../../_helpers/matchpassword';

import {environment} from './../../../environments/environment';
import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';

import {AppConfig} from './../../../app/app.config';
import {ConstantHelper} from './../../_helpers/constant.helper';

@Component({
  selector: 'app-doctor-view-docs',
  templateUrl: './doctor-view-docs.component.html',
  styleUrls: ['./doctor-view-docs.component.scss']
})
export class DoctorViewDocsComponent implements OnInit {


  @ViewChild('recaptcha', {static: false}) recaptchaElement: ElementRef;
  @HostListener('window:resize', ['$event'])
  loginForm: FormGroup;
  loginFromSubmitted = false;
  formErrorMessage = false;
  inactiveUserErrorMessage = false;
  // showLoginForm = true;
  // verifyLoginOtpForm: FormGroup;
  // verifyLoginOtpFormSubmitted = false;
  // verifyLoginFormErrorMessage = false;
  // showVerifyLoginOtpForm = false;
  userOtp: number;
  userId: number;
  // userEmail: string;
  // userMobile: string;
  // userRoleId: number;
  resultJsonObj: any = {};
  formRecaptchaErrorMessage = false;
  pId: any = '';
  docEmail: any = '';
  // pUserId: any = '';
  // docId: any = '';
  fieldTextType: boolean;

  constructor(public commonHelper: CommonHelper,
              private appConfig: AppConfig,
              private formBuilder: FormBuilder,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private http: HttpClient) {

  }

  ngOnInit() {
    this.pId = this.activatedRoute.snapshot.paramMap.get('pId');
    this.docEmail = this.activatedRoute.snapshot.paramMap.get('docEmail');
    // this.pUserId = this.activatedRoute.snapshot.paramMap.get('pUserId');
    // this.docId = this.activatedRoute.snapshot.paramMap.get('docId');

    localStorage.removeItem('loggedInUserName');
    this.loginForm = this.formBuilder.group({
      password: ['', Validators.required],
      recaptchaValue: ['', Validators.required]
    });
  }

  get f() {
    return this.loginForm.controls;
  }


  onSubmit(loginFormValue) {
    this.loginFromSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const options = {
      headers: httpHeaders
    };
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      password: this.loginForm.value.password,
      pId: this.pId
    };
    // console.log('doctor login', finalData);

    return this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_authenticateDoctorApi,
        finalData,
        options
      )
      .subscribe(
        (response: any) => {
          // console.log(response);

          if (response.commandResult.status === 1) {

            this.router.navigate(['/admin/view-docs-acknowledgement/' + this.pId]);

          } else {
            this.formErrorMessage = true;

          }

        },
        err => {
          // console.log(err);
        }
      );

  }


  renderReCaptch() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      sitekey: ConstantHelper.RECAPTCHA_SITE_KEY_CLIENT,
      callback: response => {

        // console.log(response);
        this.loginForm.get('recaptchaValue').setValue(response);

        ///////////////////recaptcha validation /////////////////
        const httpHeaders = new HttpHeaders({
          'Content-Type': 'application/json'
        });
        const options = {
          headers: httpHeaders
        };
        const finalData = {
          recaptchaResponse: response
        };
        return this.http
          .post(
            this.appConfig.getLambdaUrl01() +
            ApiHelper.name_verifyRecaptachaApi,
            finalData,
            options
          )
          .subscribe(
            apiResponse => {
              // console.log(apiResponse);
              this.resultJsonObj = apiResponse;
              // console.log(this.resultJsonObj);

              if (this.resultJsonObj.commandResult.status === 1) {
                this.formRecaptchaErrorMessage = false;
              } else {
                this.formRecaptchaErrorMessage = true;
              }
            },
            err => {
              // console.log(err);
            }
          );
        /////////////////////////////////////////////////////////
      }
    });
  }

  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    };

    (function(d, s, id, obj) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        obj.renderReCaptch();
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src =
        'https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'recaptcha-jssdk', this);
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit() {
    this.addRecaptchaScript();
    this.captchScaleFunc();
  }

  onResize(event) {
    this.captchScaleFunc();
  }

  captchScaleFunc() {
    var captchacontainWidth = this.recaptchaElement.nativeElement.clientWidth;
    var scaleValue = captchacontainWidth / 304;
    if (scaleValue < 1) {
      this.recaptchaElement.nativeElement.style.transform =
        'scale(' + scaleValue + ')';
      this.recaptchaElement.nativeElement.style.transformOrigin = '0';
    }
    // console.log(captchacontainWidth);
  }


  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }


}

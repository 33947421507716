import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from "@angular/common/http";

@Component({
  selector: "app-waiver-disclaimer-navigation",
  templateUrl: "./waiver-disclaimer-navigation.component.html",
  styleUrls: ["./waiver-disclaimer-navigation.component.scss"]
})
export class WaiverDisclaimerNavigationComponent implements OnInit {
  loggedInUserId: string;
  demographicId: string;

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get(
      "loggedInUserId"
    );

    this.demographicId = this.activatedRoute.snapshot.paramMap.get(
      "demographicId"
    );
  }

  gotoWaiverForm() {
    this.router.navigate([
      "/waiverdisclaimerform/" + this.loggedInUserId + "/" + this.demographicId
    ]);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authnticationData: any = {
    'admin@gmail.com': {
      'mobile': '9555436989',
      'email': 'admin@gmail.com',
      'password': '123456',
    }
  }
  constructor() { }

  createRegistration(regiData) {
    let authDetailsObj = {};
    this.authnticationData[regiData['email']] = regiData;
    authDetailsObj['email'] = regiData['email'];
    authDetailsObj['password'] = regiData['password'];






    // localStorage.setItem('currentUser', JSON.stringify(authDetailsObj));
    // console.log(localStorage.getItem('currentUser'));
    
  }



  logIn(logData){
    // localStorage.setItem('currentUser', JSON.stringify(user));
    // this.currentUserSubject.next(user);
  }
}

import { Validators } from "@angular/forms";
import { ConfirmationformComponent } from "./../../user/confirmationform/confirmationform.component";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { UploadService } from "../../_services/upload.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-add-doc-group",
  templateUrl: "./add-doc-group.component.html",
  styleUrls: ["./add-doc-group.component.scss"],
})
export class AddDocGroupComponent implements OnInit {
  loggedInUserId: string;
  resultJsonObj: any = {};
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  selectedDocId: string;
  selectedDocUrl: string;
  selectedDocName: string;
  showAddGroupModal: boolean = false;
  docGroupObj: any = {};
  groupAdded: boolean = false;
  createNewGroup: boolean = false;

  documentsLength: boolean = false;
  documentsList: any;
  selectedDocArr: any = [];
  documentsLengthRight: boolean = false;
  documentsListRight: any = [];

  groupsLength: boolean = false;
  groupsList: any;

  selectedDocGroupId: any = 0;
  newGrpName: string = "";
  selectedDocsId: any = [];

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public sweetAlertHelper: SweetAlertHelper,
    private elementRef: ElementRef,
    private uploadService: UploadService
  ) {}

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");

    const finalDataDocGroup = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_findDocGroupsByCompanyApi,
        finalDataDocGroup,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.groupList.length > 0) {
            this.groupsLength = true;
            this.groupsList = this.resultJsonObj.commandResult.data.groupList;
            // console.log(this.groupsList);
          } else {
            this.groupsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );

    const finalDataDocList = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl02() +
          ApiHelper.name_getAdminDocumentsListApi,
        finalDataDocList,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
            // console.log(this.documentsList);
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findGroupDocs(event) {
    this.selectedDocGroupId = event.target.value;
    // console.log(event.target.value);
    if (event.target.value == 0) {
      this.createNewGroup = true;
    } else {
      const finalData = {
        loggedInUserId: this.loggedInUserId,
        company_slug: ConstantHelper.COMPANY_SLUG,
        doc_group_id: event.target.value,
      };

      this.http
        .post(
          this.appConfig.getLambdaUrl03() + ApiHelper.name_findDocsByGroupIdApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            // console.log(apiResponse);
            if (apiResponse.commandResult.data.documentsListRight.length > 0) {
              this.documentsLengthRight = true;
              this.documentsListRight =
                apiResponse.commandResult.data.documentsListRight;
              // console.log(this.documentsListRight);
            } else {
              this.documentsLengthRight = false;
            }
          },
          (err) => {
            // console.log(err);
          }
        );
      this.createNewGroup = false;
    }
  }

  addDoc2Group() {
    let allCheck: any = document.getElementsByClassName("leftcheck");
    this.documentsList.forEach((item, index) => {
      const element = allCheck[index];
      if (element.checked) {
        this.documentsListRight.push(this.documentsList[index]);
        this.documentsList.splice(this.documentsList[index], 1);
      }
    });
    // console.log(this.documentsList);
    // console.log(this.documentsListRight);
  }

  addDoc1Group() {
    let allCheck: any = document.getElementsByClassName("rightcheck");
    this.documentsListRight.forEach((item, index) => {
      const element = allCheck[index];
      if (element.checked) {
        // this.documentsList.push(this.documentsListRight[index]);
        this.documentsListRight.splice(this.documentsListRight[index], 1);
      }
    });
    // console.log(this.documentsList);
    // console.log(this.documentsListRight);
  }

  async saveDocGroup() {
    this.getSelectedDocId();
    if (this.selectedDocGroupId == 0 && this.newGrpName == "") {
      // alert("Enter Group Name");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.addDocGroupComp.saveDocGroup
      );
      return false;
    }

    if (this.selectedDocsId.length <= 0) {
      // alert("Please select at least one doc");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminUploadComp.uploadFile
      );

      return false;
    } else {
      // console.log(this.selectedDocsId);

      // if (confirm("PLEASE CONFIRM: Are you sure you want to continue?"))
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.addDocGroupComp.SaveDocComp
      );

      {
        this.uploadService
          .saveDocGroup(
            this.loggedInUserId,
            ConstantHelper.COMPANY_SLUG,
            this.selectedDocGroupId,
            this.selectedDocsId,
            this.newGrpName
          )
          .subscribe(
            async (data: any) => {
              // console.log(data);
              if (data !== undefined) {
                // alert("Docs sent successfully!");
                const alertStatus = await this.sweetAlertHelper.alertPopUp(
                  this.message.alertMessages.adminResendDocComp.onSubmit
                );
                window.location.reload();
              }
            },
            (error) => {
              // console.log(error);
            }
          );
      }
    }
  }

  getSelectedDocId() {
    this.selectedDocsId = [];
    let allCheck: any = document.getElementsByClassName("rightcheck");
    for (let index = 0; index < allCheck.length; index++) {
      const element = allCheck[index];
      this.selectedDocsId.push(element.id);
    }
  }
}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from '@angular/common/http';

import { environment } from './../../../environments/environment';
import { ApiHelper } from './../../_helpers/api.helper';
import { CommonHelper } from './../../_helpers/common.helper';
import { AppConfig } from './../../../app/app.config';
import { ConstantHelper } from './../../_helpers/constant.helper';
import { UploadService } from '../../_services/upload.service';
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

@Component({
  selector: 'app-admin-reply-d2d-mail',
  templateUrl: './admin-reply-d2d-mail.component.html',
  styleUrls: ['./admin-reply-d2d-mail.component.scss'],
  providers: [AppConfig, CommonHelper]

})
export class AdminReplyD2dMailComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  httpOptions = {
    headers: this.httpHeaders
  };
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  companySlug: string = ConstantHelper.COMPANY_SLUG;
  childRoute: string;

  pId: string;
  loggedInUserId: string;
  userOptionId: string;
  logId: string;
  selectedLog: any = {};
  maxChars = 300;
  fileDesTxt: any = '';
  message = Message;

  constructor(public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public sweetAlertHelper: SweetAlertHelper,
    private uploadService: UploadService) {
    localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.pId = this.activatedRoute.snapshot.paramMap.get('pId');
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.userOptionId = this.activatedRoute.snapshot.paramMap.get('userOptionId');
    this.logId = this.activatedRoute.snapshot.paramMap.get('logId');
    this.fnFindLogDetails(this.logId, this.userOptionId);
  }


  ngOnInit() {


  }





  fnFindLogDetails(logId, logType) {
    const
      reqData = {
        logId,
        logType
      };
    this.http.post(this.appConfig.getPhpUrl() + ApiHelper.php_findD2DLogDetailApi, reqData, this.httpOptions)
      .subscribe(
        (res: any) => {
          // console.log('logDetail=>', res);
          this.selectedLog = res.commandResult.data.log;
        },
        err => {
          // console.log(err);
        }
      );

  }

  goBack() {
    this.router.navigate(['/' + this.childRoute + '/transfer-log/' + this.loggedInUserId]);
  }




  onSubmit() {
    const
      reqData = {
        logId: this.logId,
        emailText: this.fileDesTxt
      };

    this.http.post(this.appConfig.getPhpUrl() + ApiHelper.php_sendD2dResponseApi, reqData, this.httpOptions)
      .subscribe(
        async (res: any) => {
          //alert('Response sent successfully');
          const alertStatus = await this.sweetAlertHelper.alertPopUp(
            this.message.alertMessages.adminReplyd2dMailComp.onSubmit
          );
          this.router.navigate(['/' + this.childRoute + '/transfer-log/' + this.loggedInUserId]);
        },
        err => {
          // console.log(err);
        }
      );

  }



}

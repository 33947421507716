import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from "@angular/common/http";

@Component({
  selector: "app-hippo-release-navigation",
  templateUrl: "./hippo-release-navigation.component.html",
  styleUrls: ["./hippo-release-navigation.component.scss"]
})
export class HippoReleaseNavigationComponent implements OnInit {
  loggedInUserId: string;
  demographicId: string;

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get(
      "loggedInUserId"
    );

    this.demographicId = this.activatedRoute.snapshot.paramMap.get(
      "demographicId"
    );
  }

  gotoHippaForm() {
    this.router.navigate([
      "/hippoReleaseform/" + this.loggedInUserId + "/" + this.demographicId
    ]);
  }
}

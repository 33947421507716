import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UploadService } from "../../_services/upload.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

@Component({
  selector: "app-admin-send-email",
  templateUrl: "./admin-send-email.component.html",
  styleUrls: ["./admin-send-email.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class AdminSendEmailComponent implements OnInit {
  childRoute: string;
  loggedInUserId: string;
  userId: string;
  demographicId: string;
  loggedInUserEmail: string;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  emailObj: any = {};
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  userName: string;
  userEmail: string;
  showDocumentModal = false;
  documentsLength = false;
  documentsList: any = [];
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public sweetAlertHelper: SweetAlertHelper,
    private uploadService: UploadService
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.demographicId =
      this.activatedRoute.snapshot.paramMap.get("demographicId");
    this.userId = this.activatedRoute.snapshot.paramMap.get("userId");
    this.findUserDetails();
  }

  ngOnInit() {}

  findUserDetails() {
    const finalData = {
      id: this.userId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log('userd detail', apiResponse);
          this.userName = apiResponse.commandResult.data.userInfo.FullName;
          this.userEmail = apiResponse.commandResult.data.userInfo.Email;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the User Database?"
    //   )
    // )

    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminContactUsComp.goToDemographicsComp
    );
    if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/demographics/" + this.loggedInUserId,
      ]);
    }
  }

  async onSubmit() {
    // if (confirm("PLEASE CONFIRM: Are you sure?."))

    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.addAdminUserComp.onSubmit
    );

    {
      const finalData = {
        loggedInUserId: this.loggedInUserId,
        patientId: this.userId,
        emailLogSub: this.emailObj.emailLogSub,
        emailLogTxt: this.emailObj.emailLogTxt,
      };
      this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_sendEmailApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse: any) => {
            // console.log(apiResponse);
            // alert("Email sent successfully!!");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.d2d04_8
            );
            // window.location.reload();
            if (alertStatus)
              this.router.navigate([
                "/" + this.childRoute + "/demographics/" + this.loggedInUserId,
              ]);
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  setAttachment(setAttachment) {}
  closeDocumentModal() {}
}

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
declare let $: any;

@Component({
  selector: "app-view-user-docs",
  templateUrl: "./view-user-docs.component.html",
  styleUrls: ["./view-user-docs.component.scss"],
})
export class ViewUserDocsComponent implements OnInit {
  loggedInUserId: string;
  demographicId: string;
  resultJsonObj: any = {};
  hidePdfPopUp = false;
  demographicUrl: SafeResourceUrl;
  hippaUrl: SafeResourceUrl;
  disclaimerUrl: SafeResourceUrl;
  ahsDisclaimerPdf: SafeResourceUrl;
  ahsHippaPdf: SafeResourceUrl;
  showDocThumbnails = true;
  ahsPdfUrl: SafeResourceUrl = "";
  documentsLength = false;
  documentsList: any;
  docGroupId: any;
  docGroupTitle: any;
  docSet: any;
  docTyep: any;
  companyDefaultGroupTitle: any;
  companyDefaultGroupId: any;
  activeGroupId: any;
  selectedDocId: any;
  signedGroups: any = [];
  signedGroupsCount: any;
  pId: any = "";
  pageExpire = 0;

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  profileObj: any;
  showDeleteButton = false;
  showHippaUpdateBtn: string;
  patientObj: any;
  patientName: string;
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.pId = this.activatedRoute.snapshot.paramMap.get("pId");
    this.showHippaUpdateBtn = localStorage.getItem("showHippaUpdateBtn");
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this.checkActiveUrl(this.pId);
    this.findUserDetails(this.pId);
    this.findPatientUserInfo(this.pId);
  }

  checkActiveUrl(pId) {
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      pId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_checkDocActiveUrlApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log('apiResponse=>', apiResponse);
          if (apiResponse.commandResult.status == 0) {
            this.pageExpire = 2;
          } else {
            this.pageExpire = 1;
            if (apiResponse.commandResult.data.documentList.length > 0) {
              this.documentsLength = true;
              this.documentsList = apiResponse.commandResult.data.documentList;
              // console.log(this.documentsList);
            } else {
              this.documentsLength = false;
            }
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  showDocument(documentId, documentUrl, docTyep) {
    this.selectedDocId = documentId;
    this.docTyep = docTyep;
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(documentUrl);
    this.showDeleteButton = true;
  }

  async clearViewr() {
    // if (confirm("PLEASE CONFIRM: Are you sure, you want to clear preview?"))

    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminDocReviewComp.clearViewr
    );
    {
      this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
      this.showDeleteButton = false;
    }
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the Home Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(["admin/dashboard/" + this.loggedInUserId]);
    }
  }

  deleteDoc() {}

  findUserDetails(userId) {
    // alert('findUserDetails');

    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      id: userId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log(apiResponse);
          if (apiResponse.commandResult.status == 1) {
            this.profileObj = apiResponse.commandResult.data.userInfo;
            // console.log('ddddddd',this.profileObj);
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPatientUserInfo(patientUserHashId) {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
      profileId: patientUserHashId,
    };
    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findUserDetailsByUserHashApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.patientObj = apiResponse;
          // console.log('patienthash', apiResponse.commandResult.data.UserInfo);
          this.patientName = apiResponse.commandResult.data.UserInfo.FullName;
          // console.log(this.patientName);
        },
        (err) => {
          // console.log(err);
        }
      );
  }
}

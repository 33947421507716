import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  AfterViewInit,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { from } from "rxjs";
import { DatePipe } from "@angular/common";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { SignaturePad } from "angular2-signaturepad";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { UserService } from "./../../_services/user.service";
import * as moment from "moment";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

@Component({
  selector: "app-ks",
  templateUrl: "./ks.component.html",
  styleUrls: ["./ks.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class KsComponent implements OnInit, AfterViewInit {
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  hippaJson: any = {};
  loggedInUserId: string;
  userSignatures: any = [];
  userSignatureSelected = false;
  userChoosedSignature = "";
  isEmail: any;
  isPracticePhy: any;
  physicianId: any;
  requestId: any;
  patientId: any;
  isDelegated: any = 0;
  selectedDocUrl: any;
  fileDesTxt: any = "";
  selectedStateCode: any;
  assignedDelegateId: any;
  selectedDelegateId: any;
  selectedPhysician: any = {};
  docInfo: any = {};
  showLoader = false;
  childRoute: string;
  tempId: any;
  userOptionId: any;
  emailLogId: any;

  userParentSignatureSelected = false;
  userParentChoosedSignature = "";
  loginUserType: any;
  isForParentSign = false;

  userFullSignature = "";
  userInitialSignature = "";
  userFullSignatureExit = false;
  userInitialSignatureExis = false;
  showPdfModal = false;
  signatureType: any;
  ssnPattern = "^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$";
  resultJsonObj: any = {};
  clean: string = "0";
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  @ViewChild("canvasArea", { static: true }) canvasArea: ElementRef;
  canvasElm: any;
  public signaturePadOptions: Object = {
    minWidth: 1,
    canvasWidth:
      window.innerWidth < 1024 ? window.innerWidth : window.innerWidth / 2,
    canvasHeight: 450,
  };

  currentDate = new Date();
  dateFormat = "MM-dd-yyyy";
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer,
    public userService: UserService
  ) {
    this.loggedInUserId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.isEmail = this.activatedRoute.snapshot.paramMap.get("isEmail")
      ? this.activatedRoute.snapshot.paramMap.get("isEmail")
      : 0;
    this.isPracticePhy =
      this.activatedRoute.snapshot.paramMap.get("isPracticePhy");
    this.physicianId = this.activatedRoute.snapshot.paramMap.get("physicianId")
      ? this.activatedRoute.snapshot.paramMap.get("physicianId")
      : 0;
    this.requestId = this.activatedRoute.snapshot.paramMap.get("requestId")
      ? this.activatedRoute.snapshot.paramMap.get("requestId")
      : 0;
    this.tempId = this.activatedRoute.snapshot.paramMap.get("tempId")
      ? this.activatedRoute.snapshot.paramMap.get("tempId")
      : 0;
    this.userOptionId = this.activatedRoute.snapshot.paramMap.get(
      "userOptionId"
    )
      ? this.activatedRoute.snapshot.paramMap.get("userOptionId")
      : 0;
    this.emailLogId = this.activatedRoute.snapshot.paramMap.get("emailLogId")
      ? this.activatedRoute.snapshot.paramMap.get("emailLogId")
      : 0;

    this.hippaJson.healthcare_provider = this.userService.getUS_FromatName(
      localStorage.getItem("providerName")
    );
    this.hippaJson.authorize = this.userService.getUS_FromatName(
      localStorage.getItem("providerName")
    );
    this.hippaJson.healthcare_provider_email =
      localStorage.getItem("providerEmail");
    this.hippaJson.healthcare_provider_npi = localStorage.getItem(
      "providerPhysicianNipNo"
    );
    this.hippaJson.patient_name = this.userService.getUS_FromatName(
      localStorage.getItem("loggedInUserName")
    );
    this.hippaJson.authorize_to = this.userService.getUS_FromatName(
      localStorage.getItem("loggedInUserName")
    );
    this.hippaJson.street_address = localStorage.getItem(
      "providerOfficeAddress"
    );
    this.hippaJson.street_address = localStorage.getItem(
      "providerOfficeAddress"
    );
    this.hippaJson.patient_signature_date = this.currentDate;
    this.hippaJson.effective_date = this.currentDate;
    this.hippaJson.effective_from = this.currentDate;
    this.hippaJson.effective_to = this.currentDate;

    this.hippaJson.city_zip =
      localStorage.getItem("providerCity") +
      ", " +
      localStorage.getItem("PhysicianStateName") +
      ", " +
      localStorage.getItem("providerZipCode");

    this.hippaJson.providerCity = localStorage.getItem("providerCity");
    this.hippaJson.providerPhone = localStorage.getItem("providerPhone");
    this.hippaJson.providerPhysicianNipNo = localStorage.getItem(
      "providerPhysicianNipNo"
    );

    this.hippaJson.healthcare_provider = localStorage.getItem(
      "selectedPhysicianName"
    );
    this.hippaJson.PhysicianOfficeAddress = localStorage.getItem(
      "selectedPhysicianOfficeAddress"
    );
    this.hippaJson.physicianNPI = localStorage.getItem("selectedPhysicianNPI");
    this.hippaJson.physicianEmail = localStorage.getItem(
      "selectedPhysicianName"
    );
    this.hippaJson.physicianCity = localStorage.getItem(
      "selectedPhysicianCity"
    );
    this.hippaJson.physicianState = localStorage.getItem(
      "selectedPhysicianState"
    );
    this.hippaJson.loginUserType = localStorage.getItem("loggedInUserRoleId");

    console.log("login id:", this.hippaJson.loginUserType);
    console.log("phy nm:", this.hippaJson.healthcare_provider);
    //this.fnFindSignature(this.loggedInUserId);
    //this.findPhysicianDetails(this.loggedInUserId);
    this.fnFindDemographic(this.loggedInUserId);
  }

  ngOnInit() {
    this.patientId = this.activatedRoute.snapshot.paramMap.get("patientId");
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");

    this.hippaJson.checkbox_01 = false;
    this.hippaJson.checkbox_02 = false;
    this.hippaJson.checkbox_03 = false;
    this.hippaJson.checkbox_04 = false;
    this.hippaJson.checkbox_05 = false;
    this.hippaJson.checkbox_06 = false;
    this.hippaJson.checkbox_07 = false;
    this.hippaJson.checkbox_08 = false;
    this.hippaJson.patient_name = "";
    this.hippaJson.physicianName = "";
    this.hippaJson.physicianAddress = "";
    this.hippaJson.patient_dob = "";
    this.hippaJson.patient_ssn = "";
    this.hippaJson.purposes = "";
    this.hippaJson.start_date = "";
    this.findUserDetails(this.patientId);
    //this.hippaJson.healthcare_provider ='';
    (this.hippaJson.physicianName = localStorage.getItem(
      "selectedPhysicianName"
    )),
    this.hippaJson.healthcare_provider_npi = localStorage.getItem('selectedPhysicianNPI');
      (this.hippaJson.street_address = this.hippaJson.PhysicianOfficeAddress);
    this.hippaJson.city_zip =
      this.hippaJson.physicianCity + "," + this.hippaJson.physicianState;

    this.hippaJson.patient_signature = "";
  }

  findUserDetails(patientId) {
    const finalData = {
      company_slug: null,
      id: patientId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            //console.log('DOB=>', apiResponse.commandResult.data.userInfo.DOB)
            this.hippaJson.patient_name =
              apiResponse.commandResult.data.userInfo.LastName +
              ", " +
              apiResponse.commandResult.data.userInfo.FirstName;
            this.hippaJson.patient_dob =
              apiResponse.commandResult.data.userInfo.DOB;
            //this.hippaJson.patient_ssn = apiResponse.commandResult.data.userInfo.patient_ssn;
          }
          //console.log("patient name:",this.hippaJson.patient_name);
          //console.log("patient firstNAme:",apiResponse.commandResult.data.userInfo.FirstName)
        },
        (err) => {}
      );
  }

  async onSubmit() {
    // if (confirm("PLEASE CONFIRM: to print generated  State HIPAA Form."))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.onSubmit
    );
   if(alertStatus) {
      const finalData = {
        selectedDocUrl: this.selectedDocUrl,
        fileDesTxt: this.fileDesTxt,
        loggedInUserId: this.loggedInUserId,
        userId: this.patientId,
        loginUserType: this.loginUserType,
        patient_name: this.hippaJson.patient_name,
        patient_ssn: this.hippaJson.patient_ssn,
        patient_dob: this.hippaJson.patient_dob,
        start_date: this.hippaJson.start_date,
        end_date: this.hippaJson.end_date,
        other_issue: this.hippaJson.other_issue,
        expiry_date: this.hippaJson.expiry_date,
        physicianNPI: this.hippaJson.PhysicianNipNo,
        healthcare_provider: this.hippaJson.physicianName,
        PhysicianOfficeAddress: this.hippaJson.PhysicianOfficeAddress,
        physicianPhone: this.hippaJson.PhysicianOfficePhone,
        physicianState: this.selectedStateCode,
        assignedDelegateId: this.assignedDelegateId,
        isDelegated: this.isDelegated,
        selectedDelegateId: this.selectedDelegateId,
        selectedEmail: this.selectedPhysician.altEmail,
        selectedPhysician: this.selectedPhysician,
        social_security_no: this.hippaJson.social_security_no,
        dob: this.hippaJson.dob,
        // healthcare_provider: this.hippaJson.healthcare_provider,
        street_address: this.hippaJson.PhysicianOfficeAddress,

        checkbox_01: this.hippaJson.checkbox_01,
        checkbox_02: this.hippaJson.checkbox_02,
        checkbox_03: this.hippaJson.checkbox_03,
        checkbox_04: this.hippaJson.checkbox_04,
        checkbox_05: this.hippaJson.checkbox_05,
        checkbox_06: this.hippaJson.checkbox_06,
        checkbox_07: this.hippaJson.checkbox_07,
        checkbox_08: this.hippaJson.checkbox_08,
      };
      this.showLoader = true;
      this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.generateHipaaKS,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            if (this.hippaJson.loggedInUserRoleId == 1) {
              this.router.navigate([
                "/doctor/" +
                  "/demographics/" +
                  this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
                  "/1/",
              ]);
            } else if (this.hippaJson.loggedInUserRoleId == 4) {
              this.router.navigate([
                "/doctor/" +
                  "/demographics/" +
                  this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
                  "/1/",
              ]);
            } else {
              if (window.open(apiResponse.commandResult.data, "_blank")) {
                this.router.navigate([
                  "/doctor/" +
                    "/ushf/" +
                    this.activatedRoute.snapshot.paramMap.get(
                      "loggedInUserId"
                    ) +
                    "/" +
                    this.activatedRoute.snapshot.paramMap.get("patientId") +
                    "/" +
                    this.activatedRoute.snapshot.paramMap.get("demographicId"),
                ]);
              }
            }
            this.showLoader= false;
          },
          (err) => {}
        );
    }
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Click ‘OK’ to confirm returning to the ‘Home Page’."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate([
        "/doctor/" +
          "/dashboard/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    }
  }

  async goBack() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the USHF Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.goBack
    );
    if (alertStatus) {
      this.router.navigate([
        "/doctor/" +
          "/ushf/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.activatedRoute.snapshot.paramMap.get("patientId") +
          "/" +
          this.activatedRoute.snapshot.paramMap.get("demographicId"),
      ]);
    }
  }

  closePdfModal() {
    this.showPdfModal = false;
    this.hippaJson.patient_signature = "";
    this.hippaJson.guardian_signature = "";
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set("minWidth", 1); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
  }

  fnFindDemographic(user_id) {
    const finalData = {
      id: user_id,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_findUserDemographicGeneralApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (response: any) => {
          // console.log('response=>', response);
          this.hippaJson.dob = response.commandResult.data.userInfo.form1DOB;
          this.hippaJson.patient_city =
            response.commandResult.data.userInfo.form1City;
          this.hippaJson.patient_zip =
            response.commandResult.data.userInfo.form1ZIP;
          this.hippaJson.patient_state =
            response.commandResult.data.userInfo.form1State;
          this.hippaJson.patient_address =
            response.commandResult.data.userInfo.form1ResidenceNumber +
            ", " +
            response.commandResult.data.userInfo.form1StreetName +
            ", " +
            response.commandResult.data.userInfo.form1PrimaryPhysicianCity;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  autoFormatSSN() {
    // alert('Amit');
    var val = this.hippaJson.social_security_no.replace(/\D/g, "");
    val = val.replace(/^(\d{3})/, "$1-");
    val = val.replace(/-(\d{2})/, "-$1-");
    val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
    this.hippaJson.social_security_no = val;
  }

  openKSBlankpdf() {
    window.open("https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/Kansas-HIPAA-Medical-Release-Form.pdf", "_blank")
  }
}

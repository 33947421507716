import { from } from 'rxjs';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';

import { environment } from './../../../environments/environment';
import { ApiHelper } from './../../_helpers/api.helper';
import { CommonHelper } from './../../_helpers/common.helper';
import { ConstantHelper } from './../../_helpers/constant.helper';
import { AppConfig } from './../../../app/app.config';
import { UploadService } from '../../_services/upload.service';
import { SweetAlertHelper } from 'src/app/_helpers/sweet.alert.helper';
import { Message } from 'src/app/_locale/message';
import { async } from '@angular/core/testing';

@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.scss'],
  providers: [AppConfig, CommonHelper],
})
export class AddDocumentComponent implements OnInit {
  @ViewChild('document', { static: false }) document: ElementRef;
  childRoute: string;
  loggedInUserId: string;
  resultJsonObj: any = {};
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  addAdminObj: any = {};

  docAdded = false;
  selectedFile: File = null;
  fileName: string;

  showLoader = false;
  message: string;
  mediaType: string;
  imagePath: string;
  imgURL: any;
  loading: any;
  mediaUrl: any;
  toastr: any;
  message1 = Message;

  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    public sweetAlertHelper: SweetAlertHelper,
    private uploadService: UploadService,
    private renderer: Renderer2
  ) {
    // console.log(activatedRoute.snapshot.url); // array of states
    localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.addAdminObj.confirmationStatus = '';
    this.addAdminObj.uploadAllowed = '';
    this.addAdminObj.docMassage = '';
  }

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
  }

  onFileSelected(event) {
    if (this.document.nativeElement.value !== '') {
      this.selectedFile = event.target.files.item(0) as File;
  } else {
      // If no file is selected or the selection is canceled
      this.selectedFile = null; // Reset selectedFile
  }
  }

  async onSubmit() {
    this.showLoader = true;

    if (this.selectedFile) {
      const fSize = Math.round(this.selectedFile.size / 1024);

      if (!this.validateFile(this.selectedFile.name)) {
        // File format not allowed
        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message1.alertMessages.modifyDocComp.onSubmit
        );
        this.showLoader = false; // Hide loader when the alert is displayed
        return false;
      } else if (fSize > 61440) {
        // File size is too big
        this.showLoader = false;
        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message1.alertMessages.addDocComp.onSubmit1
        );
        // Hide loader when the alert is displayed
      } else {
        // Confirm upload
        this.showLoader = false;
        const alertStatus = await this.sweetAlertHelper.confirmPopUp(
          this.message1.confirmTitle,
          this.message1.confirmMessages.adminUploaddocComp.uploadFile
        );

        if (alertStatus) {
          // User confirmed upload
          this.uploadService
          .saveAdminDoc(
            this.loggedInUserId,
            ConstantHelper.COMPANY_SLUG,
            this.addAdminObj.docName,
            this.addAdminObj.docDescription,
            this.addAdminObj.confirmationStatus,
            this.selectedFile,
            ConstantHelper.DOC_TYPE_ADMIN_UPLOADED,
            localStorage.getItem('loggedInUserComapnyId'),
            this.addAdminObj.uploadAllowed,
            this.addAdminObj.docMassage
          )
            .subscribe(
              async (data: any) => {
                this.showLoader = false; // Hide loader after successful upload
                if (data !== undefined) {
                  if (data.commandResult.success == 1) {
                    // Successful upload
                    const alertStatus = await this.sweetAlertHelper.alertPopUp(
                      this.message1.alertMessages.modifyDocComp.onSubmit2
                    );
                    if (alertStatus) {
                      this.docAdded = true;
                    }
                    setTimeout(() => {
                      this.router.navigate([
                        '/' + this.childRoute + '/documents/' + this.loggedInUserId,
                      ]);
                    }, 2000);
                  }
                }
              },
              (error) => {
                // Error handling code
              }
            );
        } else {
          this.showLoader = false; // Hide loader if user cancels the upload
        }
      }
    } else {
      // No document selected
      this.showLoader = false;
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message1.alertMessages.modifyDocComp.onSubmit3
      );
    }
  }

  async goToDocuments() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure you want to return to the document list?"
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message1.confirmTitle,
    //   this.message1.confirmMessages.addDocComp.goToDocuments
    // );
    // if (alertStatus) {
      this.router.navigate([
        '/' + this.childRoute + '/documents/' + this.loggedInUserId,
      ]);
    // }
  }

  base64MimeType(encoded) {
    let result = null;

    if (typeof encoded !== 'string') {
      return result;
    }

    const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

    if (mime && mime.length) {
      result = mime[1];
    }
    const splits = result.split('/');

    if (
      splits[1].toLowerCase() == 'png' ||
      splits[1].toLowerCase() == 'jpg' ||
      splits[1].toLowerCase() == 'jpeg' ||
      splits[1].toLowerCase() == 'pdf'
    ) {
      return true;
    } else {
      return false;
    }
  }

  validateFile(name: String) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (
      ext.toLowerCase() == 'png' ||
      ext.toLowerCase() == 'jpg' ||
      ext.toLowerCase() == 'jpeg' ||
      ext.toLowerCase() == 'pdf'
    ) {
      return true;
    } else {
      return false;
    }
  }
}

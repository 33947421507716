import { Component, OnInit } from "@angular/core";
// import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

@Component({
  selector: "app-add-physician-user",
  templateUrl: "./add-physician-user.component.html",
  styleUrls: ["./add-physician-user.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class AddPhysicianUserComponent implements OnInit {
  childRoute: string;
  loggedInUserId: string;
  loggedInUserName: string;

  resultJsonObj: any = {};
  addAdminObj: any = {};
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  existingEmail: boolean = false;
  existingMobile: boolean = false;
  userAdded: boolean = false;
  ahsHippaPdf: any;
  ahsDisclaimerPdf: any;
  userRoleVal: any = 0;
  message = Message;
  loggedInUserEmail: string;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer
  ) {
    // console.log(activatedRoute.snapshot.url); // array of states
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.userRoleVal = this.activatedRoute.snapshot.paramMap.get("userRoleVal");
  }

  ngOnInit() {
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.loggedInUserName = localStorage.getItem("loggedInUserName") ? localStorage.getItem("loggedInUserName") : "Admin";
    this.loggedInUserEmail = localStorage.getItem("loggedInUserEmail") ? localStorage.getItem("loggedInUserEmail") : "";
  }

  async onSubmit() {
    // if (confirm("PLEASE CONFIRM: Are you sure?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.addAdminUserComp.onSubmit
    );
    if (alertStatus) {
      let httpHeaders = new HttpHeaders({
        "Content-Type": "application/json",
      });
      let options = {
        headers: httpHeaders,
      };

      const finalData = {
        company_slug: ConstantHelper.COMPANY_SLUG,
        fullName: this.addAdminObj.fullName,
        mobile: this.addAdminObj.mobile,
        email: this.addAdminObj.email.toLowerCase(),
        loggedInUserId: this.loggedInUserId,
        loggedInUserName: this.loggedInUserName,
        senderEmail: this.loggedInUserEmail
      };

      // console.log(finalData);

      return this.http
        .post(
          this.appConfig.getLambdaUrl04() + ApiHelper.name_invitePhysicainApi,
          finalData,
          options
        )
        .subscribe(
          (apiResponse) => {
            this.resultJsonObj = apiResponse;
            // console.log(this.resultJsonObj);
            if (this.resultJsonObj.commandResult.status == -1) {
              this.existingMobile = true;
              this.existingEmail = false;
            } else if (this.resultJsonObj.commandResult.status == -2) {
              this.existingEmail = true;
              this.existingMobile = false;
            } else {
              this.existingMobile = false;
              this.existingEmail = false;
              this.userAdded = true;

              setTimeout(() => {
                if (this.userRoleVal > 0) {
                  this.router.navigate([
                    "/" +
                      this.childRoute +
                      "/users/" +
                      this.loggedInUserId +
                      "/" +
                      this.userRoleVal,
                  ]);
                } else {
                  this.router.navigate([
                    "/" + this.childRoute + "/users/" + this.loggedInUserId,
                  ]);
                }
              }, 2000);
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  async goToAdmins() {
    // if (confirm('PLEASE CONFIRM: Are you sure, you want to goto Administrative access list?'))
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.addPhysicianUsercomp.goToAdmins
    // );
    // if (alertStatus) {
      if (this.userRoleVal > 0) {
        this.router.navigate([
          "/" +
            this.childRoute +
            "/users/" +
            this.loggedInUserId +
            "/" +
            this.userRoleVal,
        ]);
      } else {
        this.router.navigate([
          "/" + this.childRoute + "/users/" + this.loggedInUserId,
        ]);
      }
    // }
  }

  validateNumber(event) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        excludedKeys.includes(keyCode)
      )
    ) {
      event.preventDefault();
    }
  }
}

import { Injectable } from '@angular/core';
@Injectable()
export class CommonHelper {

  constructor() { }

  calculateAge(selectedYear, selectedMonth, selectedDay) {
    const dob = new Date(selectedYear, selectedMonth - 1, selectedDay);
    const diffMS = Date.now() - dob.getTime();
    const ageDt = new Date(diffMS);
    const ageCalculated = Math.abs(ageDt.getUTCFullYear() - 1970);
    if (ageCalculated > 0) {
      return ageCalculated;
    } else {
      return 1;

    }
  }

  cleanEmail(str) {
    return str.replace(/[^a-zA-Z0-9.@_ ]/g, '');
  }


  maskEmail(email) {
    let maskedEmail = '';
    if (email.length > 0) {
      maskedEmail = email.substring(0, 4) + '****@***.***';
    }
    return maskedEmail;
  }

  capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
}

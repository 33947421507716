import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { from } from "rxjs";
import { DatePipe } from "@angular/common";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { SignaturePad } from "angular2-signaturepad";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { UserService } from "./../../_services/user.service";
import * as moment from "moment";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

@Component({
  selector: "app-oh",
  templateUrl: "./oh.component.html",
  styleUrls: ["./oh.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class OhComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  hippaJson: any = {};
  loggedInUserId: string;
  userSignatures: any = [];
  userSignatureSelected = false;
  userChoosedSignature = "";
  isEmail: any;
  isPracticePhy: any;
  physicianId: any;
  requestId: any;
  tempId: any;
  userOptionId: any;
  emailLogId: any;
  patientId: string;
  fileDesTxt: any = '';
  selectedDelegateId: any;
  assignedDelegateId: any;
  isDelegated: any = 0;
  selectedDocUrl: any;
  selectedPhysician: any = {};
  docInfo: any = {};
  selectedStateCode: any;
  showLoader = false;
  childRoute: string;
  patient_name: any;
  physicianName: any;
  PhysicianOfficeAddress: any;  
  patient_city:any;
  authorize_to:any;


  userParentSignatureSelected = false;
  userParentChoosedSignature = "";

  isForParentSign = false;

  userFullSignature = "";
  userInitialSignature = "";
  userFullSignatureExit = false;
  userInitialSignatureExis = false;
  showPdfModal = false;
  signatureType: any;
  ssnPattern = "^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$";
  resultJsonObj: any = {};
  clean: string = "0";
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  @ViewChild("canvasArea", { static: true }) canvasArea: ElementRef;
  canvasElm: any;
  public signaturePadOptions: Object = {
    minWidth: 1,
    canvasWidth:
      window.innerWidth < 1024 ? window.innerWidth : window.innerWidth / 2,
    canvasHeight: 450,
  };

  currentDate = new Date();
  dateFormat = "MM-dd-yyyy";
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public userService: UserService
  ) {
    this.loggedInUserId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.isEmail = this.activatedRoute.snapshot.paramMap.get("isEmail")
      ? this.activatedRoute.snapshot.paramMap.get("isEmail")
      : 0;
    this.isPracticePhy =
      this.activatedRoute.snapshot.paramMap.get("isPracticePhy");
    this.physicianId = this.activatedRoute.snapshot.paramMap.get("physicianId")
      ? this.activatedRoute.snapshot.paramMap.get("physicianId")
      : 0;
    this.requestId = this.activatedRoute.snapshot.paramMap.get("requestId")
      ? this.activatedRoute.snapshot.paramMap.get("requestId")
      : 0;
    this.tempId = this.activatedRoute.snapshot.paramMap.get("tempId")
      ? this.activatedRoute.snapshot.paramMap.get("tempId")
      : 0;
    this.userOptionId = this.activatedRoute.snapshot.paramMap.get(
      "userOptionId"
    )
      ? this.activatedRoute.snapshot.paramMap.get("userOptionId")
      : 0;
    this.emailLogId = this.activatedRoute.snapshot.paramMap.get("emailLogId")
      ? this.activatedRoute.snapshot.paramMap.get("emailLogId")
      : 0;

    this.hippaJson.healthcare_provider = this.userService.getUS_FromatName(
      localStorage.getItem("providerName")
    );
    this.hippaJson.authorize = this.userService.getUS_FromatName(
      localStorage.getItem("providerName")
    );
    this.hippaJson.healthcare_provider_email =
      localStorage.getItem("providerEmail");
    this.hippaJson.healthcare_provider_npi = localStorage.getItem(
      "providerPhysicianNipNo"
    );
    this.hippaJson.patient_name = this.userService.getUS_FromatName(
      localStorage.getItem("loggedInUserName")
    );
    this.hippaJson.authorize_to = this.userService.getUS_FromatName(
      localStorage.getItem("loggedInUserName")
    );

    this.hippaJson.patient_signature_date = this.currentDate;
    this.hippaJson.effective_date = this.currentDate;
    this.hippaJson.effective_from = this.currentDate;
    this.hippaJson.effective_to = this.currentDate;

    this.fnFindDemographic(this.patientId);
    this.findUserDetails(this.patientId);
    this.hippaJson.healthcare_provider = localStorage.getItem('selectedPhysicianName');
    this.hippaJson.PhysicianOfficeAddress = localStorage.getItem('selectedPhysicianOfficeAddress');
    this.hippaJson.physicianNPI = localStorage.getItem('selectedPhysicianNPI');
    this.hippaJson.physicianEmail = localStorage.getItem('selectedPhysicianEmail');    
    this.hippaJson.physicianPhone=localStorage.getItem('Mobile');
  }

  ngOnInit() {
    this.patientId = this.activatedRoute.snapshot.paramMap.get('patientId');
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get('loggedInUserId');

    this.hippaJson.check_effective_a = false;
    this.hippaJson.check_effective_b = false;
    this.hippaJson.check_extent_a = false;
    this.hippaJson.check_extent_b = false;
    this.hippaJson.check_extent_b_mental = false;
    this.hippaJson.check_extent_b_hiv = false;
    this.hippaJson.check_extent_b_alcohol = false;
    this.hippaJson.check_extent_b_other = false;
    //this.hippaJson.patient_signature = '';
    this.findUserDetails(this.patientId);    
    this.hippaJson.authorize=localStorage.getItem('selectedPhysicianName'),
    this.hippaJson.person_address = this.hippaJson.PhysicianOfficeAddress;
    this.hippaJson.physicianCity_Zip = this.hippaJson.physicianCity + "," + this.hippaJson.physicianState;
    this.hippaJson.healthcare_provider_npi = localStorage.getItem('selectedPhysicianNPI');
  }

  // fnFindSignature(user_id) {
  //   const reqData = {
  //     user_id: user_id,
  //   };
  //   return this.http
  //     .post(
  //       this.appConfig.getPhpUrl() + ApiHelper.php_findUserSignatureApi,
  //       reqData,
  //       this.httpOptions
  //     )
  //     .subscribe(
  //       (response: any) => {
  //         // console.log(response);
  //         if (response.commandResult.success == 1) {
  //           this.userSignatures = response.commandResult.data.userSignatures;
  //           this.userFullSignature =
  //             response.commandResult.data.userSignatures.full_signature_url;
  //           this.userInitialSignature =
  //             response.commandResult.data.userSignatures.initial_signature_url;

  //           if (response.commandResult.data.userSignatures.full_signature_url) {
  //             this.userFullSignatureExit = true;
  //           }

  //           if (
  //             response.commandResult.data.userSignatures.initial_signature_url
  //           ) {
  //             this.userInitialSignatureExis = true;
  //           }
  //         } else {
  //         }

  //         // console.log(this.userSignatures);
  //       },
  //       (err) => {}
  //     );
  // }

  findUserDetails(patientId) {
    const finalData = {
      company_slug: null,
      id: patientId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            this.hippaJson.authorize_to = apiResponse.commandResult.data.userInfo.LastName + ', ' + apiResponse.commandResult.data.userInfo.FirstName;
            this.hippaJson.patient_name = apiResponse.commandResult.data.userInfo.LastName + ', ' + apiResponse.commandResult.data.userInfo.FirstName;
            this.hippaJson.dob = apiResponse.commandResult.data.userInfo.DOB;
            this.hippaJson.patient_phone=apiResponse.commandResult.data.userInfo.Mobile;
            this.hippaJson.patient_email=apiResponse.commandResult.data.userInfo.Email;
           // console.log("patient email ",this.hippaJson.patient_email);
            //console.log("patient ph: ", this.hippaJson.tele_no);
            }
        },
        (err) => {
          // console.log(err);
        }
      );
  }


  async onSubmit() {
    // if (confirm('PLEASE CONFIRM:   By clicking ‘OK’:The information you have entered will be used to electronically generate a completed State HIPAA Form.'))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.onSubmit
    );
   if(alertStatus) {
      const finalData = {
        selectedDocUrl: this.selectedDocUrl,
        fileDesTxt: this.fileDesTxt,
        loggedInUserId: this.loggedInUserId,
        userId: this.patientId,
        patient_name: this.hippaJson.patient_name,        
        dob: this.hippaJson.dob,  
        authorize: this.hippaJson.authorize,
        heathcare_provider:this.hippaJson.authorize,
        authorize_to: this.hippaJson.authorize_to,
        healthcare_provider:this.hippaJson.authorize,       
        physicianState: this.selectedStateCode,
        assignedDelegateId: this.assignedDelegateId,
        isDelegated: this.isDelegated,
        selectedDelegateId: this.selectedDelegateId,
        selectedEmail: this.selectedPhysician.altEmail,
        selectedPhysician: this.selectedPhysician.physicianName,
        physicianPhone: this.hippaJson.physicianPhone,   
        healthcare_provider_email: this.hippaJson.healthcare_provider_email,
        healthcare_provider_npi: this.hippaJson.healthcare_provider_npi,         
        effective_from: moment(new Date(this.hippaJson.effective_from.toString())).format('MM-DD-YYYY'),
        effective_to: moment(new Date(this.hippaJson.effective_to.toString())).format('MM-DD-YYYY'),
        check_extent_b_other_text: this.hippaJson.check_extent_b_other_text,
        effective_date: moment(new Date(this.hippaJson.effective_date.toString())).format('MM-DD-YYYY'),
       
        //patient_signature_date: moment(new Date(this.hippaJson.patient_signature_date.toString())).format('MM-DD-YYYY'),
        //patient_signature: this.userChoosedSignature,
        check_effective_a: this.hippaJson.check_effective_a,
        check_effective_b: this.hippaJson.check_effective_b,
        check_extent_a: this.hippaJson.check_extent_a,
        check_extent_b: this.hippaJson.check_extent_b,
        check_extent_b_mental: this.hippaJson.check_extent_b_mental,
        check_extent_b_hiv: this.hippaJson.check_extent_b_hiv,
        check_extent_b_alcohol: this.hippaJson.check_extent_b_alcohol,
        check_extent_b_other: this.hippaJson.check_extent_b_other,
        isEmail: this.isEmail,
        requestId: this.requestId
      };
      // console.log(reqData);
      // return;
      this.showLoader= true;
      return this.http
        .post(this.appConfig.getLambdaUrl06() + ApiHelper.generateHipaaOH,
          finalData,
          this.httpOptions)
        .subscribe(
          (apiResponse: any) => {
            if (this.hippaJson.loggedInUserRoleId == 1) {
              this.router.navigate([
                '/doctor/' + '/demographics/' +
                this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
                '/1/'
              ]);
            } else if (this.hippaJson.loggedInUserRoleId == 4) {
              this.router.navigate([
                '/doctor/' + '/demographics/' +
                this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
                '/1/'
              ]);
            } else {
              if (window.open(apiResponse.commandResult.data, '_blank')) {
                this.router.navigate([
                  '/doctor/' + '/ushf/' +
                  this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/' + this.activatedRoute.snapshot.paramMap.get('patientId') + '/' + this.activatedRoute.snapshot.paramMap.get('demographicId')
  
                ]);
              }
            }
            this.showLoader= false;
          },
          (err) => {
          }
        );
    }
  }


  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Click ‘OK’ to confirm returning to the ‘Home Page’."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(['/doctor/' +
      '/dashboard/' +
      this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),
    ]);
    }
  }

  async goBack() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the USHF Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.goBack
    );
    if (alertStatus) {
      this.router.navigate([
        '/doctor/' + '/ushf/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/' + this.activatedRoute.snapshot.paramMap.get('patientId') + '/' + this.activatedRoute.snapshot.paramMap.get('demographicId')

      ]);
    }
  }

  closePdfModal() {
    this.showPdfModal = false;
    this.hippaJson.patient_signature = "";
    this.hippaJson.guardian_signature = "";
  }

  // openSignatureCanvas(selectedValue, isParentSign) {
  //   this.isForParentSign = isParentSign;

  //   // console.log(selectedValue);
  //   this.signatureType = selectedValue;
  //   if (
  //     selectedValue == "AFS" ||
  //     selectedValue == "AIS" ||
  //     selectedValue == "ANPS"
  //   ) {
  //     this.showPdfModal = true;
  //   }

  //   if (selectedValue == "FS" || selectedValue == "IS") {
  //     this.userSignatureSelected = true;
  //     if (selectedValue == "FS") {
  //       this.userChoosedSignature = this.userFullSignature;
  //     }
  //     if (selectedValue == "IS") {
  //       this.userChoosedSignature = this.userInitialSignature;
  //     }
  //   }
  // }

  ngAfterViewInit() {
    // this.signaturePad is now available
    //this.signaturePad.set("minWidth", 1); // set szimek/signature_pad options at runtime
    //this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
  }

  // saveCanvas() {
  //   if (
  //     confirm(
  //       "PLEASE CONFIRM: This will replace your existing signature. Do you wish to change?"
  //     )
  //   ) {
  //     const reqData = {
  //       loggedInUserId: this.loggedInUserId,
  //       signatureBase64: this.signaturePad.toDataURL(),
  //       signatureType: this.signatureType,
  //     };
  //     return this.http
  //       .post(
  //         this.appConfig.getLambdaUrl05() +
  //           ApiHelper.name_saveSignatureImageApi,
  //         reqData,
  //         this.httpOptions
  //       )
  //       .subscribe(
  //         (apiResponse: any) => {
  //           if (apiResponse.commandResult.status == 1) {
  //             this.userSignatureSelected = true;
  //             this.userChoosedSignature =
  //               apiResponse.commandResult.data.fileInfo.signatureUrl;
  //             // console.log(this.userChoosedSignature);
  //             this.fnFindSignature(this.loggedInUserId);
  //             this.showPdfModal = false;
  //             // this.hippaJson.patient_signature = '';
  //           }
  //         },
  //         (err) => {
  //           // console.log(err);
  //         }
  //       );
  //   }
  // }

  // saveParentCanvas(signFor) {
  //   const reqData = {
  //     loggedInUserId: this.loggedInUserId,
  //     signatureBase64: this.signaturePad.toDataURL(),
  //     signatureType: this.signatureType,
  //     signFor: signFor,
  //   };
  //   return this.http
  //     .post(
  //       this.appConfig.getLambdaUrl05() +
  //         ApiHelper.name_saveParentSignatureImageApi,
  //       reqData,
  //       this.httpOptions
  //     )
  //     .subscribe(
  //       (apiResponse: any) => {
  //         if (apiResponse.commandResult.status == 1) {
  //           this.userParentSignatureSelected = true;
  //           this.userParentChoosedSignature =
  //             apiResponse.commandResult.data.fileInfo.signatureUrl;
  //           this.showPdfModal = false;
  //           // this.hippaJson.patient_signature = '';
  //         }
  //       },
  //       (err) => {
  //         // console.log(err);
  //       }
  //     );
  // }

  clearCanvas() {
    if (
      confirm("PLEASE CONFIRM: Are you sure you want to clear you signature.")
    ) {
      this.signaturePad.clear();
    }
  }

  drawStart() {}

  fnFindDemographic(patientId) {
    const finalData = {
      id: patientId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_findUserDemographicGeneralApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (response: any) => {
          // console.log('response=>', response);
          this.hippaJson.dob = response.commandResult.data.userInfo.form1DOB;
          this.hippaJson.patient_city =
            response.commandResult.data.userInfo.form1City;
          this.hippaJson.patient_zip =
            response.commandResult.data.userInfo.form1ZIP;
          this.hippaJson.patient_state =
            response.commandResult.data.userInfo.form1State;
          this.hippaJson.patient_address =
            response.commandResult.data.userInfo.form1ResidenceNumber +
            ", " +
            response.commandResult.data.userInfo.form1StreetName +
            ", " +
            response.commandResult.data.userInfo.form1PrimaryPhysicianCity;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  autoFormatSSN() {
    // alert('Amit');
    var val = this.hippaJson.social_security_no.replace(/\D/g, "");
    val = val.replace(/^(\d{3})/, "$1-");
    val = val.replace(/-(\d{2})/, "-$1-");
    val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
    this.hippaJson.social_security_no = val;
  }

  openOHBlankpdf() {
    window.open("https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/Ohio-HIPAA-Medical-Release-Form.pdf", "_blank")
  }
}

import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { async } from "@angular/core/testing";
@Component({
  selector: "app-practice-specialities",
  templateUrl: "./practice-specialities.component.html",
  styleUrls: ["./practice-specialities.component.scss"],
})
export class PracticeSpecialitiesComponent implements OnInit {
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  companySlug: string = ConstantHelper.COMPANY_SLUG;

  childRoute: string;
  loggedInUserId: string;

  specialities: any = [];
  showAddSpecialityModal = false;
  showEditSpecialityModal = false;

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  newSpeciality: string;
  addSpecialityObj: any = {};
  message = Message;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    public sweetAlertHelper: SweetAlertHelper,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.getSpecialitiesByComapnSlug(this.companySlug);
  }

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
  }

  async openAddSpecialityModal() {
    this.newSpeciality = "";
    // if (confirm("PLEASE CONFIRM: Are you sure you want to add specialty."))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.practiceSpecialitiesComp
        .openAddSpecialityModal
    );
    {
      this.showAddSpecialityModal = true;
    }
  }

  closeAddSpecialityModal() {
    this.showAddSpecialityModal = false;
    this.addSpecialityObj.newSpeciality = "";
  }

  async openEditSpecialityModal(id, speciality) {
    // if (confirm("PLEASE CONFIRM: Are you sure you want to edit specialty."))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.prcaticeGroupComp.editSpeciality
    );
    if (alertStatus) {
      this.showEditSpecialityModal = true;
      this.addSpecialityObj.oldSpeciality = speciality;
      this.addSpecialityObj.oldId = id;
    }
  }
  closeEditSpecialityModal() {
    this.showEditSpecialityModal = false;
    this.addSpecialityObj.oldSpeciality = "";
    this.addSpecialityObj.oldId = "";
  }

  async saveSpeciality() {
    if (!this.addSpecialityObj.newSpeciality) {
      // alert("please enter speciality");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.prcaticeGroupComp.addSpeciality
      );
      return;
    }
    // if (
    //   confirm("PLEASE CONFIRM: Are you sure you want to add new specialty ")
    // )
    const isValidInput = /^[a-zA-Z ]+$/.test(
      this.addSpecialityObj.newSpeciality.trim()
    );

    if (!isValidInput) {
      // alert("Invalid characters. Please enter alphabets only.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        "Invalid Input ,only alphabets "
      );
      return;
    }

    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.prcaticeGroupComp.addSpeciality
    );
    if (alertStatus) {
      {
        const finalData = {
          companySlug: this.companySlug,
          speciality: this.addSpecialityObj.newSpeciality,
        };
        return this.http
          .post(
            this.appConfig.getPhpUrl() +
              ApiHelper.php_saveSpecialityByComapnSlugApi,
            finalData,
            this.httpOptions
          )
          .subscribe(
            async (apiResponse: any) => {
              // console.log(apiResponse);
              if (apiResponse.commandResult.success == -1) {
                // alert("Specility already exits, please use another");
                const alertStatus = await this.sweetAlertHelper.alertPopUp(
                  this.message.alertMessages.practiceSpecialitiesComp
                    .saveSpeciality
                );
              } else {
                this.specialities = apiResponse.commandResult.data.specialities;
                this.showAddSpecialityModal = false;
                this.addSpecialityObj.newSpeciality = "";
                // alert("Specility added successfully");
                const alertStatus = await this.sweetAlertHelper.alertPopUp(
                  this.message.alertMessages.practiceSpecialitiesComp
                    .saveSpeciality1
                );
              }
            },
            (err) => {
              // console.log(err);
            }
          );
      }
    }
  }

  async editSpeciality() {
    if (!this.addSpecialityObj.oldSpeciality) {
      // alert("please enter specialty");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.prcaticeGroupComp.addSpeciality
      );

      return;
    }
    // if (
    //   confirm("PLEASE CONFIRM: Are you sure you want to add new specialty ")
    // )

    const isValidInput = /^[a-zA-Z ]+$/.test(
      this.addSpecialityObj.oldSpeciality.trim()
    );

    if (!isValidInput) {
      // alert("Invalid characters. Please enter alphabets only.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        "Invalid Input ,only alphabets "
      );
      return;
    }
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.prcaticeGroupComp.editSpeciality
    );
    if (alertStatus) {
      const finalData = {
        companySlug: this.companySlug,
        speciality: this.addSpecialityObj.oldSpeciality,
        id: this.addSpecialityObj.oldId,
      };

      return this.http
        .post(
          this.appConfig.getPhpUrl() +
            ApiHelper.php_updateSpecialityByComapnSlugApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse: any) => {
            // console.log(apiResponse);
            if (apiResponse.commandResult.success == -1) {
              // alert("Specility already exits, please use another");
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.practiceSpecialitiesComp
                  .saveSpeciality
              );
            } else {
              this.specialities = apiResponse.commandResult.data.specialities;
              this.showEditSpecialityModal = false;
              this.addSpecialityObj.oldSpeciality = "";
              this.addSpecialityObj.oldId = "";
              // alert("Specility updated successfully");
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.practiceSpecialitiesComp
                  .editSpeciality
              );
            }
            if (alertStatus) {
              window.location.reload();
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  goToPracticeGroup() {
    this.router.navigate([
      "/" + this.childRoute + "/practice-groups/" + this.loggedInUserId,
    ]);
  }

  getSpecialitiesByComapnSlug(companySlug) {
    const finalData = {
      companySlug: companySlug,
      all: 1,
    };
    return this.http
      .post(
        this.appConfig.getPhpUrl() +
          ApiHelper.php_getSpecialitiesByComapnSlugApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log(apiResponse);
          this.specialities = apiResponse.commandResult.data.specialities;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async changeStatus(id) {
    // if (confirm("Are you sure you want change status"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.prcaticeGroupComp.ChangeStatus
    );
    if (!alertStatus) {
      return;
    }

    const originalSpecialities = [...this.specialities];
    this.specialities = this.specialities.map((speciality) => {
      if (speciality.id === id) {
        return {
          ...speciality,
          is_active: speciality.is_active === 1 ? 0 : 1,
        };
      }
      return speciality;
    });
    const finalData = {
      companySlug: this.companySlug,
      id: id,
    };

    this.http
      .post(
        this.appConfig.getPhpUrl() +
          ApiHelper.php_changeSpecialityStatusByIdApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          console.log("API Response:", apiResponse);
          console.log("Original Specialities:", originalSpecialities);

          // Ensure only the status of the specific speciality gets updated
          const changedSpecialityFromAPI =
            apiResponse.commandResult.data.specialities.find(
              (s) => s.id === id
            );
          const specialityToUpdate = this.specialities.find((s) => s.id === id);
          if (changedSpecialityFromAPI && specialityToUpdate) {
            specialityToUpdate.is_active = changedSpecialityFromAPI.is_active;
          }

          const alertStatus = await this.sweetAlertHelper.alertPopUp(
            this.message.alertMessages.adminphysicianSchedulingComp
              .channgeStatus
          );
          if (alertStatus) {
            window.location.reload();
          }
        },
        (err) => {
          this.specialities = originalSpecialities;
          console.error("Error changing status:", err);
          this.sweetAlertHelper.alertPopUp(
            "An error occurred. Please try again."
          );
        }
      );
  }
}

import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UploadService } from "../../_services/upload.service";
import { UserService } from "../../_services/user.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-admin-second-opinion",
  templateUrl: "./admin-second-opinion.component.html",
  styleUrls: ["./admin-second-opinion.component.scss"],
})
export class AdminSecondOpinionComponent implements OnInit {
  maxChars = 300;
  fileDesTxt: any = "";

  childRoute: string;
  loggedInUserId: string;
  docInfo: any = {};
  docSelected: any = {};
  companySlug: string = ConstantHelper.COMPANY_SLUG;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  selectedNPI: any;
  selectedPhyName: any;
  showGreen = false;
  company_slug: string = "";
  selected_spacialty_id: any = {};
  selected_sub_specialty_id: any = {};
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  practicingCenterArr: any = [];

  specialityArr: any = [];

  subSpecialtyArr: any = [];
  physiciansArr: any = [];
  selectedPhysician: any = {};
  practicingCenterId = "";
  specialtyId = "";
  subSpecialtyId = "";
  physicianId = "";
  emailTxt = "";
  message = Message;
  constructor(
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    public userService: UserService
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
  }

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.docInfo.physicianName = "";
    this.docInfo.subSpecialites = "";
    this.findAllParticipatingCenters();
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the Physician Home Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
      ]);
    }
  }

  findSpecialtyByCompanySlug(specialitSlug) {
    // console.log(specialitSlug);
    //get_SpecialtyByPractices
    this.company_slug = specialitSlug;
    this.docSelected = {};
    this.physicianId = "";
    this.subSpecialtyId = "";
    this.specialtyId = "";
    const finalData = {
      companySlug: specialitSlug,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.get_SpecialtyByPractices,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          // console.log(apiResponse);
          if (apiResponse.commandResult.data.specialities.length > 0) {
            this.specialityArr = apiResponse.commandResult.data.specialities;
            // console.log('Specialties', this.specialityArr);
            this.docSelected = {};
          } else {
            // alert("No Specialties found!");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findSpecialty
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findSubSpecialtyByCompanySlug(specaltyId) {
    // console.log(specaltyId);
    this.selected_spacialty_id = specaltyId;
    this.docSelected = {};
    this.physicianId = "";
    this.subSpecialtyId = "";
    //get_SpecialtyByPractices
    const finalData = {
      companySlug: this.company_slug,
      specialityId: this.selected_spacialty_id,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl04() + "findSubSpecialitiesByCompany", //ApiHelper.get_SubspecialtyBySpecialtyAndPractice,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          // console.log(apiResponse);
          if (apiResponse.commandResult.data.sub_specialities.length > 0) {
            this.subSpecialtyArr =
              apiResponse.commandResult.data.sub_specialities;
            // console.log('SubSpecialties', this.subSpecialtyArr);
            this.docSelected = {};
          } else {
            // alert("No Sub Specialties found!");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findSubSpeciality
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPhysicianBySubspecialty(subSpecaltyId) {
    // console.log(subSpecaltyId);
    this.selected_sub_specialty_id = subSpecaltyId;
    this.docSelected = {};
    this.physicianId = "";
    const finalData = {
      companySlug: this.company_slug,
      specialityId: this.selected_spacialty_id,
      subSpecialityId: this.selected_sub_specialty_id,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_findParticipatingDoctorsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          // console.log(apiResponse);
          if (apiResponse.commandResult.data.participating_doctors.length > 0) {
            this.physiciansArr = this.fnChangePraticipatingPhysicianNameFormat(
              apiResponse.commandResult.data.participating_doctors
            );
            // console.log('Physicians', this.physiciansArr);
          } else {
            this.physiciansArr = {};
            // alert("No physicians found!");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findcoePhysicians1
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findAllParticipatingCenters() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl04() + ApiHelper.name_findPractices,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          if (apiResponse.commandResult.data.companiesList.length > 0) {
            this.practicingCenterArr =
              apiResponse.commandResult.data.companiesList;
            // console.log('Particiating Center', this.practicingCenterArr);
            this.docSelected = {};
          } else {
            // alert("No Center of Excellence found!");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp
                .findAllParticipatingCenters
            );
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  updatePhysicianInfo(physicianId) {
    // console.log(physicianId)
    this.docSelected = this.physiciansArr.find(
      (e) => e.physician_id == physicianId
    );
    // console.log(this.docSelected);
    this.selectedNPI = this.docSelected.nip_no;
    this.selectedPhyName = this.docSelected.full_name;
  }

  fnChangePraticipatingPhysicianNameFormat(physicians) {
    let formattedNameArray: any = [];
    physicians.forEach((e) => {
      e.full_name = this.userService.getUS_FromatName(e.full_name);
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(this.userService.dynamicSort("full_name"));
  }

  onSubmit() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      companySlug: this.practicingCenterId,
      physicianType: 1,
      physicianId: this.physicianId,
      specialtyId: this.specialtyId,
      subSpecialtyId: this.subSpecialtyId,
      emailTxt: this.emailTxt,
      userOptionId: 5,
    };

    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_docotr2doctorEmail05Api,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          // console.log('apiResponse=>', apiResponse)
          // alert("Request sent successfully");
          const alertStatus = await this.sweetAlertHelper.alertPopUp(
            this.message.alertMessages.adminsecondOpinionComp.onSubmit
          );
          if (alertStatus)
            this.router.navigate([
              "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
            ]);
        },
        (err) => {
          // console.log(err);
        }
      );
  }
}

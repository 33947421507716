import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  HostListener
} from "@angular/core";

import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from "@angular/common/http";

import {AuthService} from "../auth.service";
import {environment} from "./../../../environments/environment";
import {ApiHelper} from "./../../_helpers/api.helper";
import {CommonHelper} from "./../../_helpers/common.helper";
import {ConstantHelper} from "./../../_helpers/constant.helper";
import {AppConfig} from "./../../../app/app.config";

@Component({
  selector: "app-forgotpassword",
  templateUrl: "./forgotpassword.component.html",
  styleUrls: ["./forgotpassword.component.scss"],
  providers: [AppConfig, CommonHelper]
})
export class ForgotpasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  forgotFromSubmitted: boolean = false;
  formErrorMessage: boolean = false;

  showPasswordForm: boolean = true;
  showPasswordMessage: boolean = false;
  showQueErrorMessage: boolean = false;

  // userId: number;
  resultJsonObj: any = {};
  que01: string;
  que02: string;

  Que01Id: string;
  Que02Id: string;
  userId: string;
  answer1: string;
  answer2: string;


  securityQueForm: FormGroup;
  showSecurityQueForm: boolean = false;
  securityQueFormSubmitted: boolean = false;

  securityQueFormMessage: boolean = false;

  constructor(public commonHelper: CommonHelper,
              private appConfig: AppConfig,
              private formBuilder: FormBuilder,
              private router: Router,
              private authService: AuthService,
              private http: HttpClient) {
  }

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      emailOrNumber: ["", [Validators.required]]
    });


    this.securityQueForm = this.formBuilder.group({
      ans1: ["", Validators.required],
      ans2: ["", Validators.required],
    });


  }

  get f() {
    return this.forgotPasswordForm.controls;
  }

  get v() {
    return this.securityQueForm.controls;
  }


  onSubmit() {
    this.forgotFromSubmitted = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json"
    });
    let options = {
      headers: httpHeaders
    };
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      email: this.forgotPasswordForm.value.emailOrNumber
    };

    return this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_checkExistingEmailApi,
        finalData,
        options
      )
      .subscribe(
        apiResponse => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.status == 1) {
            this.showPasswordForm = false;
            this.showSecurityQueForm = true;
            this.que01 = this.resultJsonObj.commandResult.data.userInfo.Que01;
            this.que02 = this.resultJsonObj.commandResult.data.userInfo.Que02;
            this.Que01Id = this.resultJsonObj.commandResult.data.userInfo.Que01Id;
            this.Que02Id = this.resultJsonObj.commandResult.data.userInfo.Que02Id;
            this.answer1 = this.resultJsonObj.commandResult.data.userInfo.Ans01;
            this.answer2 = this.resultJsonObj.commandResult.data.userInfo.Ans02;
            this.userId = this.resultJsonObj.commandResult.data.userInfo.RecordId;
            // console.log(this.showSecurityQueForm)
            // this.showPasswordMessage = true;


            // this.router.navigate(["/reset-password/" + this.userId]);
          } else {
            this.formErrorMessage = true;
          }
        },
        err => {
          // console.log(err);
        }
      );
  }


  sendResetPassLink() {
    this.securityQueFormSubmitted = true;
    if (this.securityQueForm.invalid) {
      return;
    }
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json"
    });
    let options = {
      headers: httpHeaders
    };
    if (this.answer1 == this.securityQueForm.value.ans1 && this.answer2 == this.securityQueForm.value.ans2) {

      const finalData = {
        company_slug: ConstantHelper.COMPANY_SLUG,
        userId: this.userId,
        que01Id: this.Que01Id,
        que02Id: this.Que02Id,
        que01Ans: this.securityQueForm.value.ans1,
        que02Ans: this.securityQueForm.value.ans2,
      };
      return this.http
        .post(
          this.appConfig.getLambdaUrl01() + ApiHelper.name_sendPasswordLinkApi,
          finalData,
          options
        )
        .subscribe(
          apiResponse => {
            this.resultJsonObj = apiResponse;
            // console.log(this.resultJsonObj);
            if (this.resultJsonObj.commandResult.status == 1) {
              this.showSecurityQueForm = false;
              this.showPasswordMessage = true;




            } else {
              this.showQueErrorMessage = true;
            }
          },
          err => {
            // console.log(err);
          }
        );


    } else {
      this.showQueErrorMessage = true;
    }

  }


}

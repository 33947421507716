import { Component, OnInit } from "@angular/core";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";

import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-thumb-mobile",
  templateUrl: "./thumb-mobile.component.html",
  styleUrls: ["./thumb-mobile.component.scss"],
  providers: [AppConfig, CommonHelper]
})
export class ThumbMobileComponent implements OnInit {
  loggedInUserId: string;
  demographicId: string;
  resultJsonObj: any = {};
  hidePdfPopUp: boolean = false;
  showDemographicModal: boolean = false;
  showDemographicModalHeading: string;


  showHippaModal: boolean = false;
  showHippaDocModal: boolean = false;
  showDisclaimerDocModal: boolean = false;
  showDisclaimerModal: boolean = false;

  demographicUrl: SafeResourceUrl;
  hippaUrl: SafeResourceUrl;
  disclaimerUrl: SafeResourceUrl;
  ahsDisclaimerPdf: SafeResourceUrl;
  ahsHippaPdf: SafeResourceUrl;

  showDocThumbnails: boolean = true;


  documentsLength: boolean = false;
  documentsList: any;

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });
  httpOptions = {
    headers: this.httpHeaders
  };

  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get(
      "loggedInUserId"
    );
    this.demographicId = this.activatedRoute.snapshot.paramMap.get(
      "demographicId"
    );

    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_getActiveAdminDocumentsListApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        apiResponse => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList = this.resultJsonObj.commandResult.data.documentList;
            // console.log(this.documentsList);
            // console.log(this.documentsLength);
          } else {
            this.documentsLength = false;
          }
        },
        err => {
          // console.log(err);
        }
      );


  }




  showDemographicForm() {
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json"
    });
    let options = {
      headers: httpHeaders
    };
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      demographicId: this.demographicId
    };
    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_generateDemographicPdfApi,
        finalData,
        options
      )
      .subscribe(
        async apiResponse => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.success == 1) {
            // console.log(this.resultJsonObj.commandResult.data);

            this.demographicUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.resultJsonObj.commandResult.data
            );
            this.showDemographicModalHeading = 'DEMOGRAPHICS SHEET';
            this.showDemographicModal = true;
            // console.log(apiResponse);
          } else {
            //alert("oops! error occured, please try later");
            const alertStatus =  await this.sweetAlertHelper.alertPopUp(
            this.message.alertMessages.allotDocgrpComp.onSubmi1);
          }
        },
        err => {
          // console.log(err);
        }
      );
  }
  showHippaForm() {
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json"
    });
    let options = {
      headers: httpHeaders
    };
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      demographicId: this.demographicId
    };

    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_generateHippaRealeasPdfApi,
        finalData,
        options
      )
      .subscribe(
       async  apiResponse => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.success == 1) {
            this.demographicUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.resultJsonObj.commandResult.data
            );
            this.showDemographicModal = true;
            this.showDemographicModalHeading = 'HIPAA RELEASE FORM';
          } else {
            //alert("oops! error occured, please try later");
            const alertStatus =  await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.allotDocgrpComp.onSubmi1);
          }
        },
        err => {
          // console.log(err);
        }
      );
  }
  showDisclaimerForm() {
    // console.log(
    //   this.appConfig.getPhpUrl() + ApiHelper.php_generateWaiverDisclaimerPdfApi
    // );
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json"
    });
    let options = {
      headers: httpHeaders
    };
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      demographicId: this.demographicId
    };

    return this.http
      .post(
        this.appConfig.getPhpUrl() +
        ApiHelper.php_generateWaiverDisclaimerPdfApi,
        finalData,
        options
      )
      .subscribe(
        async apiResponse => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.success == 1) {
            // console.log(apiResponse);
            this.demographicUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.resultJsonObj.commandResult.data
            );

            this.showDemographicModal = true;
            this.showDemographicModalHeading = 'DISCLAIMER AND WAIVER FORM';
          } else {
            //alert("oops! error occured, please try later");
            const alertStatus =  await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.allotDocgrpComp.onSubmi1);
          }
        },
        err => {
          // console.log(err);
        }
      );
  }


  goToDemographicForm() {
    this.router.navigate(["/demographicsform/" + this.loggedInUserId]);
  }
  editDemographicForm4() {
    this.router.navigate([
      // "/edit-demographic-4/" + this.loggedInUserId + "/" + this.demographicId
    ]);
  }
  editSignature() {
    this.router.navigate([
      "/signaturewaiver/" + this.loggedInUserId + "/" + this.demographicId
    ]);
  }
  signOff() {
    this.showDocThumbnails = false;

    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json"
    });
    let options = {
      headers: httpHeaders
    };
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      demographicId: this.demographicId,
      company_slug: ConstantHelper.COMPANY_SLUG
    };

    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_generateDemographicPdfApi,
        finalData,
        options
      )
      .subscribe(
        apiResponse => {
          this.http
            .post(
              this.appConfig.getPhpUrl() +
              ApiHelper.php_generateHippaRealeasPdfApi,
              finalData,
              options
            )
            .subscribe(
              apiResponse => {
                this.http
                  .post(
                    this.appConfig.getPhpUrl() +
                    ApiHelper.php_generateWaiverDisclaimerPdfApi,
                    finalData,
                    options
                  )
                  .subscribe(
                    apiResponse => {
                      this.http
                        .post(
                          this.appConfig.getPhpUrl() +
                          ApiHelper.php_sendSignOffMailApi,
                          finalData,
                          options
                        )
                        .subscribe(
                          apiResponse => {
                            this.resultJsonObj = apiResponse;
                            // console.log(this.resultJsonObj);

                            this.http
                              .post(
                                this.appConfig.getPhpAhsUrl() +
                                ApiHelper.ahs_uploadFile2ElemrexApi,
                                finalData,
                                options
                              )
                              .subscribe(
                                apiResponse => {
                                  this.resultJsonObj = apiResponse;
                                  // console.log(this.resultJsonObj);
                                },
                                err => {
                                  // console.log(err);
                                }
                              );
                          },
                          err => {
                            // console.log(err);
                          }
                        );
                    },
                    err => {
                      // console.log(err);
                    }
                  );
              },
              err => {
                // console.log(err);
              }
            );
        },
        err => {
          // console.log(err);
        }
      );
  }
  closeDemographicModal() {
    this.showDemographicModal = false;
  }


  showDocument(documentName, documentUrl) {
    this.demographicUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      documentUrl
    );
    this.showDemographicModalHeading = documentName;
    this.showDemographicModal = true;
  }














}

import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-editform4",
  templateUrl: "./editform4.component.html",
  styleUrls: ["./editform4.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class Editform4Component implements OnInit {
  loggedInUserId: string;
  demographicId: string;
  resultJsonObj: any = {};
  resultJsonObjCity: any = {};
  resultJsonObjPryPhyCity: any = {};
  resultJsonObjOrdPhyCity: any = {};
  comapnyName: string = ConstantHelper.COMPANY_NAME;

  existingDemographicData: boolean = false;
  newDemographicData: boolean = false;
  editDdemographicsformDataObj: any = {};
  calculatedAge: number;
  loggedUserEmail: any;
  loggedUserMobile: any;
  newAge: any;
  finalstring: any;
  ahsDisclaimerPdf: SafeResourceUrl;
  ahsHippaPdf: SafeResourceUrl;
  showAddressSuggestionsModal: boolean = false;
  showGenders: any = ["Male", "Female", "Other"];
  showMOnths: any = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  showDays: any = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];
  showYears: any = [
    "1930",
    "1931",
    "1932",
    "1933",
    "1934",
    "1935",
    "1936",
    "1937",
    "1938",
    "1939",
    "1940",
    "1941",
    "1942",
    "1943",
    "1944",
    "1945",
    "1946",
    "1947",
    "1948",
    "1949",
    "1950",
    "1951",
    "1952",
    "1953",
    "1954",
    "1955",
    "1956",
    "1957",
    "1958",
    "1959",
    "1960",
    "1961",
    "1962",
    "1963",
    "1964",
    "1965",
    "1966",
    "1967",
    "1968",
    "1969",
    "1970",
    "1971",
    "1972",
    "1973",
    "1974",
    "1975",
    "1976",
    "1977",
    "1978",
    "1979",
    "1980",
    "1981",
    "1982",
    "1983",
    "1984",
    "1985",
    "1986",
    "1987",
    "1988",
    "1989",
    "1990",
    "1991",
    "1992",
    "1993",
    "1994",
    "1995",
    "1996",
    "1997",
    "1998",
    "1999",
    "2000",
    "2001",
    "2002",
    "2003",
    "2004",
    "2005",
    "2006",
    "2007",
    "2008",
    "2009",
    "2010",
    "2011",
    "2012",
    "2013",
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
  ];

  statesArre1: any = [
    { StateCode: "AL", State: "Alabama" },
    { StateCode: "AK", State: "Alaska" },
    { StateCode: "AZ", State: "Arizona" },
    { StateCode: "AR", State: "Arkansas" },
    { StateCode: "CA", State: "California" },
    { StateCode: "CO", State: "Colorado" },
    { StateCode: "CT", State: "Connecticut" },
    { StateCode: "DE", State: "Delaware" },
    { StateCode: "FL", State: "Florida" },
    { StateCode: "GA", State: "Georgia" },
    { StateCode: "HI", State: "Hawaii" },
    { StateCode: "ID", State: "Idaho" },
    { StateCode: "IL", State: "Illinois" },
    { StateCode: "IN", State: "Indiana" },
    { StateCode: "IA", State: "Iowa" },

    { StateCode: "KS", State: "Kansas" },
    { StateCode: "KY", State: "Kentucky" },
    { StateCode: "LA", State: "Louisiana" },
    { StateCode: "ME", State: "Maine" },
    { StateCode: "MD", State: "Maryland" },
    { StateCode: "MA", State: "Massachusetts" },

    { StateCode: "MI", State: "Michigan" },
    { StateCode: "MN", State: "Minnesota" },
    { StateCode: "MS", State: "Mississippi" },
    { StateCode: "MO", State: "Missouri" },
    { StateCode: "MT", State: "Montana" },
    { StateCode: "NE", State: "Nebraska" },

    { StateCode: "NV", State: "Nevada" },
    { StateCode: "NH", State: "New Hampshire" },
    { StateCode: "NJ", State: "New Jersey" },
    { StateCode: "NM", State: "New Mexico" },
    { StateCode: "NY", State: "New York" },
    { StateCode: "NC", State: "North Carolina" },

    { StateCode: "ND", State: "North Dakota" },
    { StateCode: "OH", State: "Ohio" },
    { StateCode: "OK", State: "Oklahoma" },
    { StateCode: "OR", State: "Oregon" },
    { StateCode: "PA", State: "Pennsylvania" },
    { StateCode: "PR", State: "Puerto Rico" },

    { StateCode: "RI", State: "Rhode Island" },
    { StateCode: "SC", State: "South Carolina" },
    { StateCode: "SD", State: "South Dakota" },
    { StateCode: "TN", State: "Tennessee" },
    { StateCode: "TX", State: "Texas" },
    { StateCode: "UT", State: "Utah" },

    { StateCode: "VT", State: "Vermont" },
    { StateCode: "VA", State: "Virginia" },
    { StateCode: "WA", State: "Washington" },
    { StateCode: "WV", State: "West Virginia" },
    { StateCode: "WI", State: "Wisconsin" },
    { StateCode: "WI", State: "Wisconsin" },
  ];

  statesArre: any = [];

  addressLength: boolean = false;
  noAddressLength: boolean = false;
  addressList: any;

  cityLength: boolean = false;
  noCityLength: boolean = false;
  citiesList: any = [];

  pryPhyCityLength: boolean = false;
  noPryPhyCityLength: boolean = false;
  pryPhyCitiesList: any = [];

  ordPhyCityLength: boolean = false;
  noOrdPhyCityLength: boolean = false;
  ordPhyCitiesList: any = [];

  orderStateSelectDisabledOption: boolean = true;
  orderCitySelectDisabledOption: boolean = true;
  orderSelectDisabledOption: boolean = true;

  priStateSelectDisabledOption: boolean = true;
  priCitySelectDisabledOption: boolean = true;
  priSelectDisabledOption: boolean = true;

  stateSelectDisabledOption: boolean = true;
  citySelectDisabledOption: boolean = true;

  priPhysicianLength: boolean = false;
  noPriPhysicianLength: boolean = false;
  priPhysiciansList: any;

  ordPhysicianLength: boolean = false;
  noOrdPhysicianLength: boolean = false;
  ordPhysiciansList: any;

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  groupsLength: boolean = false;
  groupsList: any;

  profileObj: any = {};
  docGroupId: any;
  docGroupTitle: any;

  docGrpSelectDisabledOption: boolean = true;
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  calculateAge(value) {
    if (value.dobDay > 0 && value.dobMonth > 0 && value.dobYear > 0) {
      var calculatedAge = this.commonHelper.calculateAge(
        value.dobYear,
        value.dobMonth,
        value.dobDay
      );
      this.editDdemographicsformDataObj.age = calculatedAge;
      this.newAge = calculatedAge;
    } else {
      this.editDdemographicsformDataObj.age =
        this.editDdemographicsformDataObj.age;
    }
    // console.log(value);
  }

  ngOnInit() {
    this.ahsDisclaimerPdf = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.appConfig.getDisclaimerPdf()
    );

    this.ahsHippaPdf = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.appConfig.getHippaPdf()
    );

    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");

    this.demographicId =
      this.activatedRoute.snapshot.paramMap.get("demographicId");

    // console.log(this.demographicId);

    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });

    const options = {
      headers: httpHeaders,
    };

    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      id: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_findUserDemographicApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;

          /////////////////////////////////////////////////////// Cities List Starts /////////////////////////////////////////////////////
          const finalDataCity = {
            stateCode:
              this.resultJsonObj.commandResult.data.userInfo.form1State,
          };
          this.http
            .post(
              this.appConfig.getLambdaUrl01() +
                ApiHelper.name_getCitiesByStateCodeApi,
              finalDataCity,
              options
            )
            .subscribe(
              (apiResponseCity) => {
                this.resultJsonObjCity = apiResponseCity;
                if (this.resultJsonObjCity.commandResult.status == 1) {
                  if (
                    this.resultJsonObjCity.commandResult.data.cityList.length >
                    0
                  ) {
                    this.cityLength = true;
                    this.citiesList =
                      this.resultJsonObjCity.commandResult.data.cityList;
                    // console.log('Cities List');
                    // console.log(this.citiesList);
                  } else {
                    this.noCityLength = true;
                  }
                } else {
                  this.noCityLength = true;
                }
              },
              (err) => {
                // console.log(err);
              }
            );
          /////////////////////////////////////////////////////// Cities List Ends /////////////////////////////////////////////////////

          /////////////////////////////////////////////////////// Pry Cities List Starts /////////////////////////////////////////////////////
          const finalDataPryPhyCity = {
            stateCode:
              this.resultJsonObj.commandResult.data.userInfo
                .form1PrimaryPhysicianState,
          };
          this.http
            .post(
              this.appConfig.getLambdaUrl01() +
                ApiHelper.name_getCitiesByStateCodeApi,
              finalDataPryPhyCity,
              options
            )
            .subscribe(
              (apiResponsePryPhyCity) => {
                this.resultJsonObjPryPhyCity = apiResponsePryPhyCity;
                if (this.resultJsonObjPryPhyCity.commandResult.status == 1) {
                  if (
                    this.resultJsonObjPryPhyCity.commandResult.data.cityList
                      .length > 0
                  ) {
                    this.pryPhyCityLength = true;
                    this.pryPhyCitiesList =
                      this.resultJsonObjPryPhyCity.commandResult.data.cityList;
                    // console.log('Pry PhyCities List');
                    // console.log(this.pryPhyCitiesList);
                  } else {
                    this.noPryPhyCityLength = true;
                  }
                } else {
                  this.noPryPhyCityLength = true;
                }
              },
              (err) => {
                // console.log(err);
              }
            );

          /////////////////////////////////////////////////////// Pry Cities List Ends /////////////////////////////////////////////////////

          /////////////////////////////////////////////////////// Ord Cities List Starts /////////////////////////////////////////////////////
          const finalDataOrdPhyCity = {
            stateCode:
              this.resultJsonObj.commandResult.data.userInfo
                .form1OrderingPhysicianState,
          };
          this.http
            .post(
              this.appConfig.getLambdaUrl01() +
                ApiHelper.name_getCitiesByStateCodeApi,
              finalDataOrdPhyCity,
              options
            )
            .subscribe(
              (apiResponseOrdPhyCity) => {
                this.resultJsonObjOrdPhyCity = apiResponseOrdPhyCity;
                if (this.resultJsonObjOrdPhyCity.commandResult.status == 1) {
                  if (
                    this.resultJsonObjOrdPhyCity.commandResult.data.cityList
                      .length > 0
                  ) {
                    this.ordPhyCityLength = true;
                    this.ordPhyCitiesList =
                      this.resultJsonObjOrdPhyCity.commandResult.data.cityList;
                    // console.log('Ord PhyCities List');
                    // console.log(this.ordPhyCitiesList);
                  } else {
                    this.noOrdPhyCityLength = true;
                  }
                } else {
                  this.noOrdPhyCityLength = true;
                }
              },
              (err) => {
                // console.log(err);
              }
            );

          /////////////////////////////////////////////////////// Ord Cities List Ends /////////////////////////////////////////////////////

          /////////////////////////////////////////////////////// pri Phy List Starts /////////////////////////////////////////////////////
          const finalDataOrdPhysician = {
            cityName:
              this.resultJsonObj.commandResult.data.userInfo
                .form1PrimaryPhysicianCity,
          };
          this.http
            .post(
              this.appConfig.getLambdaUrl03() +
                ApiHelper.name_getPhysiciansByCityCodeApi,
              finalDataOrdPhysician,
              options
            )
            .subscribe(
              (apiResponsePhysician: any) => {
                if (apiResponsePhysician.commandResult.status == 1) {
                  if (
                    apiResponsePhysician.commandResult.data.physiciansList
                      .length > 0
                  ) {
                    this.priPhysicianLength = true;
                    this.priSelectDisabledOption = false;
                    this.priPhysiciansList =
                      apiResponsePhysician.commandResult.data.physiciansList;
                    // console.log(this.priPhysiciansList);
                    // console.log(this.priPhysicianLength);
                  } else {
                    this.noPriPhysicianLength = true;
                  }
                } else {
                  this.noPriPhysicianLength = true;
                }
              },
              (err) => {
                // console.log(err);
              }
            );
          /////////////////////////////////////////////////////// pri phy List Ends /////////////////////////////////////////////////////

          /////////////////////////////////////////////////////// pri Phy List Starts /////////////////////////////////////////////////////
          const finalDataPhysician = {
            cityName:
              this.resultJsonObj.commandResult.data.userInfo
                .form1OrderingPhysicianCity,
          };
          this.http
            .post(
              this.appConfig.getLambdaUrl03() +
                ApiHelper.name_getPhysiciansByCityCodeApi,
              finalDataPhysician,
              options
            )
            .subscribe(
              (apiResponsePhysician: any) => {
                if (apiResponsePhysician.commandResult.status == 1) {
                  if (
                    apiResponsePhysician.commandResult.data.physiciansList
                      .length > 0
                  ) {
                    this.ordPhysicianLength = true;
                    this.orderSelectDisabledOption = false;
                    this.ordPhysiciansList =
                      apiResponsePhysician.commandResult.data.physiciansList;
                    // console.log(this.ordPhysiciansList);
                    // console.log(this.ordPhysicianLength);
                  } else {
                    this.noOrdPhysicianLength = true;
                  }
                } else {
                  this.noOrdPhysicianLength = true;
                }
              },
              (err) => {
                // console.log(err);
              }
            );
          /////////////////////////////////////////////////////// pri phy List Ends /////////////////////////////////////////////////////

          this.editDdemographicsformDataObj.email =
            this.resultJsonObj.commandResult.data.userInfo.Email;
          this.editDdemographicsformDataObj.cellPhone =
            this.resultJsonObj.commandResult.data.userInfo.Mobile;

          if (
            this.resultJsonObj.commandResult.data.userInfo.DemographicId > 0
          ) {
            let form1DOB =
              this.resultJsonObj.commandResult.data.userInfo.form1DOB.split(
                "-"
              );

            // console.log(
            //   typeof this.resultJsonObj.commandResult.data.userInfo
            //     .DemographicId
            // );
            this.existingDemographicData = true;
            this.editDdemographicsformDataObj.fName =
              this.resultJsonObj.commandResult.data.userInfo.from1FirstName;

            if (
              this.resultJsonObj.commandResult.data.userInfo.from1MiddleName ==
                "NA" ||
              this.resultJsonObj.commandResult.data.userInfo.from1MiddleName ==
                "-"
            ) {
              this.editDdemographicsformDataObj.mName = "";
            } else {
              this.editDdemographicsformDataObj.mName =
                this.resultJsonObj.commandResult.data.userInfo.from1MiddleName;
            }

            this.editDdemographicsformDataObj.lName =
              this.resultJsonObj.commandResult.data.userInfo.from1LastName;
            this.editDdemographicsformDataObj.dobYear = form1DOB[0];
            this.editDdemographicsformDataObj.dobMonth = form1DOB[1];
            this.editDdemographicsformDataObj.dobDay = form1DOB[2];

            this.editDdemographicsformDataObj.age =
              this.resultJsonObj.commandResult.data.userInfo.form1Age;
            this.editDdemographicsformDataObj.gender =
              this.resultJsonObj.commandResult.data.userInfo.form1Gender;

            if (
              this.resultJsonObj.commandResult.data.userInfo
                .form1ResidenceNumber == "NA" ||
              this.resultJsonObj.commandResult.data.userInfo
                .form1ResidenceNumber == "-"
            ) {
              this.editDdemographicsformDataObj.residence = "";
            } else {
              this.editDdemographicsformDataObj.residence =
                this.resultJsonObj.commandResult.data.userInfo.form1ResidenceNumber;
            }
            this.editDdemographicsformDataObj.street =
              this.resultJsonObj.commandResult.data.userInfo.form1StreetName;
            this.editDdemographicsformDataObj.city =
              this.resultJsonObj.commandResult.data.userInfo.form1City;
            this.editDdemographicsformDataObj.state =
              this.resultJsonObj.commandResult.data.userInfo.form1State;
            this.editDdemographicsformDataObj.zip =
              this.resultJsonObj.commandResult.data.userInfo.form1ZIP;
            this.editDdemographicsformDataObj.email =
              this.resultJsonObj.commandResult.data.userInfo.form1EmailAddress;
            this.editDdemographicsformDataObj.cellPhone =
              this.resultJsonObj.commandResult.data.userInfo.form1CellularPhone;

            if (
              this.resultJsonObj.commandResult.data.userInfo
                .form1CellularPhoneAlt == "NA" ||
              this.resultJsonObj.commandResult.data.userInfo
                .form1CellularPhoneAlt == "-"
            ) {
              this.editDdemographicsformDataObj.residence = "";
            } else {
              this.editDdemographicsformDataObj.altPhone =
                this.resultJsonObj.commandResult.data.userInfo.form1CellularPhoneAlt;
            }

            this.editDdemographicsformDataObj.primaryPhysician =
              this.resultJsonObj.commandResult.data.userInfo.form1PrimaryPhysician;
            this.editDdemographicsformDataObj.primaryPhysicianState =
              this.resultJsonObj.commandResult.data.userInfo.form1PrimaryPhysicianState;
            this.editDdemographicsformDataObj.primaryPhysicianCity =
              this.resultJsonObj.commandResult.data.userInfo.form1PrimaryPhysicianCity;

            this.editDdemographicsformDataObj.orderingPhysician =
              this.resultJsonObj.commandResult.data.userInfo.form1OrderingPhysician;
            this.editDdemographicsformDataObj.orderingPhysicianState =
              this.resultJsonObj.commandResult.data.userInfo.form1OrderingPhysicianState;
            this.editDdemographicsformDataObj.orderingPhysicianCity =
              this.resultJsonObj.commandResult.data.userInfo.form1OrderingPhysicianCity;

            this.editDdemographicsformDataObj.demographicId =
              this.resultJsonObj.commandResult.data.userInfo.DemographicId;

            this.orderStateSelectDisabledOption = false;
            this.orderCitySelectDisabledOption = false;

            this.priStateSelectDisabledOption = false;
            this.priCitySelectDisabledOption = false;

            this.stateSelectDisabledOption = false;
            this.citySelectDisabledOption = false;
          } else {
            this.newDemographicData = true;
            this.editDdemographicsformDataObj.demographicId = 0;
          }
        },
        (err) => {
          // console.log(err);
        }
      );

    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findPhysicianCitiesApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse: any) => {
          this.statesArre = apiResponse.commandResult.data.statesList;
          // console.log('this.statesArre=>', this.statesArre)
        },
        (err) => {
          // console.log(err);
        }
      );

    this.findDocumentGroups();
    this.findUserDetails(this.loggedInUserId);
  }

  async onSubmit() {
    // console.log(this.editDdemographicsformDataObj.DocGroupId);
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure you want to continue with the given information?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.doctorProfileComp.onSubmit
    );
    {
      let httpHeaders = new HttpHeaders({
        // "Content-Type": "application/x-www-form-urlencoded"
        "Content-Type": "application/json",
      });
      let options = {
        headers: httpHeaders,
      };

      const finalData = {
        company_slug: ConstantHelper.COMPANY_SLUG,
        fName: this.editDdemographicsformDataObj.fName,
        mName: this.editDdemographicsformDataObj.mName,
        lName: this.editDdemographicsformDataObj.lName,
        dobMonth: this.editDdemographicsformDataObj.dobMonth,
        dobDay: this.editDdemographicsformDataObj.dobDay,
        dobYear: this.editDdemographicsformDataObj.dobYear,
        age: this.newAge ? this.newAge : this.editDdemographicsformDataObj.age,
        gender: this.editDdemographicsformDataObj.gender,
        residence: this.editDdemographicsformDataObj.residence,
        street: this.editDdemographicsformDataObj.street,
        city: this.editDdemographicsformDataObj.city,
        state: this.editDdemographicsformDataObj.state,
        zip: this.editDdemographicsformDataObj.zip,
        email: this.editDdemographicsformDataObj.email,
        cellPhone: this.editDdemographicsformDataObj.cellPhone,
        altPhone: this.editDdemographicsformDataObj.altPhone,
        primaryPhysician: this.editDdemographicsformDataObj.primaryPhysician,
        primaryPhysicianState:
          this.editDdemographicsformDataObj.primaryPhysicianState,
        primaryPhysicianCity:
          this.editDdemographicsformDataObj.primaryPhysicianCity,

        orderingPhysician: this.editDdemographicsformDataObj.orderingPhysician,
        orderingPhysicianState:
          this.editDdemographicsformDataObj.orderingPhysicianState,
        orderingPhysicianCity:
          this.editDdemographicsformDataObj.orderingPhysicianCity,

        loggedInUserId: this.loggedInUserId,
        demographicId: this.editDdemographicsformDataObj.demographicId,
        oldDocGroupId: this.docGroupId,
      };

      return this.http
        .post(
          this.appConfig.getLambdaUrl01() +
            ApiHelper.name_updateDemographicDetailsApi,
          finalData,
          options
        )
        .subscribe(
          async (apiResponse) => {
            this.resultJsonObj = apiResponse;
            // console.log(this.resultJsonObj);
            if (this.resultJsonObj.commandResult.status == 1) {
              this.updateDocGroupId(
                this.editDdemographicsformDataObj.DocGroupId,
                this.loggedInUserId,
                this.editDdemographicsformDataObj.demographicId
              );
            } else {
              //   alert("oops! error occured, please try later");
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.allotDocgrpComp.onSubmi1
              );
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  verifyAddress() {
    this.addressList = {};
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let options = {
      headers: httpHeaders,
    };

    const finalData = {
      streeAddress:
        this.editDdemographicsformDataObj.residence +
        ", " +
        this.editDdemographicsformDataObj.street +
        ", " +
        this.editDdemographicsformDataObj.city +
        ", " +
        this.editDdemographicsformDataObj.state +
        ", " +
        this.editDdemographicsformDataObj.zip,
    };

    // console.log(finalData);
    // return;
    // console.log(
    //   this.appConfig.getLambdaUrl02() + ApiHelper.name_verifyAddressApi
    // );

    return this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_verifyAddressApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.status == 1) {
            if (this.resultJsonObj.commandResult.data.Addresses.length > 0) {
              this.addressLength = true;
              this.addressList =
                this.resultJsonObj.commandResult.data.Addresses;
              // console.log(this.addressList);
            } else {
              // this.noAddressLength = true;
            }
          } else {
            // this.noAddressLength = true;
          }
          this.showAddressSuggestionsModal = true;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  closeAddressSuggestionsModal() {
    this.showAddressSuggestionsModal = false;
  }

  onItemChange(address) {
    if (address != "curentAdd") {
      var splitted = address.split(",");
      this.editDdemographicsformDataObj.street = splitted[0];
      // var splitted1 = splitted[1].trim().split(" ");

      // var n = splitted[1].lastIndexOf(" ");

      var stateName = splitted[1]
        .trim()
        .substring(splitted[1].trim().lastIndexOf(" ") + 1);

      var cityName = splitted[1]
        .trim()
        .substring(0, splitted[1].trim().lastIndexOf(" "));

      // console.log(cityName);
      // console.log(stateName);
      this.editDdemographicsformDataObj.city = cityName;
      this.editDdemographicsformDataObj.state = stateName;
      this.showAddressSuggestionsModal = false;
    }
  }

  findCitiesByStateCode(stateCode) {
    // console.log(stateCode)
    this.stateSelectDisabledOption = false;

    this.citiesList = [];
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let options = {
      headers: httpHeaders,
    };
    const finalData = { stateCode: stateCode };
    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          this.citySelectDisabledOption = true;
          this.editDdemographicsformDataObj.zip = null;
          if (this.resultJsonObj.commandResult.status == 1) {
            if (this.resultJsonObj.commandResult.data.cityList.length > 0) {
              this.cityLength = true;
              this.citiesList = this.resultJsonObj.commandResult.data.cityList;
              // console.log(this.citiesList);
            } else {
              this.noCityLength = true;
            }
          } else {
            this.noCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPryPhyCitiesByStateCode(stateCode) {
    // alert(stateCode)
    this.priStateSelectDisabledOption = false;

    this.pryPhyCitiesList = [];
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let options = {
      headers: httpHeaders,
    };
    const finalData = { stateCode: stateCode };
    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          this.priCitySelectDisabledOption = false;
          if (this.resultJsonObj.commandResult.status == 1) {
            if (this.resultJsonObj.commandResult.data.cityList.length > 0) {
              this.pryPhyCityLength = true;
              this.pryPhyCitiesList =
                this.resultJsonObj.commandResult.data.cityList;
              // console.log(this.pryPhyCitiesList);
            } else {
              this.noPryPhyCityLength = true;
            }
          } else {
            this.noPryPhyCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findOrdPhyCitiesByStateCode(stateCode) {
    // alert(stateCode)
    this.orderStateSelectDisabledOption = false;

    this.ordPhyCitiesList = [];
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let options = {
      headers: httpHeaders,
    };
    const finalData = { stateCode: stateCode };
    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          this.orderCitySelectDisabledOption = false;
          if (this.resultJsonObj.commandResult.status == 1) {
            if (this.resultJsonObj.commandResult.data.cityList.length > 0) {
              // console.log(this.orderStateSelectDisabledOption);
              this.ordPhyCityLength = true;
              this.ordPhyCitiesList =
                this.resultJsonObj.commandResult.data.cityList;
              // console.log(this.ordPhyCitiesList);
            } else {
              this.noOrdPhyCityLength = true;
            }
          } else {
            this.noOrdPhyCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the Practice Home Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.demographicsFormComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(["/dashboard/" + this.loggedInUserId]);
    }
  }

  hideDisabledOrderCity() {
    this.orderCitySelectDisabledOption = false;
  }

  hideDisabledOrder() {
    this.orderSelectDisabledOption = false;
  }

  hideDisabledPriCity() {
    this.priCitySelectDisabledOption = false;
  }

  hideDisabledPri() {
    this.priSelectDisabledOption = false;
  }

  hideDisabledCity() {
    this.citySelectDisabledOption = false;
  }

  findPryPhysiciansByCity(cityName) {
    this.priCitySelectDisabledOption = false;
    const finalDataPhysician = { cityName: cityName };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_getPhysiciansByCityCodeApi,
        finalDataPhysician,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          if (apiResponsePhysician.commandResult.status == 1) {
            this.priSelectDisabledOption = false;
            if (
              apiResponsePhysician.commandResult.data.physiciansList.length > 0
            ) {
              this.priPhysicianLength = true;
              this.priPhysiciansList =
                apiResponsePhysician.commandResult.data.physiciansList;
              // console.log(this.priPhysiciansList);
              // console.log(this.priPhysicianLength);
            } else {
              //   alert("No Physicians Found");
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.adminsecondoptwfCOmp
                  .findcoePhysicians
              );

              this.noPriPhysicianLength = true;
            }
          } else {
            // alert("No Physicians Found");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findcoePhysicians
            );
            this.noPriPhysicianLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findOdrPhysiciansByCity(cityName) {
    this.orderCitySelectDisabledOption = false;
    const finalDataOrdPhysician = { cityName: cityName };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_getPhysiciansByCityCodeApi,
        finalDataOrdPhysician,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          // console.log(apiResponsePhysician);
          if (apiResponsePhysician.commandResult.status == 1) {
            this.orderSelectDisabledOption = false;
            if (
              apiResponsePhysician.commandResult.data.physiciansList.length > 0
            ) {
              this.ordPhysicianLength = true;
              this.ordPhysiciansList =
                apiResponsePhysician.commandResult.data.physiciansList;
              // console.log(this.ordPhyCityLength);
              // console.log(this.ordPhysiciansList);
            } else {
              //   alert("No Physicians Found");
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.adminsecondoptwfCOmp
                  .findcoePhysicians
              );
              this.noOrdPhysicianLength = true;
            }
          } else {
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findcoePhysicians
            );
            //  alert("No Physicians Found");
            this.noOrdPhysicianLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  generateSignedFiles() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      demographicId: this.demographicId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };

    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_generateDemographicPdfApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.http
            .post(
              this.appConfig.getPhpUrl() +
                ApiHelper.php_generateHippaRealeasPdfApi,
              finalData,
              this.httpOptions
            )
            .subscribe(
              (apiResponse) => {
                this.http
                  .post(
                    this.appConfig.getPhpUrl() +
                      ApiHelper.php_generateWaiverDisclaimerPdfApi,
                    finalData,
                    this.httpOptions
                  )
                  .subscribe(
                    (apiResponse) => {
                      this.resultJsonObj = apiResponse;
                      // console.log(this.resultJsonObj);

                      this.http
                        .post(
                          this.appConfig.getPhpAhsUrl() +
                            ApiHelper.ahs_uploadFile2ElemrexApi,
                          finalData,
                          this.httpOptions
                        )
                        .subscribe(
                          async (apiResponse) => {
                            this.resultJsonObj = apiResponse;
                            // console.log(this.resultJsonObj);
                            // alert(
                            //   "Demographic Information updated successfully."
                            // );
                            const alertStatus =
                              await this.sweetAlertHelper.alertPopUp(
                                this.message.alertMessages.editform4Comp
                                  .generateSignedFiles
                              );
                            if (alertStatus)
                              this.router.navigate([
                                "/dashboard/" + this.loggedInUserId,
                              ]);
                          },
                          (err) => {
                            // console.log(err);
                          }
                        );
                    },
                    (err) => {
                      // console.log(err);
                    }
                  );
              },
              (err) => {
                // console.log(err);
              }
            );
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findDocumentGroups() {
    const finalDataDocGroup = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_findDocGroupsByCompanyApi,
        finalDataDocGroup,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.groupList.length > 0) {
            this.groupsLength = true;
            this.groupsList = this.resultJsonObj.commandResult.data.groupList;
            // console.log(this.groupsList);
          } else {
            this.groupsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findUserDetails(userId) {
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      id: userId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            this.editDdemographicsformDataObj.DocGroupId =
              apiResponse.commandResult.data.userInfo.DocGroupId;
            // console.log('profileObj', this.editDdemographicsformDataObj.DocGroupId);
            this.docGroupId =
              apiResponse.commandResult.data.userInfo.DocGroupId;
            this.docGroupTitle =
              apiResponse.commandResult.data.userInfo.GroupTitle;
            this.profileObj = apiResponse.commandResult.data.userInfo;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  selectDocGroup() {
    this.docGrpSelectDisabledOption = false;
  }

  updateDocGroupId(docGroupId, userId, demographicId) {
    const finalData = {
      docGroupId,
      userId,
      demographicId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_updateUserDocGroupApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        async (apiResponse: any) => {
          //   alert("Demographic Information updated successfully.");
          const alertStatus = await this.sweetAlertHelper.alertPopUp(
            this.message.alertMessages.editform4Comp.generateSignedFiles
          );
          if (alertStatus)
            this.router.navigate(["/dashboard/" + this.loggedInUserId]);
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  validateNumber(event) {
    const keyCode = event.keyCode;

    const excludedKeys = [8, 37, 39, 46];

    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        excludedKeys.includes(keyCode)
      )
    ) {
      event.preventDefault();
    }
  }

  checkValidString(stringData) {
    if (stringData == "NA") {
      this.finalstring = "";
    } else if (stringData == "-") {
      this.finalstring = "";
    } else {
      this.finalstring = stringData;
    }
    return this.finalstring;
  }
}

import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { from } from "rxjs";
import { DatePipe } from "@angular/common";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { SignaturePad } from "angular2-signaturepad";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { UserService } from "./../../_services/user.service";
import * as moment from "moment";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
@Component({
  selector: "app-ga",
  templateUrl: "./ga.component.html",
  styleUrls: ["./ga.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class GaComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  hippaJson: any = {};
  loggedInUserId: string;
  userSignatures: any = [];
  userSignatureSelected = false;
  userFullSignature = "";
  userInitialSignature = "";
  userChoosedSignature = "";
  userFullSignatureExit = false;
  userInitialSignatureExis = false;
  showPdfModal = false;
  signatureType: any;

  ssnPattern = "^[0-9]{4}$";

  resultJsonObj: any = {};
  clean = "0";
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  @ViewChild("canvasArea", { static: true }) canvasArea: ElementRef;
  canvasElm: any;
  public signaturePadOptions: Object = {
    minWidth: 1,
    canvasWidth:
      window.innerWidth < 1024 ? window.innerWidth : window.innerWidth / 2,
    canvasHeight: 450,
  };

  currentDate = new Date();
  dateFormat = "MM-dd-yyyy";

  userWitnessSignatureSelected = false;
  userWitnessChoosedSignature = "";

  signatureFroWhom: any;
  isEmail: any;
  isPracticePhy: any;
  physicianId: any;
  requestId: any;
  tempId: any;
  userOptionId: any;
  emailLogId: any;
  message = Message;
  patientId: string;
  fileDesTxt: any = '';
  selectedDelegateId: any;
  assignedDelegateId: any;
  isDelegated: any = 0;
  selectedDocUrl: any;
  selectedPhysician: any = {};
  docInfo: any = {};
  selectedStateCode: any;
  showLoader = false;
  childRoute: string;
  patient_name: any;
  physicianName: any;
  PhysicianOfficeAddress: any;
  dob: any;
  social_security_no: any;
  loginUserType: any;
  healthcare_provider: any;
  city_zip: any;
  demographicId: any;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public userService: UserService
  ) {
    this.loggedInUserId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.isEmail = this.activatedRoute.snapshot.paramMap.get("isEmail")
      ? this.activatedRoute.snapshot.paramMap.get("isEmail")
      : 0;
    this.isPracticePhy =
      this.activatedRoute.snapshot.paramMap.get("isPracticePhy");
    this.physicianId = this.activatedRoute.snapshot.paramMap.get("physicianId")
      ? this.activatedRoute.snapshot.paramMap.get("physicianId")
      : 0;
    this.requestId = this.activatedRoute.snapshot.paramMap.get("requestId")
      ? this.activatedRoute.snapshot.paramMap.get("requestId")
      : 0;

    this.tempId = this.activatedRoute.snapshot.paramMap.get("tempId")
      ? this.activatedRoute.snapshot.paramMap.get("tempId")
      : 0;
    this.userOptionId = this.activatedRoute.snapshot.paramMap.get(
      "userOptionId"
    )
      ? this.activatedRoute.snapshot.paramMap.get("userOptionId")
      : 0;
    this.emailLogId = this.activatedRoute.snapshot.paramMap.get("emailLogId")
      ? this.activatedRoute.snapshot.paramMap.get("emailLogId")
      : 0;

    this.hippaJson.healthcare_provider = this.userService.getUS_FromatName(
      localStorage.getItem("providerName")
    );
    this.hippaJson.healthcare_provider_email =
      localStorage.getItem("providerEmail");
    this.hippaJson.healthcare_provider_npi = localStorage.getItem(
      "providerPhysicianNipNo"
    );
    this.hippaJson.street_address = localStorage.getItem(
      "providerOfficeAddress"
    );
    this.hippaJson.city_zip = localStorage.getItem("providerCity")
      ? localStorage.getItem("providerCity")
      : "" + ", " + localStorage.getItem("PhysicianStateName")
      ? localStorage.getItem("PhysicianStateName")
      : "" + ", " + localStorage.getItem("providerZipCode")
      ? localStorage.getItem("providerZipCode")
      : "";

    this.hippaJson.providerCity = localStorage.getItem("providerCity");
    this.hippaJson.providerPhone = localStorage.getItem("providerPhone");
    this.hippaJson.providerPhysicianNipNo = localStorage.getItem(
      "providerPhysicianNipNo"
    );

    this.hippaJson.patient_name = this.userService.getUS_FromatName(
      localStorage.getItem("loggedInUserName")
    );
    //this.fnFindSignature(this.loggedInUserId);
    this.fnFindDemographic(this.loggedInUserId);
    this.hippaJson.patient_signature_date = this.currentDate;
    this.hippaJson.witness_signature_date = this.currentDate;
    this.hippaJson.pre_to = this.currentDate;
    this.hippaJson.post_to = this.currentDate;
    this.hippaJson.physicianName = localStorage.getItem('selectedPhysicianName');
    this.hippaJson.PhysicianOfficeAddress = localStorage.getItem('selectedPhysicianOfficeAddress');
    this.hippaJson.physicianNPI = localStorage.getItem('selectedPhysicianNPI');
    this.hippaJson.physicianEmail = localStorage.getItem('selectedPhysicianName');
    this.hippaJson.physicianCity = localStorage.getItem('selectedPhysicianCity');
    this.hippaJson.physicianState = localStorage.getItem('selectedPhysicianState');
    this.hippaJson.physicianZip=localStorage.getItem('providerZipCode')    
  
  }

  ngOnInit() {
    // console.log(localStorage);
    this.patientId = this.activatedRoute.snapshot.paramMap.get('patientId');
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.hippaJson.checkbox_01 = false;
    this.hippaJson.checkbox_02 = false;
    this.hippaJson.checkbox_03 = false;
    this.hippaJson.checkbox_04 = false;
    this.hippaJson.checkbox_05 = false;
    this.hippaJson.checkbox_06 = false;
    this.hippaJson.checkbox_07 = false;
    // this.hippaJson.patient_signature = '';
    // this.hippaJson.witness_signature = '';
    this.hippaJson.healthcare_provider = this.hippaJson.physicianName;
    this.hippaJson.street_address = this.hippaJson.PhysicianOfficeAddress;
    //this.hippaJson.city_zip = this.hippaJson.physicianCity + "," + this.hippaJson.physicianState;
    this.hippaJson.city_zip= this.hippaJson.physicianCity + "," + this.hippaJson.physicianState+","+this.hippaJson.physicianZip;
    this.findUserDetails(this.patientId);
    this.hippaJson.healthcare_provider_npi = localStorage.getItem('selectedPhysicianNPI');
  }

  // fnFindSignature(user_id) {
  //   const reqData = {
  //     user_id,
  //   };
  //   return this.http
  //     .post(
  //       this.appConfig.getPhpUrl() + ApiHelper.php_findUserSignatureApi,
  //       reqData,
  //       this.httpOptions
  //     )
  //     .subscribe(
  //       (response: any) => {
  //         if (response.commandResult.success == 1) {
  //           this.userSignatures = response.commandResult.data.userSignatures;
  //           this.userFullSignature =
  //             response.commandResult.data.userSignatures.full_signature_url;
  //           this.userInitialSignature =
  //             response.commandResult.data.userSignatures.initial_signature_url;

  //           if (response.commandResult.data.userSignatures.full_signature_url) {
  //             this.userFullSignatureExit = true;
  //           }

  //           if (
  //             response.commandResult.data.userSignatures.initial_signature_url
  //           ) {
  //             this.userInitialSignatureExis = true;
  //           }
  //         } else {
  //         }

  //         // console.log(this.userSignatures);
  //       },
  //       (err) => {}
  //     );
  // }
  findUserDetails(patientId) {
    const finalData = {
      company_slug: null,
      id: patientId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            this.hippaJson.patient_name = apiResponse.commandResult.data.userInfo.LastName + ', ' + apiResponse.commandResult.data.userInfo.FirstName;
            this.hippaJson.dob = apiResponse.commandResult.data.userInfo.DOB;
          }
          //console.log("Date of birth:",typeof(this.hippaJson.dob));
        },
        (err) => {
        }
      );
  }

  async onSubmit() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM:   By clicking ‘OK’:The information you have entered will be used to electronically generate a completed State HIPAA Form."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.onSubmit
    );
   if(alertStatus) {
      const finalData = {
        selectedDocUrl: this.selectedDocUrl,
        fileDesTxt: this.fileDesTxt,
        loggedInUserId: this.loggedInUserId,
        userId: this.patientId,
        loginUserType: this.loginUserType,
        patient_name: this.hippaJson.patient_name,
        social_security_no: this.hippaJson.social_security_no,
        dob: this.hippaJson.dob,
        healthcare_provider: this.hippaJson.physicianName,
        street_address: this.hippaJson.PhysicianOfficeAddress,
        city_zip: this.hippaJson.city_zip,
        physicianState: this.selectedStateCode,
        assignedDelegateId: this.assignedDelegateId,
        isDelegated: this.isDelegated,
        selectedDelegateId: this.selectedDelegateId,
        selectedEmail: this.selectedPhysician.altEmail,
        selectedPhysician: this.selectedPhysician.physicianName,
        checkbox_01: this.hippaJson.checkbox_01,
        checkbox_02: this.hippaJson.checkbox_02,
        checkbox_03: this.hippaJson.checkbox_03,
        checkbox_04: this.hippaJson.checkbox_04,
        checkbox_05: this.hippaJson.checkbox_05,
        checkbox_06: this.hippaJson.checkbox_06,
        checkbox_07: this.hippaJson.checkbox_07,
        pre_to: this.hippaJson.pre_to,
        post_to: this.hippaJson.post_to,
        purposes: this.hippaJson.purposes,
        representative_name: this.hippaJson.representative_name,
        representative_capacity: this.hippaJson.representative_capacity,
        representative_street_address: this.hippaJson.PhysicianOfficeAddress,
        representative_city_zip: this.hippaJson.representative_city_zip,

      };
      // console.log(reqData);
      this.showLoader=true;
      return this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.generateHipaaGA,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            if (this.hippaJson.loggedInUserRoleId == 1) {
              this.router.navigate([
                '/doctor/' + '/demographics/' +
                this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
                '/1/'
              ]);
            } else if (this.hippaJson.loggedInUserRoleId == 4) {
              this.router.navigate([
                '/doctor/' + '/demographics/' +
                this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
                '/1/'
              ]);
            } else {
              if (window.open(apiResponse.commandResult.data, '_blank')) {
                this.router.navigate([
                  '/doctor/' + '/ushf/' +
                  this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/' + this.activatedRoute.snapshot.paramMap.get('patientId') + '/' + this.activatedRoute.snapshot.paramMap.get('demographicId')

                ]);
              }
            }
            this.showLoader=false;
          },
          (err) => {
          }
        );
    }
  }


  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Click ‘OK’ to confirm returning to the ‘Home Page’."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(['/doctor/' +
      '/dashboard/' +
      this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),
    ]);
    }
  }

  async goBack() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the USHF Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.goBack
    );
    if (alertStatus) {
      this.router.navigate([
        '/doctor/' + '/ushf/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/' + this.activatedRoute.snapshot.paramMap.get('patientId') + '/' + this.activatedRoute.snapshot.paramMap.get('demographicId')

      ]);
    }
  }

  closePdfModal() {
    this.showPdfModal = false;
    this.hippaJson.patient_signature = "";
  }

  // openSignatureCanvas(selectedValue, signatureFroWhom) {
  //   this.signatureFroWhom = signatureFroWhom;
  //   // console.log(selectedValue);
  //   this.signatureType = selectedValue;
  //   if (
  //     selectedValue == "AFS" ||
  //     selectedValue == "AIS" ||
  //     selectedValue == "AWTSG"
  //   ) {
  //     this.showPdfModal = true;
  //   }
  //   if (selectedValue == "FS" || selectedValue == "IS") {
  //     this.userSignatureSelected = true;
  //     if (selectedValue == "FS") {
  //       this.userChoosedSignature = this.userFullSignature;
  //     }
  //     if (selectedValue == "IS") {
  //       this.userChoosedSignature = this.userInitialSignature;
  //     }
  //   }
  // }

  ngAfterViewInit() {
    // this.signaturePad is now available
    //this.signaturePad.set("minWidth", 1); // set szimek/signature_pad options at runtime
    //this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
  }

  // async saveCanvas() {
  //   // if (
  //   //   confirm(
  //   //     "PLEASE CONFIRM: This will replace your existing signature. Do you wish to change?"
  //   //   )
  //   // )
  //   const alertStatus = await this.sweetAlertHelper.confirmPopUp(
  //     this.message.confirmTitle,
  //     this.message.confirmMessages.njComp.saveCanvas
  //   );
  //   {
  //     const reqData = {
  //       loggedInUserId: this.loggedInUserId,
  //       signatureBase64: this.signaturePad.toDataURL(),
  //       signatureType: this.signatureType,
  //     };
  //     return this.http
  //       .post(
  //         this.appConfig.getLambdaUrl05() +
  //           ApiHelper.name_saveSignatureImageApi,
  //         reqData,
  //         this.httpOptions
  //       )
  //       .subscribe(
  //         (apiResponse: any) => {
  //           if (apiResponse.commandResult.status == 1) {
  //             this.userSignatureSelected = true;
  //             this.userChoosedSignature =
  //               apiResponse.commandResult.data.fileInfo.signatureUrl;
  //             // console.log(this.userChoosedSignature);
  //             this.fnFindSignature(this.loggedInUserId);
  //             this.showPdfModal = false;
  //             // this.hippaJson.patient_signature = '';
  //           }
  //         },
  //         (err) => {
  //           // console.log(err);
  //         }
  //       );
  //   }
  // }

  // async clearCanvas() {
  //   // if (
  //   //   confirm("PLEASE CONFIRM: Are you sure you want to clear you signature.")
  //   // )
  //   const alertStatus = await this.sweetAlertHelper.confirmPopUp(
  //     this.message.confirmTitle,
  //     this.message.confirmMessages.njComp.clearCanvas
  //   );
  //   {
  //     this.signaturePad.clear();
  //   }
  // }

  drawStart() {}

  fnFindDemographic(patientId) {
    const finalData = {
      id: patientId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_findUserDemographicGeneralApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (response: any) => {
          // this.hippaJson.dob = new Date(
          //   response.commandResult.data.userInfo.form1DOB
          // );

          // this.hippaJson.city_zip = response.commandResult.data.userInfo.form1PrimaryPhysicianCity + ', ' + response.commandResult.data.userInfo.form1State + ', ' + response.commandResult.data.userInfo.form1ZIP;

          // this.hippaJson.street_address = response.commandResult.data.userInfo.form1StreetName;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  autoFormatSSN() {
    // alert('Amit');
    let val = this.hippaJson.social_security_no.replace(/\D/g, "");
    // val = val.replace(/^(\d{3})/, "$1-");
    // val = val.replace(/-(\d{2})/, "-$1-");
    // val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
    this.hippaJson.social_security_no = val;
  }

  openGABlankpdf() {
    window.open("https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/Georgia-HIPAA-Medical-Release-Form.pdf", "_blank")
  }

  // saveParentCanvas(signFor) {
  //   const reqData = {
  //     loggedInUserId: this.loggedInUserId,
  //     signatureBase64: this.signaturePad.toDataURL(),
  //     signatureType: this.signatureType,
  //     signFor,
  //   };
  //   return this.http
  //     .post(
  //       this.appConfig.getLambdaUrl05() +
  //         ApiHelper.name_saveParentSignatureImageApi,
  //       reqData,
  //       this.httpOptions
  //     )
  //     .subscribe(
  //       (apiResponse: any) => {
  //         if (apiResponse.commandResult.status == 1) {
  //           if (signFor == "WTSG") {
  //             this.userWitnessSignatureSelected = true;
  //             this.userWitnessChoosedSignature =
  //               apiResponse.commandResult.data.fileInfo.signatureUrl;
  //           }
  //           this.showPdfModal = false;
  //         }
  //       },
  //       (err) => {
  //         // console.log(err);
  //       }
  //     );
  // }
}

import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  HostListener
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from '@angular/common/http';
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

import {AuthService} from '../auth.service';
import {MustMatch} from '../../_helpers/matchpassword';

import {environment} from './../../../environments/environment';
import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';

import {AppConfig} from './../../../app/app.config';
import {ConstantHelper} from './../../_helpers/constant.helper';


@Component({
  selector: 'app-doctor-login',
  templateUrl: './doctor-login.component.html',
  styleUrls: ['./doctor-login.component.scss']
})
export class DoctorLoginComponent implements OnInit {

  @ViewChild('recaptcha', {static: false}) recaptchaElement: ElementRef;
  @HostListener('window:resize', ['$event'])
  loginForm: FormGroup;
  loginFromSubmitted = false;
  formErrorMessage = false;
  inactiveUserErrorMessage = false;
  showLoginForm = true;
  verifyLoginOtpForm: FormGroup;
  verifyLoginOtpFormSubmitted = false;
  verifyLoginFormErrorMessage = false;
  showVerifyLoginOtpForm = false;
  userOtp: number;
  userId: number;
  userEmail: string;
  userMobile: string;
  userRoleId: number;
  resultJsonObj: any = {};
  formRecaptchaErrorMessage = false;
  pId: any = '';
  docEmail: any = '';
  pUserId: any = '';
  docId: any = '';
  fieldTextType: boolean;
  message = Message;
  constructor(public commonHelper: CommonHelper,
              private appConfig: AppConfig,
              private formBuilder: FormBuilder,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              public sweetAlertHelper: SweetAlertHelper,
              private http: HttpClient) {

  }

  ngOnInit() {
    this.pId = this.activatedRoute.snapshot.paramMap.get('pId');
    this.docEmail = this.activatedRoute.snapshot.paramMap.get('docEmail');
    this.pUserId = this.activatedRoute.snapshot.paramMap.get('pUserId');
    this.docId = this.activatedRoute.snapshot.paramMap.get('docId');

    localStorage.removeItem('loggedInUserName');
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      recaptchaValue: ['', Validators.required]
    });

    this.verifyLoginOtpForm = this.formBuilder.group({
      otp: ['', Validators.required]
    });

    if (this.pId.length > 0) {
      this.checkExistingDoc();
    }
  }

  get f() {
    return this.loginForm.controls;
  }

  get v() {
    return this.verifyLoginOtpForm.controls;
  }

  onSubmit(loginFormValue) {
    this.loginFromSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const options = {
      headers: httpHeaders
    };
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
      user_role_id: ConstantHelper.USER_ROLE_DOCTOR,
    };
    // console.log('doctor login', finalData);

    return this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_AdminloginApi,
        finalData,
        options
      )
      .subscribe(
        apiResponse => {
          // console.log(apiResponse);
          this.resultJsonObj = apiResponse;
          // console.log('this.resultJsonObj', this.resultJsonObj);
          // return;


          if (this.resultJsonObj.commandResult.status === 1) {
            if (this.resultJsonObj.commandResult.data.userInfo.IsActive == 1) {
              this.userId = this.resultJsonObj.commandResult.data.userInfo.Id;
              this.userOtp = this.resultJsonObj.commandResult.data.userInfo.Otp;
              this.showLoginForm = false;
              this.showVerifyLoginOtpForm = true;
            } else {
              this.inactiveUserErrorMessage = true;
            }
          } else {
            this.formErrorMessage = true;
          }
        },
        err => {
          // console.log(err);
        }
      );
  }

  verifyOtp(verifyLoginOtpFormValue) {
    this.verifyLoginOtpFormSubmitted = true;

    // stop here if form is invalid
    if (this.verifyLoginOtpForm.invalid) {
      return;
    }

    if (
      this.verifyLoginOtpForm.value.otp.toString().trim() !=
      this.userOtp.toString().trim()
    ) {
      this.verifyLoginFormErrorMessage = true;
    } else {
      const httpHeaders = new HttpHeaders({
        // "Content-Type": "application/x-www-form-urlencoded"
        'Content-Type': 'application/json'
      });
      const options = {
        headers: httpHeaders
      };
      const finalData = {
        otp: this.verifyLoginOtpForm.value.otp,
        id: this.userId,
        user_role_id: ConstantHelper.USER_ROLE_DOCTOR,
      };
      // console.log('doctor login verify', finalData);

      return this.http
        .post(
          this.appConfig.getLambdaUrl01() +
          ApiHelper.name_verifyAdminLoginOtpApi,
          finalData,
          options
        )
        .subscribe(
          apiResponse => {
            // console.log(apiResponse);
            this.resultJsonObj = apiResponse;
            // console.log('this.resultJsonObj', this.resultJsonObj);
            //           return;

            if (this.resultJsonObj.commandResult.status == 1) {
              this.userId = this.resultJsonObj.commandResult.data.userInfo.RecordId;
              // console.log(this.resultJsonObj.commandResult.data.userInfo);
              localStorage.setItem(
                'loggedInUserName',
                this.resultJsonObj.commandResult.data.userInfo.FullName
              );

              localStorage.setItem(
                'loggedInUserRoleId',
                this.resultJsonObj.commandResult.data.userInfo.UserRoleId
              );

              localStorage.setItem(
                'loggedInUserComapnyId',
                this.resultJsonObj.commandResult.data.userInfo.ComapnyId
              );

              if (this.pId && this.pId.length > 0) {
                // this.router.navigate(['/admin/user-docs/' + this.pId + '/' + this.userId]);
                this.router.navigate(['/admin/user-docs-acknowledgement/' + this.pId + '/' + this.userId + '/review/' + this.userId]);
                // redirect to physician/admin signature page
              } else if (this.pUserId && this.pUserId.length > 0) {
                // alert(this.pId);
                // this.router.navigate(['/admin/user-sign-docs/' + this.pUserId + '/' + this.docId]);
                this.router.navigate(['/admin/user-sign-acknowledgement/' + this.pUserId + '/' + this.docId + '/sign/' + this.userId]);
                //
                // redirect to physician/admin signature page

              } else {
                this.router.navigate(['/admin/dashboard/' + this.userId]);
              }


            } else {
              this.verifyLoginFormErrorMessage = true;
            }
          },
          err => {
            // console.log(err);
          }
        );
    }

    // this.authService.createRegistration(this.registrationForm.value);
    // this.router.navigate(["/demographicsform"]);
  }

  renderReCaptch() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      sitekey: ConstantHelper.RECAPTCHA_SITE_KEY_CLIENT,
      callback: response => {
        // console.log(
        //   this.appConfig.getLambdaUrl01() + ApiHelper.name_verifyRecaptachaApi
        // );
        // console.log(response);
        this.loginForm.get('recaptchaValue').setValue(response);

        ///////////////////recaptcha validation /////////////////
        const httpHeaders = new HttpHeaders({
          'Content-Type': 'application/json'
        });
        const options = {
          headers: httpHeaders
        };
        const finalData = {
          recaptchaResponse: response
        };
        return this.http
          .post(
            this.appConfig.getLambdaUrl01() +
            ApiHelper.name_verifyRecaptachaApi,
            finalData,
            options
          )
          .subscribe(
            apiResponse => {
              // console.log(apiResponse);
              this.resultJsonObj = apiResponse;
              // console.log(this.resultJsonObj);

              if (this.resultJsonObj.commandResult.status === 1) {
                this.formRecaptchaErrorMessage = false;
              } else {
                this.formRecaptchaErrorMessage = true;
              }
            },
            err => {
              // console.log(err);
            }
          );
        /////////////////////////////////////////////////////////
      }
    });
  }

  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    };

    (function(d, s, id, obj) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        obj.renderReCaptch();
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src =
        'https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'recaptcha-jssdk', this);
  }

  ngAfterViewInit() {
    this.addRecaptchaScript();
    this.captchScaleFunc();
  }

  onResize(event) {
    this.captchScaleFunc();
  }

  captchScaleFunc() {
    var captchacontainWidth = this.recaptchaElement.nativeElement.clientWidth;
    var scaleValue = captchacontainWidth / 304;
    if (scaleValue < 1) {
      this.recaptchaElement.nativeElement.style.transform =
        'scale(' + scaleValue + ')';
      this.recaptchaElement.nativeElement.style.transformOrigin = '0';
    }
    // console.log(captchacontainWidth);
  }

  goToDoctorRegister() {
    if (this.pId && this.pId.length > 0) {
      this.router.navigate(['/doctor/register/' + this.pId + '/' + this.docEmail]);
    } else {
      // alert('sdfds')
      this.router.navigate(['/doctor/register']);
    }

  }


  checkExistingDoc() {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const options = {
      headers: httpHeaders
    };
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      email: this.docEmail,
      user_role_id: ConstantHelper.USER_ROLE_DOCTOR,
    };
    // console.log('doctor login', finalData);
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findExistingDocApi,
        finalData,
        options
      )
      .subscribe(
       async apiResponse => {
          // console.log(apiResponse);
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.status === 1) {


          } else {
            //alert('Please signup first.');
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.doctorLogin.signup
            );
            this.router.navigate(['/doctor/register/' + this.pId + '/' + this.docEmail]);
          }
        },
        err => {
          // console.log(err);
        }
      );
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }


}

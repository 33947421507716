import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  HostListener
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from '@angular/common/http';

import {AuthService} from '../auth.service';
import {MustMatch} from '../../_helpers/matchpassword';
import {environment} from './../../../environments/environment';
import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';
import {AppConfig} from './../../../app/app.config';
import {ConstantHelper} from './../../_helpers/constant.helper';
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { async } from '@angular/core/testing';



@Component({
  selector: 'app-doctor-signup',
  templateUrl: './doctor-signup.component.html',
  styleUrls: ['./doctor-signup.component.scss'],
  providers: [AppConfig, CommonHelper]
})
export class DoctorSignupComponent implements OnInit {


  @ViewChild('recaptcha', {static: false}) recaptchaElement: ElementRef;
  @HostListener('window:resize', ['$event'])
  registrationForm: FormGroup;
  registrationFormSubmitted = false;
  showRegistrationForm = true;
  registrationEmailTaken = false;
  registrationMobileTaken = false;
  verifyRegistrationFormMessage = false;
  verifyRegistrationFormErrorMessage = false;
  verifyRegistrationOtpForm: FormGroup;
  verifyRegistrationOtpFormSubmitted = false;
  showVerifyRegistrationOtpForm = false;
  tempUserId: number;
  tempUserOtp: string;
  userOtp: number;
  userId: number;
  userEmail: string;
  userMobile: string;
  userRoleId: number;
  resultJsonObj: any = {};
  npiJsonObj: any = {};
  formRecaptchaErrorMessage = false;
  firstQuesLength = false;
  firstQuesList: any;
  specialities: any = [];
  secondQuesLength = false;
  secondQuesList: any;
  isDisabled = true;
  statesArre1: any = [
    {StateCode: 'AL', State: 'Alabama'},
    {StateCode: 'AK', State: 'Alaska'},
    {StateCode: 'AZ', State: 'Arizona'},
    {StateCode: 'AR', State: 'Arkansas'},
    {StateCode: 'CA', State: 'California'},
    {StateCode: 'CO', State: 'Colorado'},
    {StateCode: 'CT', State: 'Connecticut'},
    {StateCode: 'DE', State: 'Delaware'},
    {StateCode: 'FL', State: 'Florida'},
    {StateCode: 'GA', State: 'Georgia'},
    {StateCode: 'HI', State: 'Hawaii'},
    {StateCode: 'ID', State: 'Idaho'},
    {StateCode: 'IL', State: 'Illinois'},
    {StateCode: 'IN', State: 'Indiana'},
    {StateCode: 'IA', State: 'Iowa'},

    {StateCode: 'KS', State: 'Kansas'},
    {StateCode: 'KY', State: 'Kentucky'},
    {StateCode: 'LA', State: 'Louisiana'},
    {StateCode: 'ME', State: 'Maine'},
    {StateCode: 'MD', State: 'Maryland'},
    {StateCode: 'MA', State: 'Massachusetts'},

    {StateCode: 'MI', State: 'Michigan'},
    {StateCode: 'MN', State: 'Minnesota'},
    {StateCode: 'MS', State: 'Mississippi'},
    {StateCode: 'MO', State: 'Missouri'},
    {StateCode: 'MT', State: 'Montana'},
    {StateCode: 'NE', State: 'Nebraska'},

    {StateCode: 'NV', State: 'Nevada'},
    {StateCode: 'NH', State: 'New Hampshire'},
    {StateCode: 'NJ', State: 'New Jersey'},
    {StateCode: 'NM', State: 'New Mexico'},
    {StateCode: 'NY', State: 'New York'},
    {StateCode: 'NC', State: 'North Carolina'},

    {StateCode: 'ND', State: 'North Dakota'},
    {StateCode: 'OH', State: 'Ohio'},
    {StateCode: 'OK', State: 'Oklahoma'},
    {StateCode: 'OR', State: 'Oregon'},
    {StateCode: 'PA', State: 'Pennsylvania'},
    {StateCode: 'PR', State: 'Puerto Rico'},

    {StateCode: 'RI', State: 'Rhode Island'},
    {StateCode: 'SC', State: 'South Carolina'},
    {StateCode: 'SD', State: 'South Dakota'},
    {StateCode: 'TN', State: 'Tennessee'},
    {StateCode: 'TX', State: 'Texas'},
    {StateCode: 'UT', State: 'Utah'},

    {StateCode: 'VT', State: 'Vermont'},
    {StateCode: 'VA', State: 'Virginia'},
    {StateCode: 'WA', State: 'Washington'},
    {StateCode: 'WV', State: 'West Virginia'},
    {StateCode: 'WI', State: 'Wisconsin'},
    {StateCode: 'WI', State: 'Wisconsin'}
  ];

  registrationFormMessage = false;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  httpOptions = {
    headers: this.httpHeaders
  };
  buttonDisabled = true;
  fullName: any;
  pId: any = '';
  docEmail: any = '';
  fieldTextType: boolean;
  cnFieldTextType: boolean;
  message = Message;
  constructor(public commonHelper: CommonHelper,
              private appConfig: AppConfig,
              private formBuilder: FormBuilder,
              private http: HttpClient,
              private authService: AuthService,
              private router: Router,
              public sweetAlertHelper: SweetAlertHelper,
              private activatedRoute: ActivatedRoute) {

  }

  ngOnInit() {
    this.pId = this.activatedRoute.snapshot.paramMap.get('pId');
    this.docEmail = this.activatedRoute.snapshot.paramMap.get('docEmail');
    this.addRecaptchaScript();
    this.registrationForm = this.formBuilder.group(
      {
        firstName: ['', Validators.required],
        middleName: [''],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        confirmEmail: ['', [Validators.required, Validators.email]],
        mobile: ['', [Validators.required, Validators.minLength(10)]],
        confirmMobile: ['', Validators.required],
        // tslint:disable-next-line: max-line-length
        password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$#@$!%*?&])[A-Za-z\d$#@$!%*?&].{7,}')]],
        confirmPassword: ['', Validators.required],
        que1Id: ['', Validators.required],
        que2Id: ['', Validators.required],
        ans1: ['', Validators.required],
        ans2: ['', Validators.required],
        npiNo: ['', Validators.required],
        confirmNpiNo: ['', Validators.required],
        npiName: [{value: '', disabled: this.isDisabled}],
        npiEmail: [{value: '', disabled: this.isDisabled}, [Validators.required, Validators.email]],
        practiceName: [{value: '', disabled: this.isDisabled}, Validators.required],
        officeState: [{value: '', disabled: this.isDisabled}, Validators.required],
        officeCity: [{value: '', disabled: this.isDisabled}, Validators.required],
        officeAddress: [{value: '', disabled: this.isDisabled}, Validators.required],
        streeAddOne: [{value: '', disabled: this.isDisabled}, Validators.required],
        streeAddTwo: [{value: '', disabled: this.isDisabled}],
        zipCode: [{value: '', disabled: this.isDisabled}, Validators.required],
        officePhone: [{value: '', disabled: this.isDisabled}, Validators.required],
        degree: [{value: '', disabled: this.isDisabled}, Validators.required],
        specialtiy: [{value: '', disabled: this.isDisabled}, Validators.required],
        subSpecialtiy: [{value: '', disabled: this.isDisabled}, Validators.required],
        primaryState: [{value: '', disabled: this.isDisabled}, Validators.required],
        primaryStateLicense: [{value: '', disabled: this.isDisabled}, Validators.required],
        confirmPrimaryStateLicense: [{value: '', disabled: this.isDisabled}, Validators.required],
        secondryState: [{value: '', disabled: this.isDisabled}, Validators.required],
        secondryStateLicense: [{value: '', disabled: this.isDisabled}, Validators.required],
        confirmSecondryStateLicense: [{value: '', disabled: this.isDisabled}, Validators.required],
        recaptchaValue: [{value: '', disabled: this.isDisabled}, Validators.required],
        npiId: ['0', Validators.required],

      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );

    this.verifyRegistrationOtpForm = this.formBuilder.group({
      otp: ['', Validators.required]
    });

    // tslint:disable-next-line: max-line-length
    /////////////////////////////////////////////////////// Second Questions List Starts /////////////////////////////////////////////////////
    const finalDataS = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 2,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataS,
        this.httpOptions
      )
      .subscribe(
        (apiResponseSQ: any) => {
          this.resultJsonObj = apiResponseSQ;
          if (this.resultJsonObj.commandResult.data.questionsList.length > 0) {
            this.secondQuesLength = true;
            this.secondQuesList = this.resultJsonObj.commandResult.data.questionsList;
            // console.log('-------secondQuesList-------');
            // console.log(this.secondQuesList);
          } else {
            this.firstQuesLength = false;
            this.secondQuesLength = false;
          }
        },
        err => {
          // console.log(err);
        }
      );

    // tslint:disable-next-line: max-line-length
    /////////////////////////////////////////////////////// Second Questions List Ends /////////////////////////////////////////////////////


    // tslint:disable-next-line: max-line-length
    /////////////////////////////////////////////////////// First Questions List Starts /////////////////////////////////////////////////////
    const finalDataF = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 1,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataF,
        this.httpOptions
      )
      .subscribe(
        (apiResponseFQ: any) => {
          this.resultJsonObj = apiResponseFQ;
          if (this.resultJsonObj.commandResult.data.questionsList.length > 0) {
            this.firstQuesLength = true;
            this.firstQuesList = this.resultJsonObj.commandResult.data.questionsList;
            // console.log('-------firstQuesList-------');
            // console.log(this.firstQuesList);
          } else {
            this.firstQuesLength = false;
          }
        },
        err => {
          // console.log(err);
        }
      );

    // tslint:disable-next-line: max-line-length
    /////////////////////////////////////////////////////// First Questions List Ends /////////////////////////////////////////////////////


  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registrationForm.controls;
  }

  get v() {
    return this.verifyRegistrationOtpForm.controls;
  }

  onSubmitaa() {

    // console.log(this.registrationForm.value);
    this.registrationFormSubmitted = true;
    if (this.registrationForm.invalid) {
      return;
    }

    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      email: this.registrationForm.value.email,
      mobile: this.registrationForm.value.mobile,
      // tslint:disable-next-line: max-line-length
      fullName: this.registrationForm.value.firstName + ' ' + this.registrationForm.value.middleName + ' ' + this.registrationForm.value.lastName,
      password: this.registrationForm.value.password,
      que1Id: this.registrationForm.value.que1Id,
      que2Id: this.registrationForm.value.que2Id,
      ans1: this.registrationForm.value.ans1,
      ans2: this.registrationForm.value.ans2,
      user_role_id: ConstantHelper.USER_ROLE_DOCTOR
    };

    // console.log(finalData);

    // return;

    return this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_registrationApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        apiResponse => {
          // console.log(apiResponse);
          this.resultJsonObj = apiResponse;

          if (this.resultJsonObj.commandResult.status == 1) {
            this.tempUserOtp = this.resultJsonObj.commandResult.data.tempUserInfo.tempOtp;
            this.tempUserId = this.resultJsonObj.commandResult.data.tempUserInfo.tempId;

            //
            // const final1Data = {
            //   email: this.registrationForm.value.email,
            //   otp: this.resultJsonObj.commandResult.data.tempUserInfo.tempOtp
            // };
            //
            // this.http
            //   .post(this.appConfig.getPhpUrl() + ApiHelper.php_sendOtpApi, final1Data, options)
            //   .subscribe(apiResponse1 => {
            // console.log(apiResponse1);
            //
            //   });
            this.showRegistrationForm = false;
            this.showVerifyRegistrationOtpForm = true;
          } else if (this.resultJsonObj.commandResult.status == -1) {
            this.registrationEmailTaken = false;
            this.registrationMobileTaken = true;
          } else if (this.resultJsonObj.commandResult.status == -2) {
            this.registrationEmailTaken = true;
            this.registrationMobileTaken = false;
          }
        },
        err => {
          // console.log(err);
        }
      );

    // this.authService.createRegistration(this.registrationForm.value);
    // this.router.navigate(["/demographicsform"]);
  }

  verifyOtp() {
    this.verifyRegistrationOtpFormSubmitted = true;

    // stop here if form is invalid
    if (this.verifyRegistrationOtpForm.invalid) {
      return;
    }

    // console.log(this.verifyRegistrationOtpForm.value.otp);
    // console.log(this.tempUserOtp);

    if (this.verifyRegistrationOtpForm.value.otp != this.tempUserOtp) {
      this.verifyRegistrationFormErrorMessage = true;
    } else {

      const finalData = {
        otp: this.verifyRegistrationOtpForm.value.otp,
        id: this.tempUserId
      };

      return this.http
        .post(
          this.appConfig.getLambdaUrl03() +
          ApiHelper.name_verifyDoctorRegistrationOtpApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          apiResponse => {
            this.resultJsonObj = apiResponse;
            // console.log(this.resultJsonObj);
            // return;
            if (this.resultJsonObj.commandResult.status === 1) {
              /////////////send mail//////////////
              const finalDataEmail = {
                name: this.resultJsonObj.commandResult.data.userInfo.FullName,
                email: this.resultJsonObj.commandResult.data.userInfo.Email,
                company_slug: ConstantHelper.COMPANY_SLUG,
              };

              this.http
                .post(
                  this.appConfig.getPhpUrl() + ApiHelper.php_sendDoctorSignUpMainApi,
                  finalDataEmail,
                  this.httpOptions
                )
                .subscribe(
                  (mailResponse: any) => {
                    localStorage.setItem('loggedInUserName', this.resultJsonObj.commandResult.data.userInfo.FullName);
                    localStorage.setItem('loggedInUserEmail', this.resultJsonObj.commandResult.data.userInfo.Email);
                    localStorage.setItem('loggedInUserRoleId', this.resultJsonObj.commandResult.data.userInfo.UserRoleId);
                    localStorage.setItem(
                      'loggedInUserComapnyId',
                      this.resultJsonObj.commandResult.data.userInfo.ComapnyId
                    );

                    this.userId = this.resultJsonObj.commandResult.data.userInfo.RecordId;
                    if (
                      this.resultJsonObj.commandResult.data.userInfo.UserRoleId == ConstantHelper.USER_ROLE_USER
                    ) {
                      this.router.navigate(['/dashboard/' + this.userId]);
                    }


                    if (
                      this.resultJsonObj.commandResult.data.userInfo.UserRoleId == ConstantHelper.USER_ROLE_ADMIN
                    ) {
                      this.router.navigate(['/admin/dashboard/' + this.userId]);
                    }

                    if (
                      this.resultJsonObj.commandResult.data.userInfo.UserRoleId == ConstantHelper.USER_ROLE_DOCTOR
                    ) {

                      if (this.pId && this.pId.length > 0) {
                        this.router.navigate(['/admin/user-docs/' + this.pId + '/' + this.userId]);
                      } else {
                        this.router.navigate(['/admin/dashboard/' + this.userId]);
                      }

                    }


                  },
                  err => {
                    // console.log(err);
                  }
                );


              ////////////////


            } else {
              this.verifyRegistrationFormMessage = true;
            }
          },
          err => {
            // console.log(err);
          }
        );
    }

    // this.authService.createRegistration(this.registrationForm.value);
    // this.router.navigate(["/demographicsform"]);
  }

  renderReCaptch() {
    setTimeout(() => {
      window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
        sitekey: ConstantHelper.RECAPTCHA_SITE_KEY_CLIENT,
        callback: response => {
          // console.log(response);

          // console.log(response);
          this.registrationForm.get('recaptchaValue').setValue(response);

          ///////////////////recaptcha validation /////////////////
          let httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
          });
          let options = {
            headers: httpHeaders
          };
          const finalData = {
            recaptchaResponse: response
          };
          return this.http
            .post(
              this.appConfig.getLambdaUrl01() +
              ApiHelper.name_verifyRecaptachaApi,
              finalData,
              options
            )
            .subscribe(
              apiResponse => {
                // console.log(apiResponse);
                this.resultJsonObj = apiResponse;
                // console.log(this.resultJsonObj);

                if (this.resultJsonObj.commandResult.status === 1) {
                  this.formRecaptchaErrorMessage = false;
                } else {
                  this.formRecaptchaErrorMessage = true;
                }
              },
              err => {
                // console.log(err);
              }
            );
          /////////////////////////////////////////////////////////
        }
      });
    }, 500);
  }


  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    };

    (function(d, s, id, obj) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        obj.renderReCaptch();
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src =
        'https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'recaptcha-jssdk', this);
  }

  ngAfterViewInit() {
    this.addRecaptchaScript();
    this.captchScaleFunc();
  }

  onResize(event) {
    this.captchScaleFunc();
  }

  captchScaleFunc() {
    var captchacontainWidth = this.recaptchaElement.nativeElement.clientWidth;
    var scaleValue = captchacontainWidth / 304;
    if (scaleValue < 1) {
      this.recaptchaElement.nativeElement.style.transform =
        'scale(' + scaleValue + ')';
      this.recaptchaElement.nativeElement.style.transformOrigin = '0';
    }
    // console.log(captchacontainWidth);
  }

  validateNumber(event) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }
  }


 async getNPIDetails(formValue) {
    // console.log('getNPIDetails');
    if (formValue.npiNo) {

      if (formValue.npiNo != formValue.confirmNpiNo) {
        //alert('NPI no. must match with confirm NPI.');
        const alertStatus = await this.sweetAlertHelper.alertPopUp(
          this.message.alertMessages.doctorSignComp.npino
        );
        return;
      }
      this.registrationForm.enable();


      let finalData = {
        doctorNPI: formValue.npiNo,
      };
      this.http
        .post(
          this.appConfig.getLambdaUrl03() + ApiHelper.name_getNIPDetailsApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async(apiResponse: any) => {
            // console.log('npiJsonObj', apiResponse);

            if (apiResponse.commandResult.status == 0) {
              //alert('NPI details not found. Please enter all the data below so that we can create a new NPI record.');
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.doctorSignComp.npidetails
              );
              window['grecaptcha'].reset();
            } else {
              //alert('Please verify the NPI data which is currently in our database and please enter any corrections, as needed.');
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.doctorSignComp.npiverify
              );
              this.npiJsonObj = apiResponse.commandResult.data.npiInfo;

              window['grecaptcha'].reset();
            }
            this.getNPISecSpecialities(formValue.npiNo, formValue, this.npiJsonObj);

          },
          err => {
            // console.log(err);
          }
        );


    } else {
      //alert('Please provide a valid NPI number.');
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.doctorSignComp.npivalid
      );
    }
  }


  getNPISecSpecialities(npiNo, formValue, npiJsonObj) {
    const finalData = {
      npi: npiNo,
    };
    this.http.post(this.appConfig.getPhpUrl() + ApiHelper.php_findSecondarySpeByNPI, finalData, this.httpOptions)
      .subscribe(
        (apiResponse: any) => {
          this.specialities = apiResponse.commandResult.data.Specializations;
          // console.log(this.specialities);


          this.registrationForm.setValue({
            npiId: npiJsonObj.Id,
            firstName: formValue.firstName,
            middleName: formValue.middleName,
            lastName: formValue.lastName,
            mobile: formValue.mobile,
            confirmMobile: formValue.confirmMobile,
            email: formValue.email,
            confirmEmail: formValue.confirmEmail,
            password: formValue.password,
            confirmPassword: formValue.confirmPassword,
            que1Id: formValue.que1Id,
            que2Id: formValue.que2Id,
            ans1: formValue.ans1,
            ans2: formValue.ans2,
            npiNo: formValue.npiNo,
            confirmNpiNo: formValue.confirmNpiNo,
            npiName: npiJsonObj.PhysicianName ? npiJsonObj.PhysicianName : '',
            npiEmail: npiJsonObj.PhysicianEmail,
            practiceName: npiJsonObj.PracticeName,
            officeState: npiJsonObj.PhysicianState,
            officeCity: npiJsonObj.PhysicianCity,
            officeAddress: npiJsonObj.PhysicianOfficeAddress,
            streeAddOne: npiJsonObj.PhysicianStreet01,
            streeAddTwo: npiJsonObj.PhysicianStreet02,
            zipCode: npiJsonObj.PhysicianZipCode,
            officePhone: npiJsonObj.PhysicianOfficePhone,
            degree: npiJsonObj.PhysicianDegree,
            specialtiy: npiJsonObj.PhysicianSpecialty,
            subSpecialtiy: this.specialities[0] ? this.specialities[0].secondaryspecialization : '',
            primaryState: npiJsonObj.PhysicianPrimaryState,
            primaryStateLicense: npiJsonObj.PhysicianPrimaryStateLicense,
            confirmPrimaryStateLicense: '',
            secondryState: this.specialities[0] ? this.specialities[0].secondarylicensestate : '',
            secondryStateLicense: this.specialities[0] ? this.specialities[0].secondary_license : '',
            confirmSecondryStateLicense: '',
            recaptchaValue: '',
          });


        },
        err => {
          // console.log(err);
        }
      );
  }

  async onSubmit() {

    // console.log(this.registrationForm.value);
    this.registrationFormSubmitted = true;
    if (this.registrationForm.invalid) {
      return;
    }

    if (this.registrationForm.value.email != this.registrationForm.value.confirmEmail) {
      //alert('Email must match with confirm email.');
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.doctorSignComp.doctorEmail
      );
      return;
    }


    // console.log('mobile', this.registrationForm.value.mobile);
    // console.log('confirmMobile', this.registrationForm.value.confirmMobile);
    if (this.registrationForm.value.mobile !== this.registrationForm.value.confirmMobile) {
      //alert('Mobile must match with confirm mobile.');
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.doctorSignComp.doctorMobile
      );
      return;
    }
    if (this.registrationForm.value.npiNo != this.registrationForm.value.confirmNpiNo) {
      //alert('NPI no. must match with confirm NPI.');
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.doctorSignComp.doctorNPI
      );
      return;
    }


    if (this.registrationForm.value.primaryStateLicense != this.registrationForm.value.confirmPrimaryStateLicense) {
      //alert('Primary state license must match with confirm primary state license.');
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.doctorSignComp.doctorPrimaryState
      );
      return;
    }


    if (this.registrationForm.value.secondryStateLicense != this.registrationForm.value.confirmSecondryStateLicense) {
      //alert('Secondary state license must match with confirm secondary state license.');
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.doctorSignComp.doctorSecondaryState
      );
      return;
    }


    if (this.registrationForm.value.primaryStateLicense != this.registrationForm.value.confirmPrimaryStateLicense) {
     // alert('Primary State License not confirmed.');
     const alertStatus = await this.sweetAlertHelper.alertPopUp(
      this.message.alertMessages.doctorSignComp.doctorPrimaryStateLincense
    );
      return;
    }


    if (this.registrationForm.value.secondryStateLicense != this.registrationForm.value.confirmSecondryStateLicense) {
      //alert('Secondray State License not confirmed.');
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.doctorSignComp.doctorSecondaryStateLincense
      );
      return;
    }


    const finalData = {
      pId: this.pId,
      docEmail: this.docEmail,
      company_slug: ConstantHelper.COMPANY_SLUG,
      email: this.registrationForm.value.email,
      mobile: this.registrationForm.value.mobile,
      // tslint:disable-next-line: max-line-length
      fullName: this.registrationForm.value.firstName + ' ' + this.registrationForm.value.middleName + ' ' + this.registrationForm.value.lastName,
      password: this.registrationForm.value.password,
      que1Id: this.registrationForm.value.que1Id,
      que2Id: this.registrationForm.value.que2Id,
      ans1: this.registrationForm.value.ans1,
      ans2: this.registrationForm.value.ans2,
      user_role_id: ConstantHelper.USER_ROLE_DOCTOR,
      firstName: this.registrationForm.value.firstName,
      middleName: this.registrationForm.value.middleName,
      lastName: this.registrationForm.value.lastName,
      npiId: this.registrationForm.value.npiId,
      officeState: this.registrationForm.value.officeState,
      officeCity: this.registrationForm.value.officeCity,
      officeAddress: this.registrationForm.value.officeAddress,
      streeAddOne: this.registrationForm.value.streeAddOne,
      streeAddTwo: this.registrationForm.value.streeAddTwo,
      zipCode: this.registrationForm.value.zipCode,
      officePhone: this.registrationForm.value.officePhone,
      degree: this.registrationForm.value.degree,
      npiNo: this.registrationForm.value.npiNo,
      practiceName: this.registrationForm.value.practiceName,
      specialtiy: this.registrationForm.value.specialtiy,
      subSpecialtiy: this.registrationForm.value.subSpecialtiy,
      primaryState: this.registrationForm.value.primaryState,
      primaryStateLicense: this.registrationForm.value.primaryStateLicense,
      secondryState: this.registrationForm.value.secondryState,
      secondryStateLicense: this.registrationForm.value.secondryStateLicense,
    };
    // console.log(finalData);
    // return;
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_doctorRregistrationApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
       async  apiResponse => {
          // console.log(apiResponse);
          this.resultJsonObj = apiResponse;

          if (this.resultJsonObj.commandResult.status == 1) {
            this.tempUserOtp = this.resultJsonObj.commandResult.data.tempUserInfo.tempOtp;
            this.tempUserId = this.resultJsonObj.commandResult.data.tempUserInfo.tempId;
            this.showRegistrationForm = false;
            this.showVerifyRegistrationOtpForm = true;
          } else if (this.resultJsonObj.commandResult.status == -1) {
            this.registrationEmailTaken = false;
            this.registrationMobileTaken = true;
          } else if (this.resultJsonObj.commandResult.status == -2) {
            this.registrationEmailTaken = true;
            this.registrationMobileTaken = false;
          } else if (this.resultJsonObj.commandResult.status == -3) {
            //alert('Wrong email provided');
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.doctorSignComp.doctorEmailVerify
            );
          }
        },
        err => {
          // console.log(err);
        }
      );
  }


  goToDoctorTogin() {
    if (this.pId && this.pId.length > 0) {
      this.router.navigate(['/showMail/' + this.pId + '/' + this.docEmail]);
    } else {
      this.router.navigate(['/doctor/login']);
    }
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleCnFieldTextType() {
    this.cnFieldTextType = !this.cnFieldTextType;
  }
}

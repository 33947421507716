import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { from } from "rxjs";
import { DatePipe } from "@angular/common";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { SignaturePad } from "angular2-signaturepad";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { UserService } from "./../../_services/user.service";
import * as moment from "moment";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { NjComponent } from "../nj/nj.component";

@Component({
  selector: "app-ca",
  templateUrl: "./ca.component.html",
  styleUrls: ["./ca.component.scss"],
})
export class CaComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  hippaJson: any = {};
  loggedInUserId: string;
  // userSignatures: any = [];
  // userSignatureSelected = false;
  // userChoosedSignature = "";
  isEmail: any;
  isPracticePhy: any;
  physicianId: any;
  requestId: any;
  tempId: any;
  userOptionId: any;
  emailLogId: any;
  // userFullSignature = "";
  // userInitialSignature = "";
  // userFullSignatureExit = false;
  // userInitialSignatureExis = false;
  showPdfModal = false;
  //signatureType: any;
  ssnPattern = "^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$";
  resultJsonObj: any = {};
  clean: string = "0";
  // @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  // @ViewChild("canvasArea", { static: true }) canvasArea: ElementRef;
  // canvasElm: any;
  // public signaturePadOptions: Object = {
  //   minWidth: 1,
  //   canvasWidth:
  //     window.innerWidth < 1024 ? window.innerWidth : window.innerWidth / 2,
  //   canvasHeight: 450,
  // };
  currentDate = new Date();
  dateFormat = "MM-dd-yyyy";
  // firstWitnessSignatureSelected = false;
  // firstWitnessChoosedSignature = "";
  // secondWitnessSignatureSelected = false;
  // secondWitnessChoosedSignature = "";
  showLoader = false;

  signatureFroWhom: any;
  message = Message;
  patientId: string;
  fileDesTxt: any = '';
  selectedDelegateId: any;
  assignedDelegateId: any;
  isDelegated: any = 0;
  selectedDocUrl: any;
  selectedPhysician: any = {};
  docInfo: any = {};
  selectedStateCode: any;
  childRoute: string;
  patient_name: any;
  physicianName: any;
  PhysicianOfficeAddress: any;  
  patient_city:any;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public userService: UserService
  ) {
    this.loggedInUserId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.isEmail = this.activatedRoute.snapshot.paramMap.get("isEmail")
      ? this.activatedRoute.snapshot.paramMap.get("isEmail")
      : 0;
    this.isPracticePhy =
      this.activatedRoute.snapshot.paramMap.get("isPracticePhy");
    this.physicianId = this.activatedRoute.snapshot.paramMap.get("physicianId")
      ? this.activatedRoute.snapshot.paramMap.get("physicianId")
      : 0;
    this.requestId = this.activatedRoute.snapshot.paramMap.get("requestId")
      ? this.activatedRoute.snapshot.paramMap.get("requestId")
      : 0;
    this.tempId = this.activatedRoute.snapshot.paramMap.get("tempId")
      ? this.activatedRoute.snapshot.paramMap.get("tempId")
      : 0;
    this.userOptionId = this.activatedRoute.snapshot.paramMap.get(
      "userOptionId"
    )
      ? this.activatedRoute.snapshot.paramMap.get("userOptionId")
      : 0;
    this.emailLogId = this.activatedRoute.snapshot.paramMap.get("emailLogId")
      ? this.activatedRoute.snapshot.paramMap.get("emailLogId")
      : 0;
    this.hippaJson.healthcare_provider = this.userService.getUS_FromatName(
      localStorage.getItem("providerName")
    );
    this.hippaJson.healthcare_provider_email =
      localStorage.getItem("providerEmail");
    this.hippaJson.healthcare_provider_npi = localStorage.getItem(
      "providerPhysicianNipNo"
    );
    this.hippaJson.patient_name = this.userService.getUS_FromatName(
      localStorage.getItem("loggedInUserName")
    );
    this.hippaJson.healthcare_provider_address = localStorage.getItem(
      "providerOfficeAddress"
    );
    this.hippaJson.healthcare_provider_phone =
      localStorage.getItem("providerPhone");
   // this.fnFindSignature(this.loggedInUserId);
   
    this.fnFindDemographic(this.patientId);
    this.findUserDetails(this.patientId);
    this.hippaJson.sign_date = this.currentDate;
    this.hippaJson.healthcare_provider = localStorage.getItem('selectedPhysicianName');
    this.hippaJson.PhysicianOfficeAddress = localStorage.getItem('selectedPhysicianOfficeAddress');
    this.hippaJson.physicianNPI = localStorage.getItem('selectedPhysicianNPI');
    this.hippaJson.physicianEmail = localStorage.getItem('selectedPhysicianEmail');    
    this.hippaJson.physicianPhone=localStorage.getItem('Mobile');
    this.hippaJson.physicianCity = localStorage.getItem('selectedPhysicianCity');
    this.hippaJson.physicianState = localStorage.getItem('selectedPhysicianState');
    this.hippaJson.physicianZip=localStorage.getItem('providerZipCode');
   
  }

  ngOnInit() {
    this.patientId = this.activatedRoute.snapshot.paramMap.get('patientId');
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.hippaJson.check_health_info = false;
    this.hippaJson.check_health_record = false;
    this.hippaJson.check_mental_health = false;
    this.hippaJson.check_hiv_test = false;
    this.hippaJson.check_alcohol = false;
    this.hippaJson.check_patient_purpose = false;
    this.hippaJson.check_other_purpose = false;
    this.hippaJson.patient_signature = "";
    this.findUserDetails(this.patientId);    
    this.hippaJson.physicianName=localStorage.getItem('selectedPhysicianName'),
    this.hippaJson.recieving_doctor_name=localStorage.getItem('selectedPhysicianName'),
    this.hippaJson.person_address = this.hippaJson.PhysicianOfficeAddress;
    this.hippaJson.healthcare_provider_address = this.hippaJson.PhysicianOfficeAddress;
    this.hippaJson.recieving_doctor_address=this.hippaJson.PhysicianOfficeAddress;
    this.hippaJson.city_zip= this.hippaJson.physicianCity + "," + this.hippaJson.physicianState+","+this.hippaJson.physicianZip;
    this.hippaJson.healthcare_provider_npi = localStorage.getItem('selectedPhysicianNPI');
  }
  findUserDetails(patientId) {
    const finalData = {
      company_slug: null,
      id: patientId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            this.hippaJson.patient_name = apiResponse.commandResult.data.userInfo.LastName + ', ' + apiResponse.commandResult.data.userInfo.FirstName;
            this.hippaJson.dob = apiResponse.commandResult.data.userInfo.DOB;
            this.hippaJson.patient_phone=apiResponse.commandResult.data.userInfo.Mobile;
            this.hippaJson.patient_email=apiResponse.commandResult.data.userInfo.Email;
            }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  // fnFindSignature(user_id) {
  //   const reqData = {
  //     user_id: user_id,
  //   };
  //   return this.http
  //     .post(
  //       this.appConfig.getPhpUrl() + ApiHelper.php_findUserSignatureApi,
  //       reqData,
  //       this.httpOptions
  //     )
  //     .subscribe(
  //       (response: any) => {
  //         if (response.commandResult.success == 1) {
  //           this.userSignatures = response.commandResult.data.userSignatures;
  //           this.userFullSignature =
  //             response.commandResult.data.userSignatures.full_signature_url;
  //           this.userInitialSignature =
  //             response.commandResult.data.userSignatures.initial_signature_url;
  //           if (response.commandResult.data.userSignatures.full_signature_url) {
  //             this.userFullSignatureExit = true;
  //           }

  //           if (
  //             response.commandResult.data.userSignatures.initial_signature_url
  //           ) {
  //             this.userInitialSignatureExis = true;
  //           }
  //         } else {
  //         }
  //       },
  //       (err) => {}
  //     );
  // }

  async onSubmit() {
    // if (confirm('PLEASE CONFIRM:   By clicking ‘OK’:The information you have entered will be used to electronically generate a completed State HIPAA Form.'))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.onSubmit
    );
    if(alertStatus){
      const finalData = {
        selectedDocUrl: this.selectedDocUrl,
        fileDesTxt: this.fileDesTxt,
        loggedInUserId: this.loggedInUserId,
        userId: this.patientId,
        patient_name: this.hippaJson.patient_name,        
        dob: this.hippaJson.dob,  
        patient_address:this.hippaJson.patient_address,    
        patient_city:this.hippaJson.patient_city,
        patient_state:this.hippaJson.patient_state,
        patient_phone:this.hippaJson.patient_phone,
        patient_email:this.hippaJson.patient_email,
        patient_fax:this.hippaJson.patient_fax,        
        physicianNPI: this.hippaJson.PhysicianNipNo,
        healthcare_provider:this.hippaJson.physicianName,        
        person_address: this.hippaJson.person_address, 
        city_zip:this.hippaJson.city_zip,     
        physicianState: this.selectedStateCode,
        assignedDelegateId: this.assignedDelegateId,
        isDelegated: this.isDelegated,
        selectedDelegateId: this.selectedDelegateId,
        selectedEmail: this.selectedPhysician.altEmail,
        selectedPhysician: this.selectedPhysician.physicianName,
        physicianPhone: this.hippaJson.physicianPhone,   
        healthcare_provider_email: this.hippaJson.healthcare_provider_email,
        healthcare_provider_npi: this.hippaJson.healthcare_provider_npi, 
        isEmail: this.isEmail,
        requestId: this.requestId,        
        patient_medical_id: this.hippaJson.patient_medical_id,          
        healthcare_provider_phone: this.hippaJson.healthcare_provider_phone,
        recieving_doctor_name: this.hippaJson.recieving_doctor_name,
        recieving_doctor_address: this.hippaJson.person_address,
        healthcare_provider_address:this.hippaJson.healthcare_provider_address,
        check_health_info: this.hippaJson.check_health_info,
        check_health_record: this.hippaJson.check_health_record,
        health_record_01: this.hippaJson.health_record_01,
        health_record_02: this.hippaJson.health_record_02,
        check_mental_health: this.hippaJson.check_mental_health,
        mental_health_input: this.hippaJson.mental_health_input,
        check_hiv_test: this.hippaJson.check_hiv_test,
        hiv_test_input: this.hippaJson.hiv_test_input,
        check_alcohol: this.hippaJson.check_alcohol,
        alcohol_input: this.hippaJson.alcohol_input,
        over_input: this.hippaJson.over_input,
        check_patient_purpose: this.hippaJson.check_patient_purpose,
        check_other_purpose: this.hippaJson.check_other_purpose,
        purpose_input_01: this.hippaJson.purpose_input_01,
        purpose_input_02: this.hippaJson.purpose_input_02,
        purpose_input_03: this.hippaJson.purpose_input_03,
        limitation_input_01: this.hippaJson.limitation_input_01,
        limitation_input_02: this.hippaJson.limitation_input_02,
        limitation_input_02a: this.hippaJson.limitation_input_02a,
        expiry_date: this.hippaJson.expiry_date ? moment(new Date(this.hippaJson.expiry_date.toString())).format('MM-DD-YYYY') : '',
        sign_date: this.hippaJson.sign_date ? moment(new Date(this.hippaJson.sign_date.toString())).format('MM-DD-YYYY') : '',
        sign_time: this.hippaJson.sign_time,
        patient_relationship: this.hippaJson.patient_relationship
      };
      this.showLoader = true;
      return this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.generateHipaaCA,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            if (this.hippaJson.loggedInUserRoleId == 1) {
              this.router.navigate([
                '/doctor/' + '/demographics/' +
                this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
                '/1/'
              ]);
            } else if (this.hippaJson.loggedInUserRoleId == 4) {
              this.router.navigate([
                '/doctor/' + '/demographics/' +
                this.activatedRoute.snapshot.paramMap.get('loggedInUserId') +
                '/1/'
              ]);
            } else {
              if (window.open(apiResponse.commandResult.data, '_blank')) {
                this.router.navigate([
                  '/doctor/' + '/ushf/' +
                  this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/' + this.activatedRoute.snapshot.paramMap.get('patientId') + '/' + this.activatedRoute.snapshot.paramMap.get('demographicId')
  
                ]);
              }
            }
            this.showLoader =false;
          },
          (err) => {
          }
        );
    }
  }


  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Click ‘OK’ to confirm returning to the ‘Home Page’."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(['/doctor/' +
      '/dashboard/' +
      this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),
    ]);
    }
  }

  async goBack() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the USHF Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.goBack
    );
    if (alertStatus) {
      this.router.navigate([
        '/doctor/' + '/ushf/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId') + '/' + this.activatedRoute.snapshot.paramMap.get('patientId') + '/' + this.activatedRoute.snapshot.paramMap.get('demographicId')

      ]);
    }
  }

  closePdfModal() {
    this.showPdfModal = false;
    this.hippaJson.patient_signature = "";
  }

  // openSignatureCanvas(selectedValue, signatureFroWhom) {
  //   this.signatureFroWhom = signatureFroWhom;

  //   // console.log(selectedValue);
  //   this.signatureType = selectedValue;
  //   if (
  //     selectedValue == "AFS" ||
  //     selectedValue == "AFWS" ||
  //     selectedValue == "ASWS"
  //   ) {
  //     this.showPdfModal = true;
  //   }
  //   if (selectedValue == "FS") {
  //     this.userSignatureSelected = true;
  //     this.userChoosedSignature = this.userFullSignature;
  //   }
  // }

  ngAfterViewInit() {
    // this.signaturePad is now available
    // this.signaturePad.set("minWidth", 1); // set szimek/signature_pad options at runtime
    // this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
  }

  // async saveCanvas() {
  //   // if (
  //   //   confirm(
  //   //     "This will replace your existing signature. Do you wish to change?"
  //   //   )
  //   // )
  //   const alertStatus = await this.sweetAlertHelper.confirmPopUp(
  //     this.message.confirmTitle,
  //     this.message.confirmMessages.njComp.saveCanvas
  //   );
  //   {
  //     const reqData = {
  //       loggedInUserId: this.loggedInUserId,
  //       signatureBase64: this.signaturePad.toDataURL(),
  //       signatureType: this.signatureType,
  //     };
  //     this.showLoader = true;
  //     return this.http
  //       .post(
  //         this.appConfig.getLambdaUrl05() +
  //           ApiHelper.name_saveSignatureImageApi,
  //         reqData,
  //         this.httpOptions
  //       )
  //       .subscribe(
  //         (apiResponse: any) => {
  //           if (apiResponse.commandResult.status == 1) {
  //             this.userSignatureSelected = true;
  //             this.userChoosedSignature =
  //               apiResponse.commandResult.data.fileInfo.signatureUrl;
  //             // console.log(this.userChoosedSignature);
  //             this.fnFindSignature(this.loggedInUserId);
  //             this.showPdfModal = false;
  //             // this.hippaJson.patient_signature = '';
  //           }
  //           this.showLoader = false;
  //         },
  //         (err) => {
  //           // console.log(err);
  //         }
  //       );
  //   }
  // }

//  async  clearCanvas() {
//     // if (
//     //   confirm("PLEASE CONFIRM: Are you sure you want to clear you signature.")
//     // )
//     const alertStatus = await this.sweetAlertHelper.confirmPopUp(
//       this.message.confirmTitle,
//       this.message.confirmMessages.njComp.clearCanvas
//     );
//     {
//       this.signaturePad.clear();
//     }
//   }

  drawStart() {}

  fnFindDemographic(patientId) {
    const finalData = {
      id: patientId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_findUserDemographicGeneralApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (response: any) => {
          // console.log('response=>', response);
          this.hippaJson.dob = new Date(
            response.commandResult.data.userInfo.form1DOB
          );
          this.hippaJson.patient_city =
            response.commandResult.data.userInfo.form1City;
          this.hippaJson.patient_zip =
            response.commandResult.data.userInfo.form1ZIP;
          this.hippaJson.patient_state =
            response.commandResult.data.userInfo.form1State;
          this.hippaJson.patient_address =
            response.commandResult.data.userInfo.form1ResidenceNumber +
            ", " +
            response.commandResult.data.userInfo.form1StreetName +
            ", " +
            response.commandResult.data.userInfo.form1PrimaryPhysicianCity +
            ", " +
            response.commandResult.data.userInfo.form1State +
            ", " +
            response.commandResult.data.userInfo.form1ZIP;
          this.hippaJson.patient_phone =
            response.commandResult.data.userInfo.Mobile;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  autoFormatSSN() {
    // alert('Amit');
    var val = this.hippaJson.social_security_no.replace(/\D/g, "");
    val = val.replace(/^(\d{3})/, "$1-");
    val = val.replace(/-(\d{2})/, "-$1-");
    val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
    this.hippaJson.social_security_no = val;
  }

  autoFormatSSN1() {
    // alert('Amit');
    var val = this.hippaJson.revocation_social_security_no.replace(/\D/g, "");
    val = val.replace(/^(\d{3})/, "$1-");
    val = val.replace(/-(\d{2})/, "-$1-");
    val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
    this.hippaJson.revocation_social_security_no = val;
  }

  openCABlankpdf() {
    window.open("https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/California-HIPAA-Medical-Release-Form.pdf", "_blank")
  }

  // saveParentCanvas(signFor) {
  //   const reqData = {
  //     loggedInUserId: this.loggedInUserId,
  //     signatureBase64: this.signaturePad.toDataURL(),
  //     signatureType: this.signatureType,
  //     signFor: signFor,
  //   };
  //   this.showLoader = true;
  //   return this.http
  //     .post(
  //       this.appConfig.getLambdaUrl04() +
  //         ApiHelper.name_saveParentSignatureImageApi,
  //       reqData,
  //       this.httpOptions
  //     )
  //     .subscribe(
  //       (apiResponse: any) => {
  //         if (apiResponse.commandResult.status == 1) {
  //           if (signFor == "FWS") {
  //             this.firstWitnessSignatureSelected = true;
  //             this.firstWitnessChoosedSignature =
  //               apiResponse.commandResult.data.fileInfo.signatureUrl;
  //           }

  //           if (signFor == "SWS") {
  //             this.secondWitnessSignatureSelected = true;
  //             this.secondWitnessChoosedSignature =
  //               apiResponse.commandResult.data.fileInfo.signatureUrl;
  //           }
  //           this.showPdfModal = false;
  //         }
  //         this.showLoader = false;
  //       },
  //       (err) => {
  //         // console.log(err);
  //       }
  //     );
  // }
}

import { from } from "rxjs";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";

import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";

declare let $: any;

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UploadService } from "../../_services/upload.service";
import { AppConfig } from "./../../../app/app.config";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-uploaddoc",
  templateUrl: "./uploaddoc.component.html",
  styleUrls: ["./uploaddoc.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class UploaddocComponent implements OnInit {
  @ViewChild("fileUpoladed", { static: false }) fileUpoladed: ElementRef;
  loggedInUserId: string;
  demographicId: string;
  resultJsonObj: any = {};
  fileToUpload: File = null;
  fileName: string;
  isHippByAdmin = false;
  docType = ConstantHelper.DOC_TYPE_USER_UPLOADED;
  companySlug = ConstantHelper.COMPANY_SLUG;
  comapnyName: string = ConstantHelper.COMPANY_NAME;

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  @ViewChild("fileLog", { static: false }) fileLog: ElementRef;

  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private uploadService: UploadService,
    private renderer: Renderer2
  ) {}

  isDisableSubmitBtn = false;

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");

    this.demographicId =
      this.activatedRoute.snapshot.paramMap.get("demographicId");

    $(function () {
      $("input[type=file]").change(function () {
        let t = $(this).val();
        let labelText = "File : " + t.substr(12, t.length);
        $(this).prev("label").text(labelText);
      });
    });
  }

  onFileSelected(event) {
    if (this.fileUpoladed.nativeElement.value != "" && this.fileName != "") {
      // console.log(this.fileUpoladed.nativeElement.value);
      this.fileToUpload = event.target.files.item(0) as File;
      this.isDisableSubmitBtn = true;
    } else {
      this.isDisableSubmitBtn = false;
    }
  }

  setFileName(event) {
    this.fileName = event.target.value;

    if (this.fileName != "" && this.fileUpoladed.nativeElement.value != "") {
      this.isDisableSubmitBtn = true;
    } else {
      this.isDisableSubmitBtn = false;
    }
  }

  async uploadFile() {
    let isHippa = 0;

    const fSize = Math.round(this.fileToUpload.size / 1024);
    if (!this.validateFile(this.fileToUpload.name)) {
      // alert("You can only upload .png, .jpg, .jpeg. or .pdf file formats.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4
      );
      return false;
    } else if (fSize > 61440) {
      // alert("File too Big, please select a file less than 60mb");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_5
      );
    } else {
      if (this.isHippByAdmin === true) {
        isHippa = 1;
      } else {
        isHippa = 0;
      }

      //   if (
      //     confirm("PLEASE CONFIRM: Are you sure you want to upload the file?")
      //   )
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.adminUploaddocComp.uploadFile
      );
      {
        // tslint:disable-next-line: max-line-length
        this.uploadService
          .uploadFile(
            this.fileToUpload,
            this.loggedInUserId,
            this.demographicId,
            this.docType,
            this.companySlug,
            localStorage.getItem("loggedInUserComapnyId"),
            this.fileToUpload.name,
            isHippa
          )
          .subscribe(
            async (data: any) => {
              if (data !== undefined) {
                if (data.commandResult.success == 1) {
                  // alert('File Uploaded Successfully!');
                  //   if (
                  //     confirm(
                  //       "PLEASE CONFIRM: Do you want to upload another file? Yes stay on the page. No return to Home page. "
                  //     )
                  //   )
                  const alertStatus =
                    await this.sweetAlertHelper.confirmPopUpwithYesOrNo(
                      this.message.confirmTitle,
                      this.message.confirmMessages.adminUploaddocComp
                        .uploadFile1
                    );
                  if (alertStatus) {
                    window.location.reload();
                  } else {
                    this.router.navigate(["/dashboard/" + this.loggedInUserId]);
                  }
                  // const p: HTMLParagraphElement = this.renderer.createElement('p');
                  // p.innerHTML = this.fileToUpload.name + ' uploaded successfully.';
                  // this.renderer.appendChild(this.fileLog.nativeElement, p);
                  // this.isDisableSubmitBtn = false;
                  // // this.router.navigate(["/dashboard/" + this.loggedInUserId]);
                }
              }
            },
            (error) => {
              // console.log(error);
            }
          );
      }
      if (!alertStatus) {
        this.router.navigate(["/dashboard/" + this.loggedInUserId]);
      }
    }
  }

  async gotToDashboard() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure you want to return to the Home Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(["/dashboard/" + this.loggedInUserId]);
    }
  }

  validateFile(name: String) {
    let ext = name.substring(name.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "pdf"
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkCheckBoxvalue(event) {
    // console.log(event.checked);
  }
}

import {
  AfterViewInit,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import * as React from "react";

import * as ReactDOM from "react-dom";

// import MyComponent from './Mycomponent';
import AHSChatBot from "./AHSChatBot";
@Component({
  selector: "app-chatBot-window",
  template: '<div [id]="rootId"></div>',
  styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnChanges, AfterViewInit, OnDestroy {
  title = "angularreactapp";

  public rootId = "rootId";

  ngOnChanges(changes: SimpleChanges) {
    this.render();
  }

  ngAfterViewInit() {
    this.render();
  }

  ngOnDestroy() {}

  private render() {
    ReactDOM.render(
      React.createElement(AHSChatBot),
      document.getElementById(this.rootId)
    );
  }
}

import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UploadService } from "../../_services/upload.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-admin-email-doc",
  templateUrl: "./admin-email-doc.component.html",
  styleUrls: ["./admin-email-doc.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class AdminEmailDocComponent implements OnInit {
  @ViewChild("fileUploaded", { static: false }) fileUploaded: ElementRef;
  childRoute: string;
  loggedInUserId: string;
  userId: string;
  demographicId: string;

  // loggedInUserId/:userId/:formId

  resultJsonObj: any = {};
  loggedInUserEmail: string;

  companySlug: string = ConstantHelper.COMPANY_SLUG;
  attachmentDocment: any = "";

  isChecked: boolean = false;
  fileToUpload: File = null;
  fSize: any;
  documentsLength: boolean = false;
  documentsList: any = [];
  hippaFile1: any;
  hippaFile2: any;
  hippaFile3: any;
  emailLogTxt: any = "";
  fileDesTxt: any = "";
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  selectedDocsId: any = [];
  selectedHippaUrls: any = [];

  statesArre: any = [];

  stateSelectDisabledOption: boolean = true;
  citySelectDisabledOption: boolean = true;
  physicianSelectDisabledOption: boolean = true;

  cityLength: boolean = false;
  noCityLength: boolean = false;
  citiesList: any = [];

  physicianLength: boolean = false;
  noPhysicianLength: boolean = false;
  physiciansList: any = [];
  selectedPhysician: any = {};
  selectedStateCode: any;
  selectedCityName: any;
  selectedPhysicianId: any;
  docInfo: any = {};
  showDocumentModal: boolean = false;
  choosedFileName: any = "";

  showNewFile: number = 0;
  attachmentDoc: any = {};
  message = Message;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public sweetAlertHelper: SweetAlertHelper,
    private uploadService: UploadService
  ) {
    // console.log(activatedRoute.snapshot.url); // array of states
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
  }

  ngOnInit() {
    this.docInfo.state = "";
    this.docInfo.city = "";
    this.docInfo.physicianName = "";
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.demographicId =
      this.activatedRoute.snapshot.paramMap.get("demographicId");
    this.userId = this.activatedRoute.snapshot.paramMap.get("userId");

    const finalData = {
      loggedInUserId: this.userId,
      company_slug: this.companySlug,
    };
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findMailingDocsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log(apiResponse);
          // console.log(this.resultJsonObj);
          if (apiResponse.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList = apiResponse.commandResult.data.documentList;
            // console.log(this.documentsList);
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );

    const finalHippaData = {
      id: this.userId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_findUserDemographicApi,
        finalHippaData,
        this.httpOptions
      )
      .subscribe(
        (apiHippaResponse: any) => {
          // console.log('apiHippaResponse', apiHippaResponse);
          this.hippaFile1 =
            apiHippaResponse.commandResult.data.userInfo.form1PdfFileNameUrl;
          this.hippaFile2 =
            apiHippaResponse.commandResult.data.userInfo.form2PdfFileNameUrl;
          this.hippaFile3 =
            apiHippaResponse.commandResult.data.userInfo.form3PdfFileNameUrl;
        },
        (err) => {
          // console.log(err);
        }
      );

    const finalCityData = {
      id: this.userId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findPhysicianCitiesApi,
        finalCityData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.statesArre = apiResponse.commandResult.data.statesList;
          // console.log('this.statesArre=>', this.statesArre)
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  clearSelectedDocs() {
    this.selectedDocsId = [];
  }

  async checkAllHippaDoc(isChecked) {
    // alert(isChecked);
    if (isChecked == 1) {
      // if (
      //   confirm("PLEASE CONFIRM: Are you sure, you want to share all documents")
      // )
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.confirmationFormComp.checkAllHipaaDoc
      );
      if (alertStatus) {
        $(".docChecks").each(function () {
          $(this).prop("checked", true);
        });
        $(".hippaChecks").each(function () {
          $(this).prop("checked", true);
        });
      } else {
        this.selectedDocsId = [];
        $(".selectAll").prop("checked", false);
        $(".docChecks").each(function () {
          $(this).prop("checked", false);
        });
        $(".hippaChecks").each(function () {
          $(this).prop("checked", false);
        });
      }
    } else {
      // if (confirm("PLEASE CONFIRM: Are you sure, you want to uncheck all?"))
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.confirmationFormComp.checkAllHipaaDoc1
      );
      if (alertStatus) {
        this.selectedDocsId = [];
        $(".selectAll").prop("checked", false);
        $(".docChecks").each(function () {
          $(this).prop("checked", false);
        });
        $(".hippaChecks").each(function () {
          $(this).prop("checked", false);
        });
      } else {
        $(".selectAll").prop("checked", true);
      }
    }
  }

  getCheckedBoxValue() {
    let allCheck: any = document.getElementsByClassName("docChecks");
    for (let index = 0; index < allCheck.length; index++) {
      const element = allCheck[index];
      if (element.checked) {
        // console.log(element.value);
        this.selectedDocsId.push(element.value);
      }
    }
  }

  // getCheckedHippaValue() {
  //   let allCheck: any = document.getElementsByClassName('hippaChecks');
  //   for (let index = 0; index < allCheck.length; index++) {
  //     const element = allCheck[index];
  //     if (element.checked) {
  // console.log(element.value);
  //       this.selectedHippaUrls.push(element.value);
  //
  //
  //     }
  //   }
  // }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the User Database?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminContactUsComp.goToDemographicsComp
    );
    if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/demographics/" + this.loggedInUserId,
      ]);
    }
  }

  onFileSelected(event) {
    this.showNewFile = 1;
    this.attachmentDocment = "";
    if (this.fileUploaded.nativeElement.value != "") {
      // console.log(this.fileUploaded.nativeElement.value);
      this.fileToUpload = <File>event.target.files.item(0);
      // console.log(this.fileToUpload);
      this.fSize = Math.round(this.fileToUpload.size / 1024);
    } else {
    }
  }

  async onSubmit() {
    let altEmail = "";

    if (this.docInfo.altEmail) {
      altEmail = this.docInfo.altEmail;
    }
    this.getCheckedBoxValue();
    if (this.selectedDocsId.length <= 0) {
      // alert("Please select at least one doc");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminUploadComp.uploadFile
      );
      return false;
    } else if (this.selectedDocsId.length > 5) {
      // alert("You can not select more then 5 docs");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_2
      );
      return false;
    } else if (
      this.showNewFile == 1 &&
      this.fileUploaded.nativeElement.value != "" &&
      !this.validateFile(this.fileToUpload.name)
    ) {
      // alert("Selected file format is not supported");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4

      );
      return false;
    } else if (
      this.showNewFile == 1 &&
      this.fileUploaded.nativeElement.value != "" &&
      this.fSize > 61440
    ) {
      // alert("File too Big, please select a file less than 60mb");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.modifyDocComp.onSubmit1
      );
      return false;
    } else {
      // if (
      //   confirm("PLEASE CONFIRM: Are you sure you want to send this email?")
      // )

      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.demographicsComp.email2All
      );
      {
        this.uploadService
          .adminSendDoc(
            this.selectedPhysician.PhysicianName,
            this.selectedPhysician.PhysicianEmail,
            this.selectedDocsId,
            this.fileToUpload,
            this.loggedInUserId,
            this.userId,
            this.selectedHippaUrls,
            this.companySlug,
            altEmail,
            this.selectedStateCode,
            this.selectedCityName,
            this.selectedPhysicianId,
            this.emailLogTxt,
            this.fileDesTxt,
            ConstantHelper.DOC_TYPE_ADMIN_EMAILED,
            localStorage.getItem("loggedInUserComapnyId"),
            this.showNewFile,
            this.attachmentDoc
          )
          .subscribe(
            async (data: any) => {
              if (data !== undefined) {
                // console.log(typeof data);
                // alert("Email successfully sent. ");
                const alertStatus = await this.sweetAlertHelper.alertPopUp(
                  this.message.alertMessages.adminsecondoptwfCOmp.d2d04_7
                );

                this.router.navigate([
                  "/" +
                    this.childRoute +
                    "/demographics/" +
                    this.loggedInUserId,
                ]);
                if (data.commandResult.success == 1) {
                }
              }
            },
            (error) => {
              // console.log(error);
            }
          );
      }
    }
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "pdf"
    ) {
      return true;
    } else {
      return false;
    }
  }

  findCitiesByStateCode(stateCode) {
    this.selectedStateCode = stateCode;
    this.stateSelectDisabledOption = false;
    this.citiesList = [];

    const finalData = { stateCode: stateCode };
    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log(apiResponse);
          this.citySelectDisabledOption = true;
          if (apiResponse.commandResult.status == 1) {
            if (apiResponse.commandResult.data.cityList.length > 0) {
              this.cityLength = true;
              this.citiesList = apiResponse.commandResult.data.cityList;
              // console.log('this.citiesList', this.citiesList);
            } else {
              this.noCityLength = true;
            }
          } else {
            this.noCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPhysicianByStateCode(cityName) {
    this.selectedCityName = cityName;

    this.citySelectDisabledOption = false;
    const finalDataPhysician = { cityName: cityName };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_getPhysiciansByCityCodeApi,
        finalDataPhysician,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          if (apiResponsePhysician.commandResult.status == 1) {
            this.physicianSelectDisabledOption = true;
            if (
              apiResponsePhysician.commandResult.data.physiciansList.length > 0
            ) {
              this.physicianLength = true;
              this.physiciansList =
                apiResponsePhysician.commandResult.data.physiciansList;
              // console.log('physiciansList List');
              // console.log(this.physiciansList);
            } else {
              // alert("No Physicians Found");
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.adminsecondoptwfCOmp
                  .findcoePhysicians
              );
              this.noPhysicianLength = true;
            }
          } else {
            // alert("No Physicians Found");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findcoePhysicians
            );
            this.noPhysicianLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  updatePhysicianInfo(physicianId) {
    this.selectedPhysicianId = physicianId;

    this.physicianSelectDisabledOption = false;

    this.selectedPhysician = this.physiciansList.find(
      (e) => e.RecordId == physicianId
    );
    // console.log(this.selectedPhysician);
  }

  // setAttachment(document) {
  //   console.log(document);
  //   this.showNewFile = 2;
  //   this.attachmentDoc = JSON.stringify(document);
  //   console.log(document);
  //   this.showNewFile = 2;
  //   this.attachmentDoc = JSON.stringify(document);
  //   console.log(this.attachmentDoc);
  //   this.choosedFileName = JSON.parse(this.attachmentDoc).DocumentName;
  //   console.log(this.choosedFileName);
  // }

  setAttachment(documentId) {
    this.fileUploaded.nativeElement.value = "";

    // console.log(documentId);
    this.showNewFile = 2;
    const docObj = this.documentsList.find((e) => e.RecordId == documentId);
    this.attachmentDoc = JSON.stringify(docObj);
    this.choosedFileName = JSON.parse(this.attachmentDoc).DocumentName;
  }

  closeDocumentModal() {
    this.showDocumentModal = false;
  }

  openDocumentModal() {
    this.showDocumentModal = true;
  }
}

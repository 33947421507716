import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UserService } from "./../../_services/user.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-profie",
  templateUrl: "./profie.component.html",
  styleUrls: ["./profie.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class ProfieComponent implements OnInit {
  loggedInUserId: string;

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  resultJsonObj: any = {};
  profileObj: any = {};
  firstQues: any = [];
  secondQues: any = [];
  que01SelectDisabledOption: boolean = true;
  que02SelectDisabledOption: boolean = true;
  docGrpSelectDisabledOption: boolean = true;

  groupsLength: boolean = false;
  groupsList: any;
  message = Message;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private userService: UserService
  ) {}

  ngOnInit() {
    // profileObj.DocGroupId='0';

    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    // console.log(this.loggedInUserId);
    this.findUserDetails(this.loggedInUserId);
    this.findDocumentGroups();
    this.findFirstSecurityQues();
    this.findSecondSecurityQues();
  }

  findDocumentGroups() {
    const finalDataDocGroup = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_findDocGroupsByCompanyApi,
        finalDataDocGroup,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.groupList.length > 0) {
            this.groupsLength = true;
            this.groupsList = this.resultJsonObj.commandResult.data.groupList;
            // console.log(this.groupsList);
          } else {
            this.groupsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findUserDetails(userId) {
    // alert('findUserDetails');

    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      id: userId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            this.profileObj = apiResponse.commandResult.data.userInfo;
            // console.log(this.profileObj);
            this.profileObj.Password = "";
            this.profileObj.Confirm_Password = "";
            if (this.profileObj.DocGroupId > 0) {
              this.docGrpSelectDisabledOption = false;
            }
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findFirstSecurityQues() {
    const finalDataS = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 1,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataS,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.que01SelectDisabledOption = false;
          this.firstQues = apiResponse.commandResult.data.questionsList;
          // console.log('firstQues=>', this.firstQues);
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findSecondSecurityQues() {
    const finalDataS = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 2,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataS,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.que02SelectDisabledOption = false;
          this.secondQues = apiResponse.commandResult.data.questionsList;
          // console.log('secondQues=>', this.secondQues);
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  selectDocGroup() {
    this.docGrpSelectDisabledOption = false;
  }

  async onSubmit() {
    // if (confirm("PLEASE CONFIRM: Are you sure you want to continue with the given information?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.doctorProfileComp.onSubmit
    );

    {
      const finalData = {
        company_slug: ConstantHelper.COMPANY_SLUG,
        recordId: this.profileObj.RecordId,
        fullName: this.profileObj.FullName,
        mobile: this.profileObj.Mobile,
        ans_01: this.profileObj.Ans_01,
        ans_02: this.profileObj.Ans_02,
        queId_01: this.profileObj.QueId_01,
        queId_02: this.profileObj.QueId_02,
        // docGroupId: this.profileObj.DocGroupId,
        password: this.profileObj.Password,
      };

      // console.log(finalData);

      return this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_updateProfileApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
         async (apiResponse: any) => {
            // console.log(apiResponse);
            localStorage.setItem("loggedInUserName", this.profileObj.FullName);
            // alert("Profile updated successfully.");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.doctorProfileComp.onSubmit
            );

            window.location.reload();
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the Home Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(["/dashboard/" + this.loggedInUserId]);
    }
  }

  async goToDashboard() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the Home Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      window.open(
        this.userService.parentSiteUrl() + "/dashboard/" + this.loggedInUserId,
        "_self"
      );
    }
  }
}

import { Component } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import IdleTimer from "./IdleTimer";
import Swal from 'sweetalert2';
import { UserService } from './_services/user.service'


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  // title = "Active";
  private timer: any;
  private logoutFlag: boolean = false;
  loggedInUserId: string;
  logoutTimeout: any;
  showPopupFlag: boolean = false;
  // private logoutFlag: boolean = false;
  constructor(private router: Router, private userService: UserService) {
    //subscribes every changes of your route
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     //scroll to top
    //     window.scrollTo(0, 0);
    //   }
    // });
  }

  ngOnInit() {
    this.logoutFlag = false;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.url === '/') {
        this.logoutFlag = true;
      }
    });
    this.loggedInUserId = localStorage.getItem('loggedInUserId');
    this.showPopupFlag = false;
    this.timer = new IdleTimer({
      timeout: 840, 
      onTimeout: () => {
        if (this.loggedInUserId && !this.showPopupFlag){
          this.showPopupFlag = true;
          this.showTimeoutWarning();
        }
        clearTimeout(this.timer);
        this.logoutTimeout = setTimeout(() => {
          this.logoutFlag = true;
          localStorage.clear();
          window.open(this.userService.parentSiteUrl() +"/", "_self");
        }, 60000);
      },
      onWarning: () => {
        if (this.loggedInUserId && !this.showPopupFlag){
          this.showPopupFlag = true;
          this.showTimeoutWarning();
        }
      },
    });
  }
  showTimeoutWarning() {
    Swal.fire({
      title: "Session Expiration",
      html: "There is 1 minute left before being logged off. Click anywhere on the screen to continue.",
      timer: 60000,
      timerProgressBar: true,
      allowOutsideClick: true,
      allowEscapeKey: true,
      allowEnterKey: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      icon: "warning",
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        localStorage.clear();
        window.open(this.userService.parentSiteUrl() +"/", "_self");
      }
      this.showPopupFlag = false;
    });
    setTimeout(() => {
      const body = document.querySelector("body");
      if (body) {
        body.addEventListener("click", this.onScreenClick);
      }
    }, 60000);
  }
  onScreenClick = () => {
    if (!this.logoutFlag) {
      clearTimeout(this.logoutTimeout);
      this.timer.reset();
    } else {
      localStorage.clear();
      window.open(this.userService.parentSiteUrl() +"/", "_self");
     }
  };
  ngOnDestroy() {
    this.logoutFlag = true;
    const body = document.querySelector("body");
    if (body) {
      body.removeEventListener("click", this.onScreenClick);
    }
    this.timer.clear();
  }
  refreshPage() {
    window.location.reload();
  }
  title = "Elemrex";
}
// import IdleTimer from "./IdleTimer";
// import { UserService } from './_services/user.service'

// @Component({
//   selector: "app-root",
//   templateUrl: "./app.component.html",
//   styleUrls: ["./app.component.scss"]
// })
// export class AppComponent {
//   timer: any;
//   constructor(
//     private router: Router,
//     private userService: UserService
//   ) {
//     //subscribes every changes of your route
//     this.router.events.subscribe(event => {
//       if (event instanceof NavigationEnd) {
//         //scroll to top
//         window.scrollTo(0, 0);
//       }
//     });
//   }




//   ngOnInit() {
//     this.timer = new IdleTimer({
//       // timeout: 1200, //expired after 1200 secs
//       timeout: 14400, //expired after 14400 secs
//       onTimeout: () => {
//         localStorage.clear();
//         window.open(this.userService.parentSiteUrl() +"/", "_self");
//       }
//     });
//   }
//   ngOnDestroy() {
//     this.timer.clear();
//   }
//   title = "Elemrex";
// }

import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UploadService } from "../../_services/upload.service";
import { DoctorMailService } from "../../_services/doctor-mail.service";
import { UserService } from "../../_services/user.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
@Component({
  selector: "app-admin-d2d-review-log",
  templateUrl: "./admin-d2d-review-log.component.html",
  styleUrls: ["./admin-d2d-review-log.component.scss"],
})
export class AdminD2dReviewLogComponent implements OnInit {
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  showLoader = false;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  childRoute: string;
  pId: string;
  logId: string;
  loggedInUserId: string;
  userOptionId: string;
  logDetails: any = {};
  behaviourId;
  maxChars = 500;
  showDataVal: any;
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private uploadService: UploadService,
    public sweetAlertHelper: SweetAlertHelper,
    private doctorMailService: DoctorMailService,
    public userService: UserService
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.pId = this.activatedRoute.snapshot.paramMap.get("pId");
    this.logId = this.activatedRoute.snapshot.paramMap.get("logId");
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.userOptionId =
      this.activatedRoute.snapshot.paramMap.get("userOptionId");
    this.behaviourId = this.activatedRoute.snapshot.paramMap.get("behaviourId");
    this.showDataVal = this.activatedRoute.snapshot.paramMap.get("showDataVal");
  }
  ngOnInit() {
    this.findD2DLogDetail(this.logId);
  }

  findD2DLogDetail(logId) {
    const finalData = {
      logId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findD2DLogDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.logDetails = res.commandResult.data.log;
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  onSubmit() {
    const reqData = {
      logId: this.logId,
      emailText: this.logDetails.EmailText,
      behaviourId: this.behaviourId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_sendD2dResponseApi,
        reqData,
        this.httpOptions
      )
      .subscribe(
        async (res: any) => {
          this.showLoader = false;
          // alert('Response sent successfully');
          const alertStatus = await this.sweetAlertHelper.alertPopUp(
            this.message.alertMessages.admind2dreviewlogComp.onSubmit
          );

          if (alertStatus)
            this.router.navigate([
              "/" +
                this.childRoute +
                "/transfer-log/" +
                this.loggedInUserId +
                "/" +
                this.showDataVal,
            ]);
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async back() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Confirm your desire to return to the transfer log. "
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.admind2dreviewlogComp.back
    // );
    // if (alertStatus) {
      this.router.navigate([
        "/" +
          this.childRoute +
          "/transfer-log/" +
          this.loggedInUserId +
          "/" +
          this.showDataVal,
      ]);
    // }
  }

 async goToDashboard() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to physician Home Page?"
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.adminChangePassComp.onReset
    // );
    // if (alertStatus)
    //  {
      this.router.navigate([
        "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
      ]);
    // }
  }
}

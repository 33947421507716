// chatbot.component.ts

import { Component } from "@angular/core";
import { ChatbotService } from "./chatbot.service";
import { Router, ActivatedRoute } from "@angular/router";

import * as React from "react";

import * as ReactDOM from "react-dom";
import AHSChatBot from "../chatBot-window/AHSChatBot";

@Component({
  selector: "app-chatbot",
  templateUrl: "./chatbot.component.html",
  styleUrls: ["./chatbot.component.css"],
})
export class ChatbotComponent {
  messages: { text: any; isUser: string }[] = [];
  userMessage: string = "";
  isChatbotOpen: boolean = true; // Add a variable to track whether the chatbot is open or closed
  isMinimized: boolean = false;
  isMaximized: boolean = false;
  loggedInUserId: string;
  // public rootId = "chat-windowB";
  constructor(
    private chatbotService: ChatbotService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
  }

  closeChatbot(): void {
    this.chatbotService.toggleChatbotState();
  }

  // Function to minimize the chatbot
  minimizeChatbot() {
    this.isMaximized = false;
  }

  // Function to maximize the chatbot
  maximizeChatbot() {
    this.isMaximized = true;
  }
  openAhsAiGpt() {
    this.router.navigate(["/ahsgpt/" + this.loggedInUserId]);
  }

  async sendMessage() {
    if (this.userMessage.trim() !== "") {
      // Add user message to chat window
      this.messages.push({ text: this.userMessage, isUser: "true" });

      // Process user message (you can replace this logic with your chatbot's logic)
      const chatbotResponse = this.getChatbotResponse();
      try {
        // const chatbotResponse = this.chatbotService.getIndexPage();

        // Add chatbot response to chat window
        this.messages.push({ text: chatbotResponse, isUser: "false" });

        // Clear user message input field
        this.userMessage = "";
        // console.log("Akshay",this.messages)

        this.messages.forEach((message) => {
          this.chatbotService.addChatMessage(message.text);
        });
      } catch (error) {
        console.error("Error processing chatbot response:", error);
      }
    }
  }
  // ngOnChanges(changes: SimpleChanges) {
  //   this.render();
  // }

  // ngAfterViewInit() {
  //   this.render();
  // }

  // ngOnDestroy() {}

  // Replace this with your chatbot logic
  getChatbotResponse(): string {
    // Simple example: Echo the user's message
    return "I am chatbot";
  }
  // private render() {
  //   ReactDOM.render(
  //     React.createElement(AHSChatBot),
  //     document.getElementById(this.rootId)
  //   );
  // }
}

import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UserService } from "../../_services/user.service";
import { AuthService } from "../../_services/auth.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class DashboardComponent implements OnInit {
  loggedInUserId: string;
  clean = "0";
  demographicId: string;
  resultJsonObj: any = {};
  isDisbleLink = true;
  showModificationAlert = false;
  currentSite = ConstantHelper.COMPANY_NAME;
  showLoader = false;

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  tooltipText =
    " 1)Click and selec document set at bottom of demographic page. Click `Accept` to  return to `Home Page`<br>2) Click `Review & Sign` in the dashboard below.<br>3) Click each document icon to preview.<br>4) Download each document to fil in and sign with Acrobat Reader (link provided).<br>5) You can also print, complete, sign, and scan any document to your desktop.<br>6) Returmto Home Page<br>7) Click `Upload Doc` and `choose` each completed codument to upload.<br>8) Return to Home Page<br>9) Click `Review` to confirm upload.";

  message = Message;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.clean = this.activatedRoute.snapshot.paramMap.get("clean");
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.findUserDetails();
    this.findDemographicDetails();
    if (this.clean == "1") {
      this.cleanTempDocs();
    }
  }

  setDocGroupName() {
    const finalData = {
      id: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_setDefaultGroupApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.showLoader = false;
          this.resultJsonObj = apiResponse;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  goToDemographicForm() {
    localStorage.setItem("showHippaUpdateBtn", "1");
    this.router.navigate(["/demographicsform/" + this.loggedInUserId]);
  }

  goToUSHFForm() {
    this.router.navigate(["/ushf/" + this.loggedInUserId]);
  }

  goToDocumentConfirmation() {
    localStorage.setItem("showHippaUpdateBtn", "0");
    this.router.navigate([
      "/hippa-acceptance/" + this.loggedInUserId + "/" + this.demographicId,
    ]);
  }

  goToDocumentReview() {
    this.router.navigate([
      "/doc-review/" + this.loggedInUserId + "/" + this.demographicId,
    ]);
  }

  goToDocumentUpload() {
    this.router.navigate([
      "/upload-doc/" + this.loggedInUserId + "/" + this.demographicId,
    ]);
  }

  goToEmailDoc() {
    this.router.navigate([
      "/send-doc/" + this.loggedInUserId + "/" + this.demographicId,
    ]);
  }

  goToEmailLog() {
    this.router.navigate(["/email-log/" + this.loggedInUserId]);
  }

  async goToProfile() {
    // if (confirm("PLEASE CONFIRM: Are you sure, you want to go to pofile?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.headerComp.goToProfile
    );
    if (alertStatus) {
      this.router.navigate(["/user-profile/" + this.loggedInUserId]);
    }
  }

  async logout() {
    // if (
    //   confirm("PLEASE CONFIRM: Are you sure, you want to close your session?")
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.headerComp.logout
    );
    {
      localStorage.clear();
      window.open(this.userService.parentSiteUrl() + "/", "_self");
    }
  }

  async editDemographicForm4() {
    if (this.demographicId > "0") {
      //   if (
      //     confirm(
      //       "PLEASE CONFIRM: Click OK to review and update your demographic data."
      //     )
      //   )
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.dashboardComp.editDemographicForm4
      );
      if (alertStatus) {
        this.router.navigate([
          "/edit-demographic-4/" +
            this.loggedInUserId +
            "/" +
            this.demographicId,
        ]);
      }
    } else {
      //   alert("Do you want to review/update your demographic information?");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminDashboardComp.editDemographicForm4
      );
    }
  }

  cleanTempDocs() {
    const finalData = {
      id: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_cleanTempDocsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.showLoader = false;
          this.resultJsonObj = apiResponse;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async showAlertMessage() {
    // alert("Please Review & Sign to in order to Review Demographic.");
    const alertStatus = await this.sweetAlertHelper.alertPopUp(
      this.message.alertMessages.dashboardComp.showAlertMessage
    );
  }

  async showAlertMessage1() {
    // alert("Please Review & Sign to in order to Select Document.");
    const alertStatus = await this.sweetAlertHelper.alertPopUp(
      this.message.alertMessages.dashboardComp.showAlertMessag1
    );
  }

  findDemographicDetails() {
    const finalData = {
      id: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_findUserDemographicApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.showLoader = false;
          this.resultJsonObj = apiResponse;

          if (
            this.resultJsonObj.commandResult.data.userInfo.ProfileCompleted ==
              1 &&
            this.resultJsonObj.commandResult.data.userInfo.Form1Modified == 1
          ) {
            this.showModificationAlert = true;
          } else {
            this.showModificationAlert = false;
          }

          if (
            this.resultJsonObj.commandResult.data.userInfo.ProfileCompleted == 1
          ) {
            this.demographicId =
              this.resultJsonObj.commandResult.data.userInfo.DemographicId;
            localStorage.setItem("profileCompleted", "1");
            this.isDisbleLink = false;
          } else {
            localStorage.setItem("profileCompleted", "0");
            this.isDisbleLink = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findUserDetails() {
    const finalData = {
      id: this.loggedInUserId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.showLoader = false;
          this.resultJsonObj = apiResponse;
          // console.log('userd d', apiResponse);
          localStorage.setItem("isUserIdloggedIn", "Y");
          localStorage.setItem(
            "loggedInUserId",
            this.resultJsonObj.commandResult.data.userInfo.RecordId
          );
          localStorage.setItem(
            "loggedInUserName",
            this.resultJsonObj.commandResult.data.userInfo.FullName
          );
          localStorage.setItem(
            "loggedInUserEmail",
            this.resultJsonObj.commandResult.data.userInfo.Email
          );
          localStorage.setItem(
            "loggedInUserRoleId",
            this.resultJsonObj.commandResult.data.userInfo.UserRoleId
          );
          localStorage.setItem("loggedInUserComapnyId", "1");
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async goToMainSite() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to your Home Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    {
      window.open(
        this.userService.parentSiteUrl() + "/dashboard/" + this.loggedInUserId,
        "_self"
      );
    }
  }
}

import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
// import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

declare let $: any;

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UserService } from "./../../_services/user.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

@Component({
  selector: "app-confirmationform",
  templateUrl: "./confirmationform.component.html",
  styleUrls: ["./confirmationform.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class ConfirmationformComponent implements OnInit {
  isChecked: boolean = false;
  loggedInUserId: string;
  demographicId: string;
  phyicianId: any;
  resultJsonObj: any = {};
  documentsLength: boolean = false;
  documentsList: any;
  ahsPdfUrl: SafeResourceUrl = "";
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  showDeleteButton: boolean = false;

  // disclaimerCheckBoxAccept: Boolean = false;
  // selectedBtn: string;
  // hipaaAccepted: boolean = false;
  // disclaimerAccepted: boolean = false;
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.demographicId =
      this.activatedRoute.snapshot.paramMap.get("demographicId");

    this.phyicianId = this.activatedRoute.snapshot.paramMap.get("phyicianId");

    this.findUserDetails();

    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      loggedInUserId: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_getSignatureDocsByCompanyApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList =
              this.resultJsonObj.commandResult.data.documentList;
          } else {
            this.documentsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );

    $(document).ready(function () {
      $(window).resize(function () {
        setHeightResizeFunc();
      });
      setHeightResizeFunc();
    });
    function setHeightResizeFunc() {
      let rightHeight =
        $(".right-box .ibox-title").height() +
        $(".right-box .ibox-content").height() -
        45;
      $(".docLists").height(rightHeight);
    }
  }

  findUserDetails() {
    const finalData = {
      id: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.name_findUserDetailsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log('userd d', apiResponse);
          localStorage.setItem("isUserIdloggedIn", "Y");
          localStorage.setItem(
            "loggedInUserId",
            this.resultJsonObj.commandResult.data.userInfo.RecordId
          );
          localStorage.setItem(
            "loggedInUserName",
            this.resultJsonObj.commandResult.data.userInfo.FullName
          );
          localStorage.setItem(
            "loggedInUserEmail",
            this.resultJsonObj.commandResult.data.userInfo.Email
          );
          localStorage.setItem(
            "loggedInUserRoleId",
            this.resultJsonObj.commandResult.data.userInfo.UserRoleId
          );
          localStorage.setItem("loggedInUserComapnyId", "1");
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  acceptConfirmationFunc() {
    // if (this.selectedBtn == 'disclaimer') {
    //
    // } else if (this.selectedBtn == 'hippa') {
    //
    // } else {
    //   alert('Please read document first.')
    //
    // }
  }

  goToDemographicForm() {
    this.router.navigate(["/demographicsform/" + this.loggedInUserId]);
  }

  async goToSignatureForm() {
    if ($(".com").not(":checked").length > 0) {
      // alert(
      //   "Please check all checkboxes or click on select all to proceed to next signature page."
      // );

      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.confirmationformComp.goTosignatureForm
      );
    } else {
      // if (confirm('PLEASE CONFIRM: Are you sure you want to go the signature page?'))
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.confirmationFormComp.goToSignatureForm
      );
      if (alertStatus) {
        this.router.navigate([
          "/signaturewaiver1/" +
            this.loggedInUserId +
            "/" +
            this.demographicId +
            "/" +
            this.phyicianId / 0,
        ]);
      }
    }
  }

  loadHippaDoc(event, documentUrl: any, i) {
    // console.log(event.target.checked);
    if (event.target.checked) {
      document.getElementById("hipaaAccepted" + i).removeAttribute("disabled");
      document
        .getElementById("hipaaAccepted" + i)
        .setAttribute("checked", "checked");

      this.ahsPdfUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(documentUrl);
      this.showDeleteButton = true;
    } else {
      this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
      this.isChecked = false;
    }
  }

  // checkAllHippaDoc(isChecked) {
  // console.log(isChecked);

  //   if (confirm("PLEASE CONFIRM: By clicking ‘Select All’ you are waiving your need to review each document individually.")) {
  //     if (isChecked == 1) {
  //       $('.docChecks').each(function () {
  //         $(this).prop("checked", true);
  //       });

  //     } else {
  //       $('.docChecks').each(function () {
  //         $(this).prop("checked", false);
  //       });
  //     }

  //   } else {
  //     if (isChecked == 1) {
  //       $('.selectAll').prop("checked", false);

  //     } else {
  //       $('.selectAll').prop("checked", true);
  //     }

  //   }
  // }

  async checkAllHippaDoc(isChecked) {
    if (isChecked == 1) {
      // if (
      //   confirm(
      //     "PLEASE CONFIRM: By clicking ‘Select All’ you are waiving your need to review each document individually."
      //   )
      // )
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.confirmationFormComp.checkAllHipaaDoc
      );
      if (alertStatus) {
        $(".docChecks").each(function () {
          $(this).prop("checked", true);
        });
        document.getElementById("selectAllH5").innerHTML = "Unselect All";
      } else {
        $(".selectAll").prop("checked", false);
        $(".docChecks").each(function () {
          $(this).prop("checked", false);
        });
      }
    } else {
      // if (
      //   confirm(
      //     "PLEASE CONFIRM: By clicking ‘Unselect All’ you have to review each document individually."
      //   )
      // )
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.confirmationFormComp.checkAllHipaaDoc1
      );
      {
        document.getElementById("selectAllH5").innerHTML = "Select All";
        $(".selectAll").prop("checked", false);
        $(".docChecks").each(function () {
          $(this).prop("checked", false);
        });
      }
    }

    // if (confirm("PLEASE CONFIRM: By clicking ‘Select All’ you are waiving your need to review each document individually.")) {
    //   if (isChecked == 1) {
    //     $('.docChecks').each(function () {
    //       $(this).prop("checked", true);
    //     });

    //   } else {
    //     $('.docChecks').each(function () {
    //       $(this).prop("checked", false);
    //     });
    //   }

    // } else {
    //   if (isChecked == 1) {
    //     $('.selectAll').prop("checked", false);

    //   } else {
    //     $('.selectAll').prop("checked", true);
    //   }

    // }
  }

  async goToWelcome() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure you want to leave and going back to Home Page."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(["/dashboard/" + this.loggedInUserId]);
    }
  }

  async goToRegisteredDoctors() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure you want to leave and going back to Home Page."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    {
      window.open(
        this.userService.parentSiteUrl() +
          "/registered-doctors/" +
          this.loggedInUserId,
        "_self"
      );
    }
  }

  async clearViewr() {
    // if (confirm("PLEASE CONFIRM: Are you sure, you want to clear preview?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminDocReviewComp.clearViewr
    );
    {
      $(".selectAll").prop("checked", false);
      $(".docChecks").each(function () {
        $(this).prop("checked", false);
      });
      this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
      this.showDeleteButton = false;
    }
  }
}

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";

@Component({
  selector: "app-waiver-disclaimer-form",
  templateUrl: "./waiver-disclaimer-form.component.html",
  styleUrls: ["./waiver-disclaimer-form.component.scss"],
  providers: [AppConfig, CommonHelper]
})
export class WaiverDisclaimerFormComponent implements OnInit {
  waiverDisclaimerForm: FormGroup;

  loggedInUserId: string;
  demographicId: string;
  fullName: string;
  dobYear: string;
  dobMonth: string;
  dobDay: string;
  email: string;
  cellularPhone: string;
  resultJsonObj: any = {};
  ahsDisclaimerPdf: SafeResourceUrl;
  ahsHippaPdf: SafeResourceUrl;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.ahsDisclaimerPdf = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.appConfig.getDisclaimerPdf()
    );

    this.ahsHippaPdf = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.appConfig.getHippaPdf()
    );

    this.waiverDisclaimerForm = this.formBuilder.group({});

    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get(
      "loggedInUserId"
    );

    this.demographicId = this.activatedRoute.snapshot.paramMap.get(
      "demographicId"
    );

    this.fullName =
      localStorage.getItem("fName") +
      " " +
      localStorage.getItem("mName") +
      " " +
      localStorage.getItem("lName");
    this.email = localStorage.getItem("email");
    this.cellularPhone = localStorage.getItem("cellPhone");

    this.dobYear = localStorage.getItem("dobYear");
    this.dobMonth = localStorage.getItem("dobMonth");
    this.dobDay = localStorage.getItem("dobDay");

    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json"
    });
    const options = {
      headers: httpHeaders
    };

    const finalData = {
      loggedInUserId: this.loggedInUserId,
      demographicId: this.demographicId
    };

    return this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_findDemographicFormApi,
        finalData,
        options
      )
      .subscribe(
        apiResponse => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          // this.fullName =
          //   this.resultJsonObj.commandResult.data.demographicInfo
          //     .from1FirstName +
          //   " " +
          //   this.resultJsonObj.commandResult.data.demographicInfo.from1LastName;
          //
          // this.email = this.resultJsonObj.commandResult.data.demographicInfo.form1EmailAddress;
          // this.cellularPhone = this.resultJsonObj.commandResult.data.demographicInfo.form1CellularPhone;
        },
        err => {
          // console.log(err);
        }
      );
  }

  onSubmit() {
    this.router.navigate([
      "/signaturewaiver/" + this.loggedInUserId + "/" + this.demographicId
    ]);
  }

  editDemographicForm3() {
    // alert("editDemographicForm2");
    this.router.navigate([
      "/edit-demographic-3/" + this.loggedInUserId + "/" + this.demographicId
    ]);
  }
}

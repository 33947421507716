import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";


@Component({
  selector: 'app-doc-group',
  templateUrl: './doc-group.component.html',
  styleUrls: ['./doc-group.component.scss']
})
export class DocGroupComponent implements OnInit {
  childRoute: string;


  loggedInUserId: string;
  resultJsonObj: any = {};
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  selectedDocId: string;
  selectedDocUrl: string;
  selectedDocName: string;
  showAddGroupModal: boolean = false;
  docGroupObj: any = {};
  groupAdded: boolean = false;

  documentsLength: boolean = false;
  documentsList: any;
  groupName: string;
  groupId: string;


  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });
  httpOptions = {
    headers: this.httpHeaders
  };

  constructor(public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer) {
    localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path)
    this.childRoute = activatedRoute.snapshot.url[0].path;
  }

  ngOnInit() {
    this.docGroupObj.groupName = '';
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get(
      "loggedInUserId"
    );

    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl02() +
        ApiHelper.name_getAdminDocumentsListApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        apiResponse => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
            this.documentsLength = true;
            this.documentsList = this.resultJsonObj.commandResult.data.documentList;
            // console.log(this.documentsList);
          } else {
            this.documentsLength = false;
          }
          // console.log(this.documentsLength);
        },
        err => {
          // console.log(err);
        }
      );
  }

  oepnAddGroupModal() {
    this.showAddGroupModal = true;
  }

  closeAddGroupModal() {
    this.showAddGroupModal = false;
  }

  onSubmit() {

    const finalData = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
      group_name: this.docGroupObj.groupName
    };

    // console.log(finalData);
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_addDocGroupApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        apiResponse => {
          this.resultJsonObj = apiResponse;
          this.groupName = this.resultJsonObj.commandResult.data.groupInfo.GroupName;
          this.groupId = this.resultJsonObj.commandResult.data.groupInfo.GroupId;
          this.groupAdded = true;
          setTimeout(() => {
            this.router.navigate(["/" + this.childRoute + "/add-doc-group/" + this.groupId + "/" + this.groupName + "/" + this.loggedInUserId]);
          }, 1000);
        },
        err => {
          // console.log(err);
        }
      );


  }

  //delete
  openModifyDocPage(RecordId, DocumentName, DocumentDescription) {

  }


}

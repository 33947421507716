import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }
  public isAuthenticated(): boolean {
    const token = localStorage.getItem('isUserIdloggedIn') ? localStorage.getItem('isUserIdloggedIn') : 'N';
    if (token == 'Y') {
      return true;
    } else {
      return false;

    }
  }

}
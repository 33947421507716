import { Component, OnInit } from '@angular/core';
import { SweetAlertHelper } from '../_helpers/sweet.alert.helper';
import { Message } from '../_locale/message';
import { Router, ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-ahsaichatgpt",
  templateUrl: "./ahsaichatgpt.component.html",
  styleUrls: ["./ahsaichatgpt.component.scss"],
})
export class AhsaichatgptComponent implements OnInit {
  message = Message;
  loggedInUserId = localStorage.getItem("loggedInUserId");
  childRoute: string;
  constructor(
    public sweetAlertHelper: SweetAlertHelper,
    private router: Router
  ) {}
  ngOnInit(): void {}

  // async goToDashboard() {
  //   // if (confirm("PLEASE CONFIRM: Are you sure, you want to go to Home page?"))
  //   const alertStatus = await this.sweetAlertHelper.confirmPopUp(
  //     this.message.confirmTitle,
  //     this.message.confirmMessages.adminChangePassComp.onReset
  //   );

  //   if (alertStatus) {
  //     this.router.navigate(["doctor/dashboard/" + this.loggedInUserId]);
  //   }
  // }
  async goToHomePage() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    
      if (alertStatus) {
        this.router.navigate([
          '/doctor/dashboard/' + this.loggedInUserId,
        ]);
      }
    
  }
}

import {Injectable} from '@angular/core';
import Swal from 'sweetalert2';

@Injectable()
export class SweetAlertHelper {
  alertStatus = false;

  constructor() {
  }

  async alertPopUp(alertMsg): Promise<any> {
    await Swal.fire({
      //title: "PLEASE CONFIRM:",
      text: alertMsg,
      showCancelButton: false,
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        this.alertStatus = true;
      }
      if (result.isDismissed) {
        this.alertStatus = false;
      }
    });
    return this.alertStatus;
  }

  async confirmPopUp(title, text): Promise<any> {
    await Swal.fire({
      title,
      text,
      showCancelButton: true,
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        this.alertStatus = true;
      }
      if (result.isDismissed) {
        this.alertStatus = false;
      }
    });
    console.log('this.alertStatus', this.alertStatus);
    return this.alertStatus;
  }

  async confirmPopUpwithYesOrNo(title, text): Promise<any> {
    await Swal.fire({
      title,
      text,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.alertStatus = true;
      }
      if (result.isDismissed) {
        this.alertStatus = false;
      }
    });
    return this.alertStatus;
  }
}

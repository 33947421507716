import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

@Component({
  selector: "app-admin-contact-us",
  templateUrl: "./admin-contact-us.component.html",
  styleUrls: ["./admin-contact-us.component.scss"],
})
export class AdminContactUsComponent implements OnInit {
  maxChars = 300;
  fileDesTxt: any = "";
  emailSubject: any = "";
  childRoute: string;
  loggedInUserId: string;
  companySlug: string = ConstantHelper.COMPANY_SLUG;
  comapnyName: string = ConstantHelper.COMPANY_NAME;

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
  }

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
  }

  async onReset() {
    //   if (
    //     confirm(
    //       "PLEASE CONFIRM: Are you certain you want to return to the Home Page?"
    //     )
    //   )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
      ]);
    }
  }
  async goToDemographics() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the User Database?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminContactUsComp.goToDemographicsComp
    );
    if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/demographics/" + this.loggedInUserId,
      ]);
    }
  }

  async onSubmit() {
    // if (confirm("PLEASE CONFIRM: Are you sure you want to send email?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.demographicsComp.email2All
    );
    {
      const finalData = {
        subject: this.emailSubject,
        query: this.fileDesTxt,
        userId: this.loggedInUserId,
      };
      // console.log('finalData=>', finalData);
      this.http
        .post(
          this.appConfig.getLambdaUrl05() + ApiHelper.sendUserQuery,
          finalData,
          this.httpOptions
        )
        .subscribe(
          async (apiResponse: any) => {
            // console.log('apiResponse=>', apiResponse)
            // alert("Email successfully sent to all patients.");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.contactUs.contactUs
            );
            if (alertStatus) {
              this.router.navigate(["/" + this.childRoute + "/dashboard/" + this.loggedInUserId,])
            }
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }
}

import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UploadService } from "../../_services/upload.service";
import { UserService } from "../../_services/user.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

@Component({
  selector: "app-allot-doc-group",
  templateUrl: "./allot-doc-group.component.html",
  styleUrls: ["./allot-doc-group.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class AllotDocGroupComponent implements OnInit {
  @ViewChild("fileUploaded", { static: false }) fileUploaded: ElementRef;

  demographyUserId: string;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  logId: string;
  docInfo: any = {};
  companySlug: string = ConstantHelper.COMPANY_SLUG;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  groupsList: any = [];
  groupsLength: boolean = false;
  loggedInUserId: string;
  demographicId: string;
  userId: string;
  delegateId: any;
  demographicObj: any = {};
  userObj: any = {};
  loggedInUserRoleId: any;
  childRoute: string;
  patientName: any;
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private uploadService: UploadService,
    public sweetAlertHelper: SweetAlertHelper,
    public userService: UserService
  ) {
    // console.log(activatedRoute.snapshot.url); // array of states
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.loggedInUserRoleId = localStorage.getItem('loggedInUserRoleId');
  }

  ngOnInit() {
    this.demographicObj.DocGroupId = "";
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.demographicId =
      this.activatedRoute.snapshot.paramMap.get("demographicId");
    this.userId = this.activatedRoute.snapshot.paramMap.get("userId");
    this.delegateId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.loggedInUserId;

    this.findDemographicDetails(this.userId);
    this.findUserDetails(this.userId);
    this.findDocumentGroups();
  }

  findDemographicDetails(userId) {
    const finalData = {
      id: userId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_findUserDemographicApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log('Demo Graphic', apiResponse);
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findUserDetails(userId) {
    const finalData = {
      id: userId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.userObj = apiResponse;
          this.patientName = this.userService.getUS_FromatName(
            apiResponse.commandResult.data.userInfo.FullName
          );
          // console.log('User', this.userObj);
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findDocumentGroups() {
    const finalDataDocGroup = {
      loggedInUserId: this.loggedInUserId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_findDocGroupsByCompanyApi,
        finalDataDocGroup,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.data.groupList.length > 0) {
            this.groupsLength = true;
            this.groupsList = apiResponse.commandResult.data.groupList;
            // console.log('groupsList',this.groupsList);
          } else {
            this.groupsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async onSubmit() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you sure you want allot this document group?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.allotDocGrpCOmp.onSubmit
    );
    if (alertStatus) {
      {
        const finalData = {
          company_slug: ConstantHelper.COMPANY_SLUG,
          loggedInUserId: this.delegateId,
          demographicId: this.demographicId,
          userId: this.userId,
          docGroupId: this.demographicObj.DocGroupId,
        };

        // console.log(finalData);
        return this.http
          .post(
            this.appConfig.getPhpUrl() + ApiHelper.php_allotDocGroupApi,
            finalData,
            this.httpOptions
          )
          .subscribe(
            async (apiResponse: any) => {
              // console.log(apiResponse);
              if (apiResponse.commandResult.success == 1) {
                // console.log('Document');
                // alert("Document Group assigned successfully");
                const alertStatus = await this.sweetAlertHelper.alertPopUp(
                  this.message.alertMessages.allotDocgrpComp.onSubmit
                );
                if (alertStatus)
                  this.router.navigate([
                    "/" +
                      this.childRoute +
                      "/demographics/" +
                      this.loggedInUserId,
                  ]);
              } else {
                // console.log('Document error');
                // alert("oops! error occured, please try later");
                const alertStatus = await this.sweetAlertHelper.alertPopUp(
                  this.message.alertMessages.allotDocgrpComp.onSubmi1
                );
              }
            },
            (err) => {
              // console.log(err);
            }
          );
      }
    }
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the User Database?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminUploaddocComp.gotoDemgraphics
    );

    if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
      ]);
    }
  }

  async goToDemographics() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Confirm your desire to return to the 'Patient user' list. "
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminUploaddocComp.gotoDemgraphics
    );

    if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/demographics/" + this.loggedInUserId,
      ]);
    }
  }
}

import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UserService } from "../../_services/user.service";
import { ArrayHelper } from "./../../_helpers/array.helper";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-admin-ushf2",
  templateUrl: "./admin-ushf2.component.html",
  styleUrls: ["./admin-ushf2.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class AdminUshf2Component implements OnInit {
  maxChars = 300;
  fileDesTxt: any = "";
  childRoute: string;
  loggedInUserId: string;
  userId: string;
  demographicId: string;
  resultJsonObj: any = {};
  docInfo: any = {};
  companySlug: string = ConstantHelper.COMPANY_SLUG;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  showLoader = true;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  statesArre: any = [];
  stateSelectDisabledOption = true;
  citySelectDisabledOption = true;
  physicianSelectDisabledOption = true;
  cityLength = false;
  noCityLength = false;
  citiesList: any;
  physicianLength = false;
  noPhysicianLength = false;
  physiciansList: any = [];
  selectedPhysician: any = {};
  hippaAvailable: any;
  selectedDocUrl: any;
  selectedStateCode: any;
  showSearchPhysicianModal = false;
  physicianSearchObj: any = {};
  doctorTableArr: any = [];
  doctorTableArrTemp: any = [];
  isSearched = false;
  searchStateList: any = ArrayHelper.STATE_ARRAY;
  showComfirmationModal = false;
  hasPhysicianEmail = 1;

  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient,
    public userService: UserService
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
  }
  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.userId = this.activatedRoute.snapshot.paramMap.get("userId");
    this.findPhysicianCities();
    this.docInfo.state = "";
    this.docInfo.city = "";
    this.docInfo.physicianName = "";
  }

  findPhysicianCities() {
    // alert(this.userId);
    const finalData = {
      id: this.loggedInUserId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findPhysicianCitiesApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.statesArre = apiResponse.commandResult.data.statesList;
          // console.log('this.statesArre=>', this.statesArre)
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the Physician Home Page?"
    //   )
    // )

    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/dashboard/" + this.loggedInUserId,
      ]);
    }
  }

  async onSubmit() {
    // console.log(this.fileDesTxt);
    if (this.selectedDocUrl) {
      // if (confirm("PLEASE CONFIRM: Are you sure?"))
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.addAdminUserComp.onSubmit
      );
      {
        // window.open(this.selectedDocUrl);
        const finalData = {
          selectedDocUrl: this.selectedDocUrl,
          fileDesTxt: this.fileDesTxt,
          loggedInUserId: this.loggedInUserId,
          userId: this.selectedPhysician.RecordId,
          physicianState: this.selectedStateCode,
        };
        this.showLoader = true;
        this.http
          .post(
            this.appConfig.getPhpUrl() + ApiHelper.php_sendUSHFApi,
            finalData,
            this.httpOptions
          )
          .subscribe(
            async (apiResponse: any) => {
              // console.log('apiResponse=>', apiResponse);
              // if (
              //   confirm(
              //     "PLEASE CONFIRM: HIPAA Form send successfully, do you want to resend the same?"
              //   )
              // )
              const alertStatus = await this.sweetAlertHelper.confirmPopUp(
                this.message.confirmTitle,
                this.message.confirmMessages.adminushfComp.onsubmit1
              );
              if (alertStatus) {
                window.location.reload();
              } else {
                this.router.navigate([
                  "/" +
                    this.childRoute +
                    "/demographics/" +
                    this.loggedInUserId,
                ]);
              }
              this.showLoader = false;
            },
            (err) => {
              // console.log(err);
            }
          );
      }
    } else {
      // alert("No HIPAA form found for the state.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminushfComp.onSubmit1
      );
    }
  }

  findCitiesByStateCode(stateCode) {
    this.stateSelectDisabledOption = false;
    this.citiesList = [];

    // tslint:disable-next-line: object-literal-shorthand
    const finalData = { stateCode: stateCode };
    this.showLoader = true;
    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log(apiResponse);
          this.citySelectDisabledOption = true;
          if (apiResponse.commandResult.status == 1) {
            if (apiResponse.commandResult.data.cityList.length > 0) {
              this.cityLength = true;
              this.citiesList = apiResponse.commandResult.data.cityList;
              // console.log('this.citiesList', this.citiesList);
            } else {
              this.noCityLength = true;
            }
          } else {
            this.noCityLength = true;
          }

          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPhysicianByStateCode(cityName) {
    this.isSearched = false;
    this.citySelectDisabledOption = false;
    // tslint:disable-next-line: object-literal-shorthand
    const finalDataPhysician = { cityName: cityName };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_getPhysiciansByCityCodeApi,
        finalDataPhysician,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          this.showLoader = false;
          if (apiResponsePhysician.commandResult.status == 1) {
            this.physicianSelectDisabledOption = true;
            if (
              apiResponsePhysician.commandResult.data.physiciansList.length > 0
            ) {
              this.physicianLength = true;

              // this.physiciansList = apiResponsePhysician.commandResult.data.physiciansList;
              this.physiciansList = this.fnChangePhysicianNameFormat(
                apiResponsePhysician.commandResult.data.physiciansList
              );

              // console.log(this.physiciansList)
            } else {
              // alert("No Physicians Found");
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.adminsecondoptwfCOmp
                  .findcoePhysicians
              );
              this.noPhysicianLength = true;
            }
          } else {
            // alert("No Physicians Found");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findcoePhysicians
            );
            this.noPhysicianLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  fnChangePhysicianNameFormat(physicians) {
    const formattedNameArray: any = [];
    physicians.forEach((e) => {
      e.PhysicianName = this.userService.getUS_FromatName(e.PhysicianName);
      formattedNameArray.push(e);
    });
    return formattedNameArray.sort(
      this.userService.dynamicSort("PhysicianName")
    );
  }

  updatePhysicianInfo(physicianId) {
    this.physicianSelectDisabledOption = false;

    this.selectedPhysician = this.physiciansList.find(
      (e) => e.RecordId == physicianId
    );
    // console.log(this.selectedPhysician);

    if (!this.selectedPhysician.PhysicianEmail) {
      this.showComfirmationModal = true;
    }
  }

  onChangeUserState(stateCode) {
    this.selectedStateCode = stateCode;
    // console.log('State Code=> ', stateCode);
    this.userService.findHippaByStateCode(stateCode).subscribe(
      (apiResponse) => {
        // console.log(apiResponse);
        this.resultJsonObj = apiResponse;
        if (this.resultJsonObj.commandResult.status == 1) {
          this.hippaAvailable = true;
          this.selectedDocUrl =
            this.resultJsonObj.commandResult.data.hippaDetails.DocumentUrl;
        } else {
          this.hippaAvailable = false;
        }
      },
      (err) => {
        // console.log(err);
      }
    );
  }

  async goToDemographics() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Confirm your desire to return to the 'Patient user' list. "
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminUploaddocComp.gotoDemgraphics
    );
    if (alertStatus) {
      this.router.navigate([
        "/" + this.childRoute + "/demographics/" + this.loggedInUserId,
      ]);
    }
  }

  closeSearchPhysicianModal() {
    this.showSearchPhysicianModal = false;
  }

  closeComfirmationModal() {
    this.showComfirmationModal = false;
  }
  openSearchPhysicianModal() {
    this.showSearchPhysicianModal = true;
    this.physicianSearchObj.physicianSearchLastName = "";
    this.physicianSearchObj.physicianSearchState = "";
    this.physicianSearchObj.physicianSearchNPI = "";
    this.doctorTableArr = [];
  }

  resetSearchPhysicianModal() {
    this.physicianSearchObj.physicianSearchLastName = "";
    this.physicianSearchObj.physicianSearchState = "";
    this.physicianSearchObj.physicianSearchNPI = "";
    this.doctorTableArr = [];
  }

  async searchPhysicians() {
    if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchLastName
    ) {
      // alert("Please enter last name");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminUshf2Comp.searchPhysicians
      );

      return;
    } else if (
      !this.physicianSearchObj.physicianSearchNPI &&
      this.physicianSearchObj.physicianSearchLastName.length < 3
    ) {
      // alert("Last name requires minimun 3 characters.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminUshf2Comp.searchPhysicians1
      );
      return;
    } else if (
      !this.physicianSearchObj.physicianSearchNPI &&
      !this.physicianSearchObj.physicianSearchState
    ) {
      // alert("Please select state");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.seacrhPhysician2
      );
      return;
    } else {
      this.doctorTableArr = [];

      const finalData = {
        loggedInUserId: this.loggedInUserId,
        physicianSearchLastName:
          this.physicianSearchObj.physicianSearchLastName,
        physicianSearchState: this.physicianSearchObj.physicianSearchState,
        physicianSearchNPI: this.physicianSearchObj.physicianSearchNPI,
      };
      this.showLoader = true;
      this.http
        .post(
          this.appConfig.getPhpUrl() +
            ApiHelper.php_searchPhysicianByNameStateApi,
          finalData,
          this.httpOptions
        )
        // tslint:disable-next-line: deprecation
        .subscribe(
          (apiResponse: any) => {
            this.doctorTableArrTemp =
              apiResponse.commandResult.data.physiciansList;
            this.doctorTableArr = this.fnChangePhysicianNameFormat(
              this.doctorTableArrTemp
            );
            this.cityLength = true;
            this.citiesList = apiResponse.commandResult.data.cityList;
            this.selectedDocUrl =
              apiResponse.commandResult.data.hippaDetails.DocumentUrl;
            this.showLoader = false;
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }
  setStateCode(stateCode) {
    this.selectedStateCode = stateCode;
  }

  selectDoctor(physicianId) {
    this.physicianSelectDisabledOption = false;
    this.isSearched = true;
    this.selectedPhysician = this.doctorTableArr.find(
      (e) => e.RecordId == physicianId
    );
    // console.log(this.selectedPhysician);
    this.showSearchPhysicianModal = false;
    this.docInfo.state = this.selectedPhysician.PhysicianStateCode;
    this.docInfo.stateName = this.selectedPhysician.PhysicianStateName;

    this.docInfo.city = this.selectedPhysician.PhysicianCity;
    this.docInfo.physicianName = this.selectedPhysician.RecordId;
    this.docInfo.physician_Name = this.selectedPhysician.PhysicianName;

    const finalData = { physicianNPI: this.selectedPhysician.PhysicianNipNo };
    this.showLoader = true;

    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findPhysicianEmailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.data.hasPhysicianEmail == 1) {
            this.selectedPhysician.PhysicianEmail =
              apiResponse.commandResult.data.physicianEmail;
          } else {
            this.selectedPhysician.PhysicianEmail =
              apiResponse.commandResult.data.physicianEmail;
            this.showComfirmationModal = true;
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }
}

import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";

@Component({
  selector: "app-faq-list",
  templateUrl: "./faq-list.component.html",
  styleUrls: ["./faq-list.component.scss"]
})
export class FaqListComponent implements OnInit {
  isCollapsed = false;
  loggedInUserId: string;
  resultJsonObj: any = {};
  childRoute: string;
  faqsLength: boolean = false;
  faqsList: any;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  supportMail = ConstantHelper.SUPPORT_EMAIL;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {   // console.log(activatedRoute.snapshot.url); // array of states
    localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path)
    this.childRoute= activatedRoute.snapshot.url[0].path;}

  ngOnInit() {
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get(
      "loggedInUserId"
    );

    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json"
    });

    const options = {
      headers: httpHeaders
    };

    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      loggedInUserId: this.loggedInUserId
    };

    this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_getAdminFaqListApi,
        finalData,
        options
      )
      .subscribe(
        apiResponse => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj.commandResult.data.adminFaqs.length);

          // console.log(this.resultJsonObj);

          if (this.resultJsonObj.commandResult.data.adminFaqs.length > 0) {
            this.faqsLength = true;
            this.faqsList = this.resultJsonObj.commandResult.data.adminFaqs;
            // console.log(this.faqsList);
          } else {
            this.faqsLength = false;
          }
        },
        err => {
          // console.log(err);
        }
      );
  }

  openAddUserPage() {
    this.router.navigate(['/'+this.childRoute+'/add-admin-user/' + this.loggedInUserId]);
  }
}

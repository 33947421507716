import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpEvent,
  HttpErrorResponse,
  HttpEventType,
} from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

import { environment } from "./../../environments/environment";
import { ApiHelper } from "./../_helpers/api.helper";
import { CommonHelper } from "./../_helpers/common.helper";
import { ConstantHelper } from "./../_helpers/constant.helper";
import { AppConfig } from "./../../app/app.config";

@Injectable({
  providedIn: "root",
})
export class DoctorMailService {
  constructor(
    private http: HttpClient,
    public commonHelper: CommonHelper,
    private appConfig: AppConfig
  ) {}

  // tslint:disable-next-line: max-line-length
  doctor2DoctorEmail03(
    userId,
    companySlug,
    physicianType,
    stateCode,
    cityName,
    physicianId,
    fileDesTxt,
    docType,
    userOptionId,
    fileToUpload,
    selectedDocsId,
    hippaFileDesTxt,
    showNewFile,
    attachmentDoc,
    selectedEmail,
    showUserCheckVal,
    demographicUserId,
    assignedDelegateId,
    isDelegated,
    selectedDelegateId
  ) {
    const formData = new FormData();
    if (fileToUpload) {
      formData.append("fileKey", fileToUpload, fileToUpload.name);
    }
    formData.append("requestedPhysicianEmail", selectedEmail);
    formData.append("showUserCheck", showUserCheckVal);
    formData.append("loggedInUserId", userId);
    formData.append("userOptionId", userOptionId);
    formData.append("physicianType", physicianType);
    formData.append("selectedDocsId", selectedDocsId);
    formData.append("companySlug", companySlug);
    formData.append("selectedStateCode", stateCode);
    formData.append("selectedCityName", cityName);
    formData.append("selectedPhysicianId", physicianId);
    formData.append("hippaFileDesTxt", hippaFileDesTxt);
    formData.append("fileDesTxt", fileDesTxt);
    formData.append("docType", docType);
    formData.append("showNewFile", showNewFile);
    formData.append("attachmentDocObj", attachmentDoc);
    formData.append("demographicUserId", demographicUserId);
    formData.append("assignedDelegateId", assignedDelegateId);
    formData.append("isDelegated", isDelegated);
    formData.append("selectedDelegateId", selectedDelegateId);

    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_docotr2doctorEmail03Api,
        formData,
        {
          reportProgress: true,
          observe: "events",
        }
      )
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.Response:
              return event.body;
          }
        })
      );
  }

  doctor2DoctorEmail01(
    userId,
    companySlug,
    physicianType,
    physicianId,
    specialtyId,
    subSpecialtyId,
    fileDesTxt,
    docType,
    userOptionId,
    fileToUpload,
    selectedDocsId,
    hippaFileDesTxt,
    showNewFile,
    attachmentDoc,
    requestingPhyMobile,
    requestingPhyEmail,
    reuestTime,
    demographicUserId,
    assignedDelegateId,
    isDelegated,
    selectedDelegateId
  ) {
    const formData = new FormData();
    if (fileToUpload) {
      formData.append("fileKey", fileToUpload, fileToUpload.name);
    }
    formData.append("loggedInUserId", userId);
    formData.append("companySlug", companySlug);
    formData.append("physicianType", physicianType);
    formData.append("physicianId", physicianId);
    formData.append("specialtyId", specialtyId);
    formData.append("subSpecialtyId", subSpecialtyId);
    formData.append("fileDesTxt", fileDesTxt);
    formData.append("docType", docType);
    formData.append("userOptionId", userOptionId);
    formData.append("selectedDocsId", selectedDocsId);
    formData.append("hippaFileDesTxt", hippaFileDesTxt);
    formData.append("showNewFile", showNewFile);
    formData.append("attachmentDocObj", attachmentDoc);
    formData.append("requestingPhyMobile", requestingPhyMobile);
    formData.append("requestingPhyEmail", requestingPhyEmail);
    formData.append("reuestTime", reuestTime);
    formData.append("demographicUserId", demographicUserId);

    formData.append("assignedDelegateId", assignedDelegateId);
    formData.append("isDelegated", isDelegated);
    formData.append("selectedDelegateId", selectedDelegateId);

    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_docotr2doctorEmail01Api,
        formData,
        {
          reportProgress: true,
          observe: "events",
        }
      )
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.Response:
              return event.body;
          }
        })
      );
  }

  doctor2DoctorEmail02(
    loggedInUserId,
    companySlug,
    physicianType,
    physicianId,
    specialtyId,
    subSpecialtyId,
    emailTxt,
    requestingPhyMobile,
    requestingPhyEmail,
    reuestTime,
    userOptionId,
    docType,
    fileToUpload1,
    fileToUpload2,
    fileToUpload3,
    assignedDelegateId,
    isDelegated,
    selectedDelegateId
  ) {
    const formData = new FormData();
    if (fileToUpload1) {
      formData.append("fileKey1", fileToUpload1, fileToUpload1.name);
    }

    if (fileToUpload2) {
      formData.append("fileKey2", fileToUpload2, fileToUpload2.name);
    }

    if (fileToUpload3) {
      formData.append("fileKey3", fileToUpload3, fileToUpload3.name);
    }

    formData.append("loggedInUserId", loggedInUserId);
    formData.append("companySlug", companySlug);
    formData.append("physicianType", physicianType);
    formData.append("physicianId", physicianId);
    formData.append("specialtyId", specialtyId);
    formData.append("subSpecialtyId", subSpecialtyId);
    formData.append("emailTxt", emailTxt);
    formData.append("requestingPhyMobile", requestingPhyMobile);
    formData.append("requestingPhyEmail", requestingPhyEmail);
    formData.append("reuestTime", reuestTime);
    formData.append("userOptionId", userOptionId);
    formData.append("docType", docType);

    formData.append("assignedDelegateId", assignedDelegateId);
    formData.append("isDelegated", isDelegated);
    formData.append("selectedDelegateId", selectedDelegateId);



    return this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_docotr2doctorEmail02Api,
        formData,
        {
          reportProgress: true,
          observe: "events",
        }
      )
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.Response:
              return event.body;
          }
        })
      );
  }
}

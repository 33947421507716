import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { UserService } from "./../../_services/user.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.scss"],
})
export class FaqComponent implements OnInit {
  isCollapsed = false;
  loggedInUserId: string;
  resultJsonObj: any = {};

  faqsLength: boolean = false;
  faqsList: any;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  supportMail = ConstantHelper.SUPPORT_EMAIL;
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");

    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });

    const options = {
      headers: httpHeaders,
    };

    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      loggedInUserId: this.loggedInUserId,
    };

    this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_getAdminFaqListApi,
        finalData,
        options
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj.commandResult.data.adminFaqs.length);

          // console.log(this.resultJsonObj);

          if (this.resultJsonObj.commandResult.data.adminFaqs.length > 0) {
            this.faqsLength = true;
            this.faqsList = this.resultJsonObj.commandResult.data.adminFaqs;
            // console.log(this.faqsList);
          } else {
            this.faqsLength = false;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async goToDashboad() {
    // if (confirm('PLEASE CONFIRM: Are you certain you want to return to the Home Page?'))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(["/dashboard/" + this.loggedInUserId]);
    }
  }

  async goToMainSite() {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      // if (confirm("PLEASE CONFIRM: Are you certain you want to return to your Home Page?"))

      window.open(
        this.userService.parentSiteUrl() + "/dashboard/" + this.loggedInUserId,
        "_self"
      );
    }
  }
}

import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
// import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from "@angular/common/http";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

@Component({
  selector: 'app-admin-welcome',
  templateUrl: './admin-welcome.component.html',
  styleUrls: ['./admin-welcome.component.scss']
})
export class AdminWelcomeComponent implements OnInit {
  message = Message;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  goToLogin() {
    this.router.navigate(["/doctor/login/"]);
  }

  goToAdminLogin() {
    this.router.navigate(["/admin/login/"]);
  }





  goToRegister() {
    this.router.navigate(["/doctor/register/"]);
  }

  async checkLogin() {
    //alert('Please login first.')
    const alertStatus = await this.sweetAlertHelper.alertPopUp(
      this.message.alertMessages.adminWelcome.checklog
    );
   

  }

}

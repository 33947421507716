import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  HostListener
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from '@angular/common/http';

import {AuthService} from '../auth.service';
import {MustMatch} from '../../_helpers/matchpassword';

import {environment} from './../../../environments/environment';
import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';
import {AppConfig} from './../../../app/app.config';
import {ConstantHelper} from './../../_helpers/constant.helper';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [AppConfig, CommonHelper]
})
export class RegisterComponent implements OnInit {
  @ViewChild('recaptcha', {static: false}) recaptchaElement: ElementRef;
  @HostListener('window:resize', ['$event'])
  registrationForm: FormGroup;
  registrationFormSubmitted = false;
  showRegistrationForm = true;
  registrationEmailTaken = false;
  registrationMobileTaken = false;
  verifyRegistrationFormMessage = false;
  verifyRegistrationFormErrorMessage = false;
  verifyRegistrationOtpForm: FormGroup;
  verifyRegistrationOtpFormSubmitted = false;
  showVerifyRegistrationOtpForm = false;
  tempUserId: number;
  tempUserOtp: string;
  userOtp: number;
  userId: number;
  userEmail: string;
  userMobile: string;
  userRoleId: number;
  resultJsonObj: any = {};
  formRecaptchaErrorMessage = false;

  firstQuesLength = false;
  firstQuesList: any;

  secondQuesLength = false;
  secondQuesList: any;

  registrationFormMessage = false;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  httpOptions = {
    headers: this.httpHeaders
  };

  fieldTextType: boolean;
  cnFieldTextType: boolean;

  constructor(public commonHelper: CommonHelper,
              private appConfig: AppConfig,
              private formBuilder: FormBuilder,
              private http: HttpClient,
              private authService: AuthService,
              private router: Router) {

  }

  ngOnInit() {
    // console.log(ConstantHelper.COMPANY_SLUG);

    this.addRecaptchaScript();


    this.registrationForm = this.formBuilder.group(
      {
        fullName: ['', Validators.required],
        mobile: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$#@$!%*?&])[A-Za-z\d$#@$!%*?&].{7,}')]],
        confirmPassword: ['', Validators.required],
        recaptchaValue: ['', Validators.required],
        que1Id: ['', Validators.required],
        que2Id: ['', Validators.required],
        ans1: ['', Validators.required],
        ans2: ['', Validators.required],
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );

    this.verifyRegistrationOtpForm = this.formBuilder.group({
      otp: ['', Validators.required]
    });

    /////////////////////////////////////////////////////// Second Questions List Starts /////////////////////////////////////////////////////
    const finalDataS = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 2,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataS,
        this.httpOptions
      )
      .subscribe(
        (apiResponseSQ: any) => {
          this.resultJsonObj = apiResponseSQ;
          if (this.resultJsonObj.commandResult.data.questionsList.length > 0) {
            this.secondQuesLength = true;
            this.secondQuesList = this.resultJsonObj.commandResult.data.questionsList;
            // console.log('-------secondQuesList-------');
            // console.log(this.secondQuesList);
          } else {
            this.firstQuesLength = false;
            this.secondQuesLength = false;
          }
        },
        err => {
          // console.log(err);
        }
      );

    /////////////////////////////////////////////////////// Second Questions List Ends /////////////////////////////////////////////////////


    /////////////////////////////////////////////////////// First Questions List Starts /////////////////////////////////////////////////////
    const finalDataF = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      setId: 1,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_getAllQuestionsListApi,
        finalDataF,
        this.httpOptions
      )
      .subscribe(
        (apiResponseFQ: any) => {
          this.resultJsonObj = apiResponseFQ;
          if (this.resultJsonObj.commandResult.data.questionsList.length > 0) {
            this.firstQuesLength = true;
            this.firstQuesList = this.resultJsonObj.commandResult.data.questionsList;
            // console.log('-------firstQuesList-------');
            // console.log(this.firstQuesList);
          } else {
            this.firstQuesLength = false;
          }
        },
        err => {
          // console.log(err);
        }
      );

    /////////////////////////////////////////////////////// First Questions List Ends /////////////////////////////////////////////////////


  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registrationForm.controls;
  }

  get v() {
    return this.verifyRegistrationOtpForm.controls;
  }

  onSubmit() {

    // console.log(this.registrationForm.value);
    this.registrationFormSubmitted = true;

    // stop here if form is invalid
    if (this.registrationForm.invalid) {
      // alert('invalid')
      return;

    }

    headers: new HttpHeaders();
    let httpHeaders = new HttpHeaders({
      // "Content-Type": "application/x-www-form-urlencoded"
      'Content-Type': 'application/json'
    });
    let options = {
      headers: httpHeaders
    };
    const finalData = {
      company_slug: ConstantHelper.COMPANY_SLUG,
      email: this.registrationForm.value.email,
      mobile: this.registrationForm.value.mobile,
      fullName: this.registrationForm.value.fullName,
      password: this.registrationForm.value.password,
      que1Id: this.registrationForm.value.que1Id,
      que2Id: this.registrationForm.value.que2Id,
      ans1: this.registrationForm.value.ans1,
      ans2: this.registrationForm.value.ans2,
      user_role_id: ConstantHelper.USER_ROLE_USER

    };

    // console.log(finalData);

    return this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_registrationApi,
        finalData,
        options
      )
      .subscribe(
        apiResponse => {
          // console.log(apiResponse);
          this.resultJsonObj = apiResponse;

          if (this.resultJsonObj.commandResult.status == 1) {
            this.tempUserOtp = this.resultJsonObj.commandResult.data.tempUserInfo.tempOtp;
            this.tempUserId = this.resultJsonObj.commandResult.data.tempUserInfo.tempId;
            this.showRegistrationForm = false;
            this.showVerifyRegistrationOtpForm = true;
          } else if (this.resultJsonObj.commandResult.status == -1) {
            this.registrationEmailTaken = false;
            this.registrationMobileTaken = true;
          } else if (this.resultJsonObj.commandResult.status == -2) {
            this.registrationEmailTaken = true;
            this.registrationMobileTaken = false;
          }
        },
        err => {
          // console.log(err);
        }
      );
  }

  verifyOtp() {
    this.verifyRegistrationOtpFormSubmitted = true;
    if (this.verifyRegistrationOtpForm.invalid) {
      return;
    }

    // console.log(this.verifyRegistrationOtpForm.value.otp);
    // console.log(this.tempUserOtp);

    if (this.verifyRegistrationOtpForm.value.otp != this.tempUserOtp) {
      this.verifyRegistrationFormErrorMessage = true;
    } else {
      headers: new HttpHeaders();
      let httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      let options = {
        headers: httpHeaders
      };
      const finalData = {
        otp: this.verifyRegistrationOtpForm.value.otp,
        id: this.tempUserId
      };

      return this.http
        .post(
          this.appConfig.getLambdaUrl01() +
          ApiHelper.name_verifyRegistrationOtpApi,
          finalData,
          options
        )
        .subscribe(
          apiResponse => {
            this.resultJsonObj = apiResponse;

            if (this.resultJsonObj.commandResult.status === 1) {

              const finalData1 = {
                email: this.registrationForm.value.email,
                id: this.resultJsonObj.commandResult.data.userInfo.RecordId,
                company_slug: ConstantHelper.COMPANY_SLUG
              };
              // console.log(finalData1);

              this.http.post(this.appConfig.getPhpUrl() + ApiHelper.php_checkInvitationApi, finalData1, options)
                .subscribe(
                  (apiResponse: any) => {
                    localStorage.setItem('loggedInUserName', this.resultJsonObj.commandResult.data.userInfo.FullName);
                    localStorage.setItem('loggedInUserEmail', this.resultJsonObj.commandResult.data.userInfo.Email);
                    localStorage.setItem('loggedInUserRoleId', this.resultJsonObj.commandResult.data.userInfo.UserRoleId);
                    localStorage.setItem('loggedInUserComapnyId', this.resultJsonObj.commandResult.data.userInfo.ComapnyId);
                    // console.log(this.resultJsonObj.commandResult.data.userInfo.UserRoleId);
                    this.userId = this.resultJsonObj.commandResult.data.userInfo.RecordId;
                    if (this.resultJsonObj.commandResult.data.userInfo.UserRoleId == 2) {
                      this.router.navigate(['/dashboard/' + this.userId]);
                    }
                    if (
                      this.resultJsonObj.commandResult.data.userInfo.UserRoleId == 1
                    ) {
                      this.router.navigate(['/admin/users/' + this.userId]);
                    }
                  },
                  err => {
                    // console.log(err);
                  }
                );
            } else {
              this.verifyRegistrationFormMessage = true;
            }
          },
          err => {
            // console.log(err);
          }
        );
    }
  }

  renderReCaptch() {
    setTimeout(() => {
      window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
        sitekey: ConstantHelper.RECAPTCHA_SITE_KEY_CLIENT,
        callback: response => {
          // console.log(response);
          // console.log(
          //   this.appConfig.getLambdaUrl01() + ApiHelper.name_verifyRecaptachaApi
          // );
          // console.log(response);
          this.registrationForm.get('recaptchaValue').setValue(response);

          ///////////////////recaptcha validation /////////////////
          let httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
          });
          let options = {
            headers: httpHeaders
          };
          const finalData = {
            recaptchaResponse: response
          };
          return this.http
            .post(
              this.appConfig.getLambdaUrl01() +
              ApiHelper.name_verifyRecaptachaApi,
              finalData,
              options
            )
            .subscribe(
              apiResponse => {
                // console.log(apiResponse);
                this.resultJsonObj = apiResponse;
                // console.log(this.resultJsonObj);

                if (this.resultJsonObj.commandResult.status === 1) {
                  this.formRecaptchaErrorMessage = false;
                } else {
                  this.formRecaptchaErrorMessage = true;
                }
              },
              err => {
                // console.log(err);
              }
            );
          /////////////////////////////////////////////////////////
        }
      });
    }, 500);
  }


  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    };

    (function(d, s, id, obj) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        obj.renderReCaptch();
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src =
        'https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'recaptcha-jssdk', this);
  }

  ngAfterViewInit() {
    this.addRecaptchaScript();
    this.captchScaleFunc();
  }

  onResize(event) {
    this.captchScaleFunc();
  }

  captchScaleFunc() {
    var captchacontainWidth = this.recaptchaElement.nativeElement.clientWidth;
    var scaleValue = captchacontainWidth / 304;
    if (scaleValue < 1) {
      this.recaptchaElement.nativeElement.style.transform =
        'scale(' + scaleValue + ')';
      this.recaptchaElement.nativeElement.style.transformOrigin = '0';
    }
    // console.log(captchacontainWidth);
  }

  validateNumber(event) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }
  }


  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleCnFieldTextType() {
    this.cnFieldTextType = !this.cnFieldTextType;
  }

}

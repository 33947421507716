// chatbot.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpClientModule } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ChatbotService {
  private isChatbotOpenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private chatMessages: string[] = [];

  constructor(private http: HttpClient) {
    this.loadChatMessages();

  }

  toggleChatbotState() {
    const currentState = this.isChatbotOpenSubject.getValue();
    this.isChatbotOpenSubject.next(!currentState);
  }

  getChatbotState(): Observable<boolean> {
    return this.isChatbotOpenSubject.asObservable();
  }

  addChatMessage(message: string) {
    this.chatMessages.push(message);

    // Save chat messages to local storage
    localStorage.setItem('chatMessages', JSON.stringify(this.chatMessages));
    this.saveChatMessages();
  }

  getChatMessages(): string[] {
    return this.chatMessages;
  }

  
  private loadChatMessages() {
    const storedMessages = localStorage.getItem('chatMessages');
    if (storedMessages) {
      this.chatMessages = JSON.parse(storedMessages);
      console.log('Chat messages loaded:', this.chatMessages); // For debug
    } else {
      console.log('No chat messages in local storage.'); // For debug
    }
  }


  private saveChatMessages() {
    // Save chat messages to local storage
    localStorage.setItem('chatMessages', JSON.stringify(this.chatMessages));
  }
}

import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  HostListener
} from "@angular/core";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from "@angular/common/http";

import { AuthService } from "../auth.service";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { MustMatch } from "../../_helpers/matchpassword";

@Component({
  selector: "app-admin-changepassword",
  templateUrl: "./admin-changepassword.component.html",
  styleUrls: ["./admin-changepassword.component.scss"],
  providers: [AppConfig, CommonHelper]
})
export class AdminChangepasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  changePasswordFormSubmitted: boolean = false;

  formErrorMessage: boolean = false;
  formSuccessMessage: boolean = false;
  showModifyPasswordForm: boolean = true;
  userId: number;
  resultJsonObj: any = {};

  loggedInUserId: string;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get(
      "loggedInUserId"
    );

    this.changePasswordForm = this.formBuilder.group(
      {
        password: ["", [Validators.required, Validators.minLength(5)]],
        confirmPassword: ["", Validators.required]
      },
      {
        validator: MustMatch("password", "confirmPassword")
      }
    );
  }
  get f() {
    return this.changePasswordForm.controls;
  }

  onSubmit(changePasswordFormValue) {
    this.changePasswordFormSubmitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json"
    });
    let options = {
      headers: httpHeaders
    };
    const finalData = {
      password: this.changePasswordForm.value.password,
      id: this.loggedInUserId
    };

    return this.http
      .post(
        this.appConfig.getLambdaUrl01() + ApiHelper.name_modifyPasswordApi,
        finalData,
        options
      )
      .subscribe(
        apiResponse => {
          this.resultJsonObj = apiResponse;
          // console.log(this.resultJsonObj);
          if (this.resultJsonObj.commandResult.status == 1) {
            this.formSuccessMessage = true;
            this.showModifyPasswordForm = false;
          } else {
            this.formErrorMessage = true;
          }
        },
        err => {
          // console.log(err);
        }
      );
  }
}

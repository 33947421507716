import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { from } from "rxjs";
import { DatePipe } from "@angular/common";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { UserService } from "./../../_services/user.service";
import * as moment from "moment";
import { timeStamp } from "console";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

@Component({
  selector: "app-wa",
  templateUrl: "./wa.component.html",
  styleUrls: ["./wa.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class WaComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  hippaJson: any = {};
  loggedInUserId: string;
  isEmail: any;
  isPracticePhy: any;
  physicianId: any;
  requestId: any;
  tempId: any;
  userOptionId: any;
  emailLogId: any;
  showLoader = false;
  showPdfModal = false;
  ssnPattern = "^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$";
  resultJsonObj: any = {};
  clean: string = "0";

  currentDate = new Date();
  dateFormat = "MM-dd-yyyy";
  patientId: string;
  message = Message;
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer,
    public userService: UserService
  ) {
    this.loggedInUserId = localStorage.getItem("delegateId")
      ? localStorage.getItem("delegateId")
      : this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.isEmail = this.activatedRoute.snapshot.paramMap.get("isEmail")
      ? this.activatedRoute.snapshot.paramMap.get("isEmail")
      : 0;
    this.isPracticePhy =
      this.activatedRoute.snapshot.paramMap.get("isPracticePhy");
    this.physicianId = this.activatedRoute.snapshot.paramMap.get("physicianId")
      ? this.activatedRoute.snapshot.paramMap.get("physicianId")
      : 0;
    this.requestId = this.activatedRoute.snapshot.paramMap.get("requestId")
      ? this.activatedRoute.snapshot.paramMap.get("requestId")
      : 0;
    this.tempId = this.activatedRoute.snapshot.paramMap.get("tempId")
      ? this.activatedRoute.snapshot.paramMap.get("tempId")
      : 0;
    this.userOptionId = this.activatedRoute.snapshot.paramMap.get(
      "userOptionId"
    )
      ? this.activatedRoute.snapshot.paramMap.get("userOptionId")
      : 0;
    this.emailLogId = this.activatedRoute.snapshot.paramMap.get("emailLogId")
      ? this.activatedRoute.snapshot.paramMap.get("emailLogId")
      : 0;

    this.hippaJson.healthcare_provider = this.userService.getUS_FromatName(
      localStorage.getItem("selectedPhysicianName")
    );
    this.hippaJson.healthcare_provider_npi = localStorage.getItem(
      "providerPhysicianNipNo"
    );
    this.hippaJson.healthcare_provider_email = localStorage.getItem(
      "selectedPhysicianEmail"
    );
    this.hippaJson.city_zip =
      localStorage.getItem("providerCity") +
      ", " +
      localStorage.getItem("PhysicianStateName") +
      ", " +
      localStorage.getItem("providerZipCode");
    this.hippaJson.providerCity = localStorage.getItem("selectedPhysicianCity");
    this.hippaJson.PhysicianStateName = localStorage.getItem(
      "selectedPhysicianState"
    );
    (this.hippaJson.providerZipCode = localStorage.getItem("providerZipCode")),
      (this.hippaJson.providerPhone = localStorage.getItem("Mobile"));
    this.hippaJson.healthcare_provider_npi = localStorage.getItem(
      "providerPhysicianNipNo"
    );
    this.fnFindDemographic(this.patientId);
    this.hippaJson.expiry_date = this.currentDate;
    this.hippaJson.legal_representative_date = this.currentDate;
    this.hippaJson.revocation_sign_date = this.currentDate;
    this.hippaJson.revocation_legal_representative_date = this.currentDate;
    this.hippaJson.patientPhone = localStorage.getItem("patientPhone");
  }

  ngOnInit() {
    this.patientId = this.activatedRoute.snapshot.paramMap.get("patientId");
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.hippaJson.legal_representative_sign = "";
    this.hippaJson.revocation_legal_representative_sign = "";
    this.hippaJson.checkbox_01 = false;
    this.hippaJson.checkbox_02 = false;
    this.hippaJson.checkbox_03 = false;
    this.hippaJson.checkbox_04 = false;
    this.hippaJson.checkbox_05 = false;
    this.hippaJson.checkbox_06 = false;
    this.hippaJson.checkbox_07 = false;
    this.hippaJson.checkbox_08 = false;
    this.hippaJson.checkbox_09 = false;
    this.hippaJson.checkbox_10 = false;
    this.hippaJson.checkbox_11 = false;
    this.hippaJson.checkbox_12 = false;
    this.hippaJson.checkbox_13 = false;
    this.hippaJson.checkbox_14 = false;
    this.hippaJson.checkbox_15 = false;
    this.hippaJson.checkbox_16 = false;
    this.hippaJson.checkbox_17 = false;
    this.hippaJson.checkbox_18 = false;
    this.hippaJson.checkbox_19 = false;
    this.hippaJson.checkbox_20 = false;
    this.hippaJson.checkbox_21 = false;
    this.hippaJson.checkbox_22 = false;
    this.hippaJson.checkbox_23 = false;
    this.hippaJson.checkbox_24 = false;
    this.hippaJson.checkbox_25 = false;
    this.hippaJson.checkbox_26 = false;
    this.hippaJson.checkbox_27 = false;
    this.hippaJson.checkbox_28 = false;
    this.hippaJson.checkbox_29 = false;
    this.hippaJson.healthcare_provider_npi = localStorage.getItem('selectedPhysicianNPI');
    this.hippaJson.providerCity = localStorage.getItem("selectedPhysicianCity");
    this.hippaJson.PhysicianStateName = localStorage.getItem("selectedPhysicianState");
    this.findUserDetails(this.patientId);
  }

  findUserDetails(patientId) {
    const finalData = {
      id: patientId,
    };
    return this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getUserDetailApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          if (apiResponse.commandResult.status == 1) {
            this.hippaJson.patient_name =
              apiResponse.commandResult.data.userInfo.LastName +
              ", " +
              apiResponse.commandResult.data.userInfo.FirstName;
            this.hippaJson.dob = apiResponse.commandResult.data.userInfo.DOB;
            this.hippaJson.patient_phone =
              apiResponse.commandResult.data.userInfo.Mobile;
          }
        },
        (err) => {}
      );
  }

  async onSubmit() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM:   By clicking ‘OK’:The information you have entered will be used to electronically generate a completed State HIPAA Form."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.onSubmit
    );
   if(alertStatus) {
      const finalData = {
        userId: this.patientId,
        checkbox_01: this.hippaJson.checkbox_01,
        checkbox_02: this.hippaJson.checkbox_02,
        checkbox_03: this.hippaJson.checkbox_03,
        checkbox_04: this.hippaJson.checkbox_04,
        checkbox_05: this.hippaJson.checkbox_05,
        checkbox_06: this.hippaJson.checkbox_06,
        checkbox_07: this.hippaJson.checkbox_07,
        checkbox_08: this.hippaJson.checkbox_08,
        checkbox_09: this.hippaJson.checkbox_09,
        checkbox_10: this.hippaJson.checkbox_10,
        checkbox_11: this.hippaJson.checkbox_11,
        checkbox_12: this.hippaJson.checkbox_12,
        checkbox_13: this.hippaJson.checkbox_13,
        checkbox_14: this.hippaJson.checkbox_14,
        checkbox_15: this.hippaJson.checkbox_15,
        checkbox_16: this.hippaJson.checkbox_16,
        checkbox_17: this.hippaJson.checkbox_17,
        checkbox_18: this.hippaJson.checkbox_18,
        checkbox_19: this.hippaJson.checkbox_19,
        checkbox_20: this.hippaJson.checkbox_20,
        checkbox_21: this.hippaJson.checkbox_21,
        checkbox_22: this.hippaJson.checkbox_22,
        checkbox_23: this.hippaJson.checkbox_23,
        checkbox_24: this.hippaJson.checkbox_24,
        checkbox_25: this.hippaJson.checkbox_25,
        checkbox_26: this.hippaJson.checkbox_26,
        checkbox_27: this.hippaJson.checkbox_27,
        checkbox_28: this.hippaJson.checkbox_28,
        checkbox_29: this.hippaJson.checkbox_29,
        loggedInUserId: this.loggedInUserId,
        healthcare_provider: this.hippaJson.healthcare_provider,
        healthcare_provider_email: this.hippaJson.healthcare_provider_email,
        healthcare_provider_npi: this.hippaJson.healthcare_provider_npi,
        agency_permision_info: this.hippaJson.agency_permision_info,
        disclosed_info: this.hippaJson.disclosed_info,
        providerFax: this.hippaJson.providerFax,
        representative_name: this.hippaJson.representative_name,
        dob: moment(new Date(this.hippaJson.dob.toString())).format(
          "MM-DD-YYYY"
        ),
        expiry_date: moment(
          new Date(this.hippaJson.expiry_date.toString())
        ).format("MM-DD-YYYY"),
        legal_representative_date: moment(
          new Date(this.hippaJson.legal_representative_date.toString())
        ).format("MM-DD-YYYY"),
        legal_representative_name: this.hippaJson.legal_representative_name,
        legal_representative_relation:
          this.hippaJson.legal_representative_relation,
        health_info: this.hippaJson.health_info,
        patient_city: this.hippaJson.patient_city,
        patient_name: this.hippaJson.patient_name,
        patient_phone: this.hippaJson.patient_phone,
        former_name: this.hippaJson.former_name,
        business_name: this.hippaJson.business_name,
        providerPhone: this.hippaJson.providerPhone,
        client_no: this.hippaJson.client_no,
        other_idno: this.hippaJson.other_idno,
        service_date: this.hippaJson.service_date,
        service_location: this.hippaJson.service_location,
        patient_title: this.hippaJson.patient_title,
        reason: this.hippaJson.reason,
        other_info: this.hippaJson.other_info,
        valid_date: this.hippaJson.valid_date,
        other_subject: this.hippaJson.other_subject,
        record_date: this.hippaJson.record_date,
        patient_state: this.hippaJson.patient_state,
        patient_zip: this.hippaJson.patient_zip,
        patient_address: this.hippaJson.patient_address,
        patient_medical_id: this.hippaJson.patient_medical_id,
        revocation_city: this.hippaJson.revocation_city,
        // revocation_dob: moment(
        //   new Date(this.hippaJson.revocation_dob.toString())
        // ).format("MM-DD-YYYY"),
        revocation_legal_representative_date: moment(
          new Date(
            this.hippaJson.revocation_legal_representative_date.toString()
          )
        ).format("MM-DD-YYYY"),
        revocation_legal_representative_name:
          this.hippaJson.revocation_legal_representative_name,
        revocation_legal_representative_relation:
          this.hippaJson.revocation_legal_representative_relation,
        revocation_medical_id: this.hippaJson.revocation_medical_id,
        revocation_name: this.hippaJson.revocation_name,
        revocation_phone: this.hippaJson.revocation_phone,
        revocation_sign_date: moment(
          new Date(this.hippaJson.revocation_sign_date.toString())
        ).format("MM-DD-YYYY"),
        revocation_social_security_no:
          this.hippaJson.revocation_social_security_no,
        revocation_state: this.hippaJson.revocation_state,
        street_address: localStorage.getItem("selectedPhysicianOfficeAddress"),
        revocation_zip: this.hippaJson.revocation_zip,
        social_security_no: this.hippaJson.social_security_no,
        providerCity:this.hippaJson.providerCity,
        PhysicianStateName:this.hippaJson.PhysicianStateName ,
        providerZipCode:this.hippaJson.providerZipCode,
        isEmail: this.isEmail,
        requestId: this.requestId,
      };
      this.showLoader=true;
      return this.http
        .post(
          this.appConfig.getLambdaUrl06() + ApiHelper.generateHipaaWA,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (apiResponse: any) => {
            if (this.hippaJson.loggedInUserRoleId == 1) {
              this.router.navigate([
                "/doctor/" +
                  "/demographics/" +
                  this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
                  "/1/",
              ]);
            } else if (this.hippaJson.loggedInUserRoleId == 4) {
              this.router.navigate([
                "/doctor/" +
                  "/demographics/" +
                  this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
                  "/1/",
              ]);
            } else {
              if (window.open(apiResponse.commandResult.data, "_blank")) {
                this.router.navigate([
                  "/doctor/" +
                    "/ushf/" +
                    this.activatedRoute.snapshot.paramMap.get(
                      "loggedInUserId"
                    ) +
                    "/" +
                    this.activatedRoute.snapshot.paramMap.get("patientId") +
                    "/" +
                    this.activatedRoute.snapshot.paramMap.get("demographicId"),
                ]);
              }
            }
            this.showLoader= false;
          },
          (err) => {}
        );
    }
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Click ‘OK’ to confirm returning to the ‘Home Page’."
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate([
        "/dashboard/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId"),
      ]);
    }
  }

  async goBack() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the USHF Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.njComp.goBack
    );
    if (alertStatus) {
      this.router.navigate([
        "/doctor/" +
          "/ushf/" +
          this.activatedRoute.snapshot.paramMap.get("loggedInUserId") +
          "/" +
          this.activatedRoute.snapshot.paramMap.get("patientId") +
          "/" +
          this.activatedRoute.snapshot.paramMap.get("demographicId"),
      ]);
    }
  }

  closePdfModal() {
    this.showPdfModal = false;
  }

  fnFindDemographic(user_id) {
    const finalData = {
      id: user_id,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl04() +
          ApiHelper.name_findUserDemographicGeneralApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (response: any) => {
          console.log("response=>", response);
          this.hippaJson.dob = new Date(
            response.commandResult.data.userInfo.form1DOB
          );
          this.hippaJson.patient_city =
            response.commandResult.data.userInfo.form1City;
          this.hippaJson.patient_zip =
            response.commandResult.data.userInfo.form1ZIP;
          this.hippaJson.patient_state =
            response.commandResult.data.userInfo.form1State;
          this.hippaJson.patient_address =
            response.commandResult.data.userInfo.form1ResidenceNumber +
            ", " +
            response.commandResult.data.userInfo.form1StreetName +
            ", " +
            response.commandResult.data.userInfo.form1PrimaryPhysicianCity +
            ", " +
            response.commandResult.data.userInfo.form1State +
            ", " +
            response.commandResult.data.userInfo.form1ZIP;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  autoFormatSSN() {
    var val = this.hippaJson.social_security_no.replace(/\D/g, "");
    val = val.replace(/^(\d{3})/, "$1-");
    val = val.replace(/-(\d{2})/, "-$1-");
    val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
    this.hippaJson.social_security_no = val;
  }

  autoFormatSSN1() {
    var val = this.hippaJson.revocation_social_security_no.replace(/\D/g, "");
    val = val.replace(/^(\d{3})/, "$1-");
    val = val.replace(/-(\d{2})/, "-$1-");
    val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
    this.hippaJson.revocation_social_security_no = val;
  }

  openWABlankpdf() {
    window.open(
      "https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/Washington-HIPAA-Medical-Release-Form.pdf",
      "_blank"
    );
  }
}

import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { Message } from "src/app/_locale/message";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";

@Component({
  selector: "app-activity-log",
  templateUrl: "./activity-log.component.html",
  styleUrls: ["./activity-log.component.scss"],
})
export class ActivityLogComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };

  loggedInUserId: string;
  resultJsonObj: any = {};
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  childRoute: string;
  logsLength: boolean = false;
  logsList: any;
  selectedDocId: string;
  selectedDocUrl: string;
  selectedDocName: string;
  showPdfModal: boolean = false;
  ahsPdfUrl: SafeResourceUrl = "";
  showLoader = false;
  message = Message;
  dtOptions: DataTables.Settings = {};
  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer
  ) {
    localStorage.setItem("childRoute", activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.fnFindAdminActivityLogs(this.loggedInUserId);
  }

  ngOnInit() {
    this.dtOptions = {
      order: []
    };
  }

  fnFindAdminActivityLogs(userId) {
    const finalData = {
      loggedInUserId: userId,
      company_slug: ConstantHelper.COMPANY_SLUG,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getLambdaUrl02() + ApiHelper.name_getAdminLogsListApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse) => {
          this.resultJsonObj = apiResponse;
          if (this.resultJsonObj.commandResult.status == 1) {
            if (this.resultJsonObj.commandResult.data.logsList.length > 0) {
              this.logsLength = true;
              this.logsList = this.resultJsonObj.commandResult.data.logsList;
            }
          }
          this.showLoader = false;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  loadDocunent(docId, docName, docUrl) {
    this.selectedDocId = docId;
    this.selectedDocUrl = docUrl;
    this.selectedDocName = docName;
    this.showPdfModal = true;
    this.ahsPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.selectedDocUrl
    );
  }

  closePdfModal() {
    this.showPdfModal = false;
  }

  goToDocumentsList() {
    this.router.navigate([
      "/" + this.childRoute + "/documents/" + this.loggedInUserId,
    ]);
  }
}

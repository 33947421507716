import {Component, OnInit, ViewChild, ElementRef} from "@angular/core";
// import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {Router, ActivatedRoute} from "@angular/router";
import {NotificationService} from "../../_services/notification.service";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from "@angular/common/http";

@Component({
  selector: "app-welcome",
  templateUrl: "./welcome.component.html",
  styleUrls: ["./welcome.component.scss"]
})
export class WelcomeComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private http: HttpClient,
              private sanitizer: DomSanitizer,
              private notifyService: NotificationService) {
  }

  ngOnInit() {
  }

  goToLogin() {
    this.router.navigate(["/login/"]);
  }

  goToRegister() {
    this.router.navigate(["/register/"]);
  }

  checkLogin() {
    alert('Please login first.')
  }
}

import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";

@Component({
  selector: "app-email-log",
  templateUrl: "./email-log.component.html",
  styleUrls: ["./email-log.component.scss"],
})
export class EmailLogComponent implements OnInit {
  loggedInUserId: string;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  logsLength = false;
  logsList: any;

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  message = Message;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public sweetAlertHelper: SweetAlertHelper,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");

    this.fnFindEmailLogs();
  }

  fnFindEmailLogs() {
    const finalData = {
      loggedInUserId: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_getEmailLogsApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log(apiResponse);
          if (apiResponse.commandResult.data.logsList.length > 0) {
            this.logsLength = true;
            this.logsList = apiResponse.commandResult.data.logsList;
            // console.log(this.logsList);
          } else {
            this.logsLength = false;
          }
          // console.log(this.logsLength);
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async sendDocs(logId) {
    // if (confirm("PLEASE CONFIRM: Are you sure?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.addAdminUserComp.onSubmit
    );
    if (alertStatus) {
      this.router.navigate([
        "/resend-docs/" + this.loggedInUserId + "/" + logId,
      ]);
    }
  }

  async goToDashboad() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to the Home Page?"
    //   )
    // )
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(["/dashboard/" + this.loggedInUserId]);
    }
  }
}

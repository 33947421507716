import { Injectable } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ShareUnrelatedDateService {
  constructor() {}

  public dataDetails: any = [];
  public dataSDetails: string ='';
  public dataDetails2: string ='';
  public dataDetails3: string='1';


  isDelegating= 0;
  public subject = new Subject<any>();
  private dataSource = new BehaviorSubject(this.dataDetails);
  private dataSSource = new BehaviorSubject(this.dataSDetails);
  private dataSource2 = new BehaviorSubject(this.dataDetails2);
  private dataSource3 = new BehaviorSubject(this.dataDetails3);


  currentData = this.dataSource.asObservable();
  currentSData = this.dataSSource.asObservable();
  currentData2 = this.dataSource2.asObservable();
  currentData3 = this.dataSource3.asObservable();


  

  changeisDelegating(isDelegating: string) {this.dataSource2.next(isDelegating);}
  changePatientsTypeVal(patientsTypeVal: string) {this.dataSource3.next(patientsTypeVal);}


  changePhysicianVal(physicianVal: string) {this.dataSSource.next(physicianVal);}
  changeDelegateId(delegateId: string) {this.dataSource.next(delegateId);}
  changeUserList(usersList: any) {this.dataSource.next(usersList);}
  changeTempUserList(tempLog: any) {this.dataSource.next(tempLog);}


  



}

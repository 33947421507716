import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders
} from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { AppConfig } from "./../../../app/app.config";
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-admin-delegation-log',
  templateUrl: './admin-delegation-log.component.html',
  styleUrls: ['./admin-delegation-log.component.scss']
})
export class AdminDelegationLogComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });
  httpOptions = {
    headers: this.httpHeaders
  };


  loggedInUserId: string;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  childRoute: string;
  logsList: any = [];
  requestingPhysicianVal: any = '';
  tempLog: any = [];
  loggedInUserRoleId: any;
  physicianArr: any = [];
  activityArr: any = [];
  adminArr: any = [];
  endDateLessThanStart(): boolean {
    return new Date(this.endDateVal) < new Date(this.startDateVal);
  }

  startDateGreaterThanCurrent(): boolean { return new Date(this.startDateVal) > this.currentDate; }



  currentDate = new Date();
  dateFormat = 'MM-dd-yyyy';
  startDateVal = '';
  endDateVal = '';
  fileName= 'ExcelSheet.xlsx';  

  physicianVal = '';
  adminVal = '';
  activityVal = '';
  logsListFlag = true;
  dateFlag = true;
  dtOptions: DataTables.Settings = {};

  constructor(
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
  ) {
    localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path)
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.loggedInUserId = this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.loggedInUserRoleId= localStorage.getItem('loggedInUserRoleId');
  }

  ngOnInit() {
    this.dtOptions = {
      order: []
    };
  }



  applyFilters() {
    // Apply filters based on selected options
    let filteredList = this.tempLog;

    if (this.physicianVal) {
      filteredList = filteredList.filter(item => item.RequesitngDoctorId === this.physicianVal);
    }

    if (this.adminVal) {
      filteredList = filteredList.filter(item => item.DelegateId === this.adminVal);
    }

    if (this.activityVal) {
      filteredList = filteredList.filter(item => item.ActivityType === this.activityVal);
    }

    // Update the displayed logsList
    this.logsList = filteredList;
     // Show error message if no logs are found
     this.logsListFlag = this.logsList.length > 0
  }


  searchReport() {
    const newStartDate = moment(new Date(this.startDateVal.toString())).format(
      'YYYY-MM-DD'
    );
    const newEndDate = moment(new Date(this.endDateVal.toString())).format(
      'YYYY-MM-DD'
    );

    const finalData = {
      companySlug: ConstantHelper.COMPANY_SLUG,
      loggedInUserId: this.loggedInUserId,
      startDate: newStartDate,
      endDate: newEndDate,
    };
    console.log(finalData);
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_getDelegationLogApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.logsList = res.commandResult.data.logs;
          this.logsList.length > 0 ? this.logsListFlag = true: this.logsListFlag = false;
          this.tempLog = res.commandResult.data.logs;
          this.physicianArr = res.commandResult.data.physicians;
          this.activityArr = res.commandResult.data.activities;
          this.adminArr = res.commandResult.data.admins;
          this.applyFilters();
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  filterActivity(activity) {
    this.activityVal = activity;
    this.applyFilters();
  }

  filterPhysician(physicianId) {
    this.physicianVal = physicianId;
    this.applyFilters();
  }

  filterAdmin(adminId) {
    this.adminVal = adminId;
    this.applyFilters();
  }

  reset() {
    this.logsList = [];
    this.physicianVal = '';
    this.adminVal = '';
    this.activityVal = '';
    this.startDateVal = '';
    this.endDateVal = '';
    this.dateFlag = !this.dateFlag;
  }




  exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('example'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
    }


    printData() {  
      var divToPrint = document.getElementById("example");  
      let newWin = window.open("");  
      newWin.document.write(divToPrint.outerHTML);  
      newWin.print();  
      newWin.close();  
  } 

}

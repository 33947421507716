import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";

import {
  HttpClient,
  HttpParams,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

import { environment } from "./../../../environments/environment";
import { ApiHelper } from "./../../_helpers/api.helper";
import { CommonHelper } from "./../../_helpers/common.helper";
import { AppConfig } from "./../../../app/app.config";
import { ConstantHelper } from "./../../_helpers/constant.helper";
import { UploadService } from "../../_services/upload.service";
import { UserService } from "../../_services/user.service";
import { SweetAlertHelper } from "src/app/_helpers/sweet.alert.helper";
import { Message } from "src/app/_locale/message";
import { async } from "@angular/core/testing";

@Component({
  selector: "app-resend-docs",
  templateUrl: "./resend-docs.component.html",
  styleUrls: ["./resend-docs.component.scss"],
  providers: [AppConfig, CommonHelper],
})
export class ResendDocsComponent implements OnInit {
  @ViewChild("fileUploaded", { static: false }) fileUploaded: ElementRef;
  maxChars = 300;
  loggedInUserId: string;
  logId: string;
  docInfo: any = {};
  companySlug: string = ConstantHelper.COMPANY_SLUG;
  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  documentsList: any;
  documentsLength: any;
  statesArre: any = [];
  comapnyName: string = ConstantHelper.COMPANY_NAME;

  stateSelectDisabledOption = true;
  citySelectDisabledOption = true;
  physicianSelectDisabledOption = true;

  cityLength = false;
  noCityLength = false;
  citiesList: any = [];

  physicianLength = false;
  noPhysicianLength = false;
  physiciansList: any = [];
  selectedPhysician: any = {};
  resultJsonObj: any = {};
  logDetail: any = {};

  selectedStateCode: any;
  selectedCityName: any;
  selectedPhysicianId: any;
  emailLogTxt: any;
  fileDesTxt: any;
  altEmailText: any;
  cnfAltEmailText: any;
  docType = ConstantHelper.DOC_TYPE_USER_EMAILED;
  hippaAvailable = false;
  hasHippaFile = false;

  selectedDocUrl: any;

  fileToUpload: File = null;
  fSize: any;
  showNewFile = 0;
  attachmentDoc: any = {};
  selectedDocumentId: any;
  attachmentDocment: any = "";
  message = Message;

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public sweetAlertHelper: SweetAlertHelper,
    private sanitizer: DomSanitizer,
    private uploadService: UploadService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get("loggedInUserId");
    this.logId = this.activatedRoute.snapshot.paramMap.get("logId");

    this.fnFindStates();

    this.findEmailLogDetail();
  }

  fnFindStates() {
    const finalCityData = {
      id: this.loggedInUserId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findPhysicianCitiesApi,
        finalCityData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          this.statesArre = apiResponse.commandResult.data.statesList;
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findEmailLogDetail() {
    const finalLogData = {
      logId: this.logId,
    };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() + ApiHelper.name_findLogDetailApi,
        finalLogData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log(apiResponse);
          this.logDetail = apiResponse;

          this.selectedStateCode =
            apiResponse.commandResult.data.logInfo.PhysicianStateCode;
          this.selectedPhysicianId =
            apiResponse.commandResult.data.logInfo.PhysicianId;
          this.selectedCityName =
            apiResponse.commandResult.data.logInfo.PhysicianCity;

          this.docInfo.state =
            apiResponse.commandResult.data.logInfo.PhysicianStateCode;
          this.docInfo.physicianName =
            apiResponse.commandResult.data.logInfo.PhysicianId;
          this.docInfo.city =
            apiResponse.commandResult.data.logInfo.PhysicianCity;
          this.emailLogTxt = apiResponse.commandResult.data.logInfo.EmailTxt;
          this.docInfo.altEmail =
            apiResponse.commandResult.data.logInfo.PersonAltEmail;
          this.docInfo.cnfAltEmail =
            apiResponse.commandResult.data.logInfo.PersonAltEmail;
          this.citiesList = apiResponse.commandResult.data.cityList;
          this.physiciansList = apiResponse.commandResult.data.physiciansList;
          this.documentsList = apiResponse.commandResult.data.documentsList;
          this.selectedPhysician = this.physiciansList.find(
            (e) => e.RecordId == this.selectedPhysicianId
          );
          this.onChangeUserState(
            apiResponse.commandResult.data.logInfo.PhysicianStateCode
          );
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findCitiesByStateCode(stateCode) {
    this.stateSelectDisabledOption = false;

    this.selectedStateCode = stateCode;

    const finalData = { stateCode: stateCode };
    return this.http
      .post(
        this.appConfig.getLambdaUrl01() +
          ApiHelper.name_getCitiesByStateCodeApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (apiResponse: any) => {
          // console.log(apiResponse);
          this.citySelectDisabledOption = true;
          if (apiResponse.commandResult.status == 1) {
            if (apiResponse.commandResult.data.cityList.length > 0) {
              this.cityLength = true;
              this.citiesList = apiResponse.commandResult.data.cityList;
              // console.log('this.citiesList', this.citiesList);
            } else {
              this.noCityLength = true;
            }
          } else {
            this.noCityLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findPhysicianByStateCode(cityName) {
    this.selectedCityName = cityName;

    this.citySelectDisabledOption = false;
    const finalDataPhysician = { cityName: cityName };
    this.http
      .post(
        this.appConfig.getLambdaUrl03() +
          ApiHelper.name_getPhysiciansByCityCodeApi,
        finalDataPhysician,
        this.httpOptions
      )
      .subscribe(
        async (apiResponsePhysician: any) => {
          if (apiResponsePhysician.commandResult.status == 1) {
            this.physicianSelectDisabledOption = true;
            if (
              apiResponsePhysician.commandResult.data.physiciansList.length > 0
            ) {
              this.physicianLength = true;
              this.physiciansList =
                apiResponsePhysician.commandResult.data.physiciansList;
              // console.log('physiciansList List');
              // console.log(this.physiciansList);
            } else {
              // alert("No Physicians Found");
              const alertStatus = await this.sweetAlertHelper.alertPopUp(
                this.message.alertMessages.adminsecondoptwfCOmp
                  .findcoePhysicians
              );

              this.noPhysicianLength = true;
            }
          } else {
            // alert("No Physicians Found");
            const alertStatus = await this.sweetAlertHelper.alertPopUp(
              this.message.alertMessages.adminsecondoptwfCOmp.findcoePhysicians
            );
            this.noPhysicianLength = true;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  updatePhysicianInfo(physicianId) {
    this.physicianSelectDisabledOption = false;
    this.selectedPhysicianId = physicianId;

    this.selectedPhysician = this.physiciansList.find(
      (e) => e.RecordId == physicianId
    );
    // console.log(this.selectedPhysician);
  }

  async onSubmit() {
    let altEmail = "";

    if (this.docInfo.altEmail) {
      altEmail = this.docInfo.altEmail;
    }

    if (
      this.showNewFile == 1 &&
      this.fileUploaded.nativeElement.value != "" &&
      !this.validateFile(this.fileToUpload.name)
    ) {
      // alert("Selected file format is not supported");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_4
      );
      return false;
    } else if (
      this.showNewFile == 1 &&
      this.fileUploaded.nativeElement.value != "" &&
      this.fSize > 61440
    ) {
      // alert("File too Big, please select a file less than 60mb");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminsecondoptwfCOmp.d2d1_5
      );
      return false;
    } else {
      // if (confirm('PLEASE CONFIRM: Are you sure you want to send this email?'))
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        this.message.confirmMessages.demographicsComp.email2All
      );
      if (alertStatus) {
        // tslint:disable-next-line: max-line-length
        this.uploadService
          .adminResendDoc(
            this.selectedPhysician.PhysicianName,
            this.selectedPhysician.PhysicianEmail,
            altEmail,
            this.loggedInUserId,
            this.logId,
            this.companySlug,
            this.loggedInUserId,
            this.selectedStateCode,
            this.selectedCityName,
            this.selectedPhysicianId,
            this.emailLogTxt,
            this.fileToUpload,
            this.fileDesTxt,
            this.docType,
            localStorage.getItem("loggedInUserComapnyId"),
            this.showNewFile,
            this.attachmentDoc
          )
          .subscribe(
            async (data: any) => {
              // console.log(data);
              if (data !== undefined) {
                // alert("Email successfully sent. ");
                const alertStatus = await this.sweetAlertHelper.alertPopUp(
                  this.message.alertMessages.adminsecondoptwfCOmp.d2d04_8
                );
                if (alertStatus)
                  this.router.navigate(["/email-log/" + this.loggedInUserId]);
              }
            },
            (error) => {
              // console.log(error);
            }
          );
      }
    }
  }

  async onReset() {
    // if (confirm("PLEASE CONFIRM: Are you sure go to Practice Home Page?"))
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.adminChangePassComp.onReset
    );
    if (alertStatus) {
      this.router.navigate(["/email-log/" + this.loggedInUserId]);
    }
  }

  // onFileSelected(event) {
  //   if (this.fileUploaded.nativeElement.value != "") {
  // console.log(this.fileUploaded.nativeElement.value);
  //     this.fileToUpload = <File>event.target.files.item(0);
  // console.log(this.fileToUpload);
  //     this.fSize = Math.round(this.fileToUpload.size / 1024);
  //   } else {
  //   }
  // }

  onFileSelected(event) {
    this.showNewFile = 1;
    this.attachmentDocment = "";
    this.hippaAvailable = false;

    if (this.fileUploaded.nativeElement.value != "") {
      // console.log(this.fileUploaded.nativeElement.value);
      this.fileToUpload = event.target.files.item(0) as File;
      this.fSize = Math.round(this.fileToUpload.size / 1024);
    } else {
    }

    if (
      this.hasHippaFile &&
      this.fileUploaded.nativeElement.value == "" &&
      this.selectedDocumentId == ""
    ) {
      this.hippaAvailable = true;
    }
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "pdf"
    ) {
      return true;
    } else {
      return false;
    }
  }

  onChangeUserState(stateCode) {
    // console.log('State Code=> ', stateCode);
    this.userService.findHippaByStateCode(stateCode).subscribe(
      (apiResponse) => {
        // console.log(apiResponse);
        this.resultJsonObj = apiResponse;
        if (this.resultJsonObj.commandResult.status == 1) {
          this.hippaAvailable = true;
          this.hasHippaFile = true;

          this.selectedDocUrl =
            this.resultJsonObj.commandResult.data.hippaDetails.DocumentUrl;
        } else {
          this.hippaAvailable = false;
        }
      },
      (err) => {
        // console.log(err);
      }
    );
  }

  async downloadHipp() {
    if (this.selectedDocUrl) {
      // if (confirm('PLEASE CONFIRM: Are you sure?')) {
      window.open(this.selectedDocUrl);
      // }
    } else {
      // alert("No HIPAA form found for the state.");
      const alertStatus = await this.sweetAlertHelper.alertPopUp(
        this.message.alertMessages.adminUshf2Comp.onSubmit
      );
    }
  }

  setAttachment(DocumentId) {
    this.hippaAvailable = false;
    this.fileUploaded.nativeElement.value = "";
    this.selectedDocumentId = DocumentId;
    this.showNewFile = 2;
    const docObj = this.documentsList.find(
      (e) => e.RecordId == this.selectedDocumentId
    );
    this.attachmentDoc = JSON.stringify(docObj);
    // console.log(this.fileUploaded.nativeElement.value);
    // console.log(this.selectedDocumentId)
    if (
      this.hasHippaFile &&
      this.fileUploaded.nativeElement.value == "" &&
      this.selectedDocumentId == ""
    ) {
      this.hippaAvailable = true;
    }
  }
}
